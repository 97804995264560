import React, { useEffect, useRef, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import styles from "./Stake.module.sass";
import { TfiTwitterAlt } from "react-icons/tfi";
import { SiDiscord } from "react-icons/si";
import { AiFillInstagram } from "react-icons/ai";
import { Col, Row } from "antd";
import StakeImg from "../../../images/stakeNew.png";
const Stake = (props) => {
  return (
    <div className={styles.stake}>
      <Row>
        <Col xs={24} sm={24} md={12} lg={12}>
          <img src={StakeImg} className={styles.stakeImg} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className={styles.container}>
            <div>
              <div className={styles.heading}>Race Your Gen One Puppies</div>
              <div className={styles.text}>
              Breed your Original Rare or Legendary Rare CYBERDOGZ and 
              watch them compete in matches every 15 minutes. Earn $KIBBLEZ, 
              $BONEZ or $USDT (coming soon). 
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Stake;
