import React from "react";
import Hero10 from "./Hero10";
import HotBid from "../../components/HotBid";

const Whitepaper = () => {
  return (
    <>
      <Hero10 />
      <HotBid classSection="section-pb" />
    </>
  );
};

export default Whitepaper;
