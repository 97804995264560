import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Link, useParams } from "react-router-dom";
import styles from "./Account.module.sass";
import Assets from "./Assets";
import Auction from "../../abi/Auction.json";
import { ethers, utils, providers } from "ethers";

import {
  GetFollowers,
  GetFollowings,
  GetUserAssets,
  GetBuyerUser,
  GetBidsByUser,
} from "../../services/ApiServices";
import { useDispatch, useSelector } from "react-redux";
import { minifyAddress } from "../../utils/helpers";
import { INFURA_PROVIDER_KEY } from "../../Config/config";
const navLinks = [
  "All Assets",
  "My Assets - Ethereum",
  "My Assets - Polygon",
  "My Bids",

  // "Likes",
  // "Following",
  // "Followers",
];

const Profile = (props) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [following, setFollowing] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [user, setUser] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [userLoading, setUserLoading] = useState(false);
  const [isFollow, setIsFollow] = useState(false);
  const [bids, setBids] = useState([]);
  const [polygonAssets, setPolygonAssets] = useState([]);
  const [ethereumAssets, setEthereumAssets] = useState([]);
  const [rinkebyAssets, setRinkebyAssets] = useState([]);
  const { uid } = useParams();
  useEffect(() => {
    getAssets();
    // getBids()
  }, [uid]);
  // const AuctionAddress = "0x7AD718D00C99867ad7917830Fa170b91C57A0Adb";
  const AuctionContractAddress = "0x28b5633Dc11Df4D55f545B753f4E8528addeAf80";
  useEffect(() => {
    AuctionContractContract();
  }, [uid]);

  const AuctionContractContract = async () => {
    try {
      let provider;
      let auction;
      if (typeof window.ethereum == "undefined") {
        provider = new providers.InfuraProvider("homestead", {
          projectId: INFURA_PROVIDER_KEY,
        });

        auction = new ethers.Contract(
          AuctionContractAddress,
          Auction.abi,
          provider
        );
      } else {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        provider = new providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        auction = new ethers.Contract(
          AuctionContractAddress,
          Auction.abi,
          signer
        );
      }
      let data = await auction.getCurrentBidForUser(1, uid);
      let owner = await auction.owner();
      // setBids(data);
      let tokenAddress = data.toString().split(",")[0];
      let tokenId = data.toString().split(",")[1];
      let startPrice = data.toString().split(",")[2];
      let amount = data.toString().split(",")[3];
      let tradable = data.toString().split(",")[4];
      let sold = data.toString().split(",")[5];
      let duration = data.toString().split(",")[6];
      let endTime = data.toString().split(",")[7];
      let price = utils.formatUnits(
        utils.parseUnits(startPrice.toString(), "wei"),
        "ether"
      );
      var value = {
        tokenAddress: tokenAddress,
        tokenId: tokenId,
        startPrice: price,
        amount: amount,
        tradable: tradable,
        sold: sold,
        duration: duration,
        endTime: endTime,
      };
      // setAuctionValue(value);
    } catch (e) {
      console.log(e);
    }
  };

  const getAssets = async () => {
    setIsLoading(true);
    let result;

    try {
      result = await GetUserAssets(uid,"onlydogz=false&rents=true");
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }

    try {
      if (result.success) {
        setIsLoading(false);
        setAssets(result.result.assets);
      }
    } catch (e) {
      setIsLoading(false);

      console.log(e);
    }
  };

  return (
    // <div className={styles.body}>
    <div className={cn("container", styles.container)}>
      {/* <User className={styles.user} item={user} getUser={getUser} isFollow={isFollow} /> */}
      <p className={styles.title}>
        ASSETS OF&nbsp;
        <span className={styles.heading}>{minifyAddress(uid, 10)}</span>
      </p>

      <div className={styles.group}>
        <Assets
          class={styles.items}
          index={activeIndex}
          isLoading={isLoading}
          showPolygon={() => setActiveIndex(1)}
          assets={assets}
        />
      </div>
    </div>
  );
};

export default Profile;
