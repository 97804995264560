import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "antd";
import styles from "./Breeding.module.sass";
import { GetUserAssets } from "../../../services/ApiServices";
import { useDrop, useDrag, DndProvider } from "react-dnd";
import PawLoading from "../../../json/pawLoading.json";
import LottieLoader from "react-lottie-loader";
import BreedingHistory from "../History/BreedingHistory";
import { isMobile } from "react-device-detect";
import BreedModal from "./BreedModal";
import Farm from "../../../images/Farm.png";
import Hatch from "../../../images/hatch.png";
import { useSnackbar } from "notistack";
import TransparentButton from "../../../components/TransparentButton";
import BlackModal from "../../../components/BlackModal";
import Banner from "../../../images/breedingBAnner.png";
import DotsLeft from "../../../images/raceDots.png";
import DotsRight from "../../../images/raceDots1.png";
import { useWeb3React } from "@web3-react/core";
import { useNavigate } from "react-router";
const Breeding = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [male, setMale] = useState([]);
  const [female, setFemale] = useState([]);
  const [maleBreed, setMaleBreed] = useState({});
  const [femaleBreed, setFemaleBreed] = useState({});
  const { account, active, activate, error, deactivate } = useWeb3React();
  const [dragData, setDragData] = useState({});
  const [maleIndex, setMaleIndex] = useState(-1);
  const [femaleIndex, setFemaleIndex] = useState(-1);
  const [tempIndex, setTempIndex] = useState(-1);
  const [isBreedModal, setIsBreedModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate=useNavigate()
  useEffect(() => {
    if (account) getAssets(account);
  }, [account]);
  const getAssets = async () => {
    setIsLoading(true);
    let result;
    try {
      result = await GetUserAssets(account, "onlydogz=true");
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
    try {
      if (result.success) {
        setIsLoading(false);
        setAssets(result.result.assets);
        let male = result.result.assets.filter(function (data) {
          if (data.gender === "MALE" && data.chainName == "Polygon") {
            return data;
          }
        });

        setMale(male);
        let female = result.result.assets.filter(function (data) {
          if (data.gender === "FEMALE" && data.chainName == "Polygon") {
            return data;
          }
        });
        setFemale(female);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const dragStart = (e, data, index) => {
    setDragData(data);
    setTempIndex(index);
  };
  const droped = (e) => {
    if (dragData.gender == "MALE") {
      setMaleBreed(dragData);
      setMaleIndex(tempIndex);
    } else {
      enqueueSnackbar("Asset is not Male", { variant: "warning" });
    }
  };
  const femaleDroped = (e) => {
    if (dragData.gender == "FEMALE") {
      setFemaleBreed(dragData);
      setFemaleIndex(tempIndex);
    } else {
      enqueueSnackbar("Asset is not Female", { variant: "warning" });
    }
  };

  const [{ isOver }, drop] = useDrop(() => ({
    accept: "image",
    drop: (item) => addImageToBoard(item._id),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const addImageToBoard = (id) => {
    const pictureList = assets.find((picture) => id === picture._id);
    setMaleBreed(pictureList);
  };

  const clickMaleFucntion = (index, data) => {
    if (isMobile) {
      setMaleBreed(data);
      setMaleIndex(index);
    }
  };
  const clickFemaleFucntion = (index, data) => {
    if (isMobile) {
      setFemaleBreed(data);
      setFemaleIndex(index);
    }
  };

  return (
    <div className={styles.breeding}>
      <div className={styles.top}>
        <img src={Banner} className={styles.banner} />
        <div className={styles.topContainer}>
          <div className={styles.heading}>Breeding</div>
          <div className={styles.subHead}>
            Breed your cyberdog to increase your collection
          </div>
        </div>
      </div>
      <div className={styles.info}>
        <img src={DotsLeft} className={styles.dotsLeft} />
        <div className={styles.breedText}>
          To breed, you must have at least:
        </div>
        <div className={styles.information}>
          1 GENESIS <span>MALE</span> AND 1 GENESIS <span>FEMALE</span>{" "}
          CYBERDOGZ
        </div>
        <img src={DotsRight} className={styles.dotsRight} />
      </div>

      <Row style={{ width: "100%" }}>
        <Col md={12} lg={12} sm={24} xs={24}>
          <div className={styles.genesis}>
            <Row>
              <Col span={11} className={styles.body}>
                <div className={styles.gender}>
                  MALE <span className={styles.count}>{male.length}</span>
                </div>
                <div className={styles.genderContainer}>
                  {isLoading ? (
                    <LottieLoader
                      animationData={PawLoading}
                      className={styles.loader}
                    />
                  ) : male.length <= 0 ? (
                    <div className={styles.noData}>
                      No Male assets available
                      <button className={styles.buyButton} onClick={()=>navigate('/marketplace')}>Buy Male Assets</button>

                    </div>
                  ) : (
                    <Row>
                      {male.map((data, index) => (
                        <Col
                          span={12}
                          key={index}
                          onDragStart={(e) => dragStart(e, data, index)}
                          draggable
                        >
                          <div
                            className={
                              maleIndex == index
                                ? styles.assetSelected
                                : styles.genderCard
                            }
                          >
                            <img
                              className={styles.assetImage}
                              src={data.previewImage}
                              onClick={() => {
                                clickMaleFucntion(index, data);
                              }}
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  )}
                </div>
              </Col>
              <Col span={1}></Col>
              <Col span={11} className={styles.body}>
                <div className={styles.gender}>
                  FEMALE <span className={styles.count}>{female.length}</span>
                </div>
                <div className={styles.genderContainer}>
                  {isLoading ? (
                    <LottieLoader
                      animationData={PawLoading}
                      className={styles.loader}
                    />
                  ) : female.length <= 0 ? (
                    <div className={styles.noData}>
                      No Female assets available
                      <button className={styles.buyButton} onClick={()=>navigate('/detail/61c9c7ed5f3a0854f77fa96d')}>Buy Female Lander</button>
                    </div>
                  ) : (
                    <Row>
                      {female.map((data, index) => (
                        <Col span={12} key={index}>
                          <div
                            className={
                              femaleIndex == index
                                ? styles.assetSelected
                                : styles.genderCard
                            }
                            onDragStart={(e) => dragStart(e, data, index)}
                          >
                            <img
                              className={styles.assetImage}
                              src={data.previewImage}
                              onClick={() => {
                                clickFemaleFucntion(index, data);
                              }}
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  )}
                </div>
              </Col>
              <Col span={1}></Col>
            </Row>
          </div>
        </Col>
        <Col md={12} lg={12} sm={24} xs={24}>
          <Row>
            <Col span={6}>
              <div className={styles.card}>
                <div className={styles.maleCard} onDrop={droped}>
                  {maleBreed?._id ? (
                    <img
                      src={maleBreed.previewImage}
                      className={styles.previewImage}
                    />
                  ) : (
                    <img src={Hatch} className={styles.previewImage} />
                  )}
                  <div className={styles.drop}>Drop male dog here</div>
                </div>

                <div className={styles.femaleCard} onDrop={femaleDroped}>
                  {femaleBreed?._id ? (
                    <img
                      src={femaleBreed.previewImage}
                      className={styles.previewImage}
                    />
                  ) : (
                    <img src={Hatch} className={styles.previewImage} />
                  )}
                  <div className={styles.drop}>Drop Female dog here</div>
                </div>
              </div>
            </Col>
            <Col span={18}>
              <img src={Farm} className={styles.farm} />
              <div className={styles.button}>
                <TransparentButton
                  outerContainer={styles.button}
                  label="BreedNow"
                  onClick={() => {
                    setIsBreedModal(true);
                  }}
                  disabled={femaleBreed?._id && maleBreed?._id ? false : true}
                />
              </div>
            </Col>
          </Row>
          <div className={styles.price}>
            Price/Pair <br />
            <span className={styles.amount}>25.00 USDT</span>
            <span style={{ color: "#00A2FD" }}>(Polygon)</span>
          </div>
        </Col>
        {/* <Col md={6} lg={6} sm={24} xs={24}>
          <div>
            <img src={BreedBox} className={styles.breedBox} />
            <Row>
              <Col span={10}>
                <div className={styles.breedCard} onDrop={droped}>
                  {maleBreed?._id ? (
                    <img src={maleBreed.previewImage} width="100%" />
                  ) : (
                    <div className={styles.placeholder}>Male</div>
                  )}
                </div>
              </Col>
              <Col span={4}>
                <img src={BreedCenter} className={styles.breedCenter} />
              </Col>
              <Col span={10}>
                <div className={styles.breedCard} onDrop={femaleDroped}>
                  {femaleBreed?._id ? (
                    <img src={femaleBreed.previewImage} width="100%" />
                  ) : (
                    <div className={styles.placeholder}>Female</div>
                  )}
                </div>
              </Col>
            </Row>
            <img src={BreedBottom} className={styles.breedBox} />
            <div className={styles.breedButton}>
              <Button
                label="Breed Now"
                onClick={() => {
                  setIsBreedModal(true);
                }}
                disabled={femaleBreed?._id && maleBreed?._id ? false : true}
              />
            </div>
            <div className={styles.price}>
              Breeding Fee <br />
              <img src={Usdt} className={styles.icon} />
              <span className={styles.amount}>25.00 USDT</span>
              (Polygon)
            </div>
          </div>
        </Col> */}
      </Row>

      <BreedingHistory />
      <BlackModal
        visible={isBreedModal}
        onClose={() => {
          setIsBreedModal(false);
        }}
        outerClassName={styles.modal}
      >
        <BreedModal male={maleBreed} female={femaleBreed} />
      </BlackModal>
    </div>
  );
};
export default Breeding;
