import React, { useState, Component } from "react";
import cn from "classnames";
import Styles from "./Faq.module.sass";
import { DropdownButton } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import Footer from "../../components/Footer";
import { Collapse } from "antd";
import "./faq.css";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}

const Faq = () => {
  const [index, setIndex] = useState(0);
  const faqs = [
      {
        id: 1,
        question: "What is CYBERDOGZ?",
        answer:
          "CYBERDOGZ is a metaverse game where users can own & Interact with your Digital Dog the CYBERDOGZ metaverse users do not just get to own a digital dog but you can also interact, pet, play and feed with it in Augmented Reality.Your very own digital pet!galaxy, Andromeda with them. These purebred dogs are enhanced.",
      },
      {
        id: 2,
        question: "What is Blockchain?",
        answer:
          "A blockchain is a distributed ledger that is shared among the nodes of a computer network. In blockchain information is stored electronically in digital format. Blockchains supports various cryptocurrency systems, such as Bitcoin, Ethereum, etc.The technology blockchain supports immutable transactions generating trust.",
      },
      {
        id: 3,
        question: "What are tokens and network?",
        answer:
          "Crypto tokens are fungible digital assets which has certain supply and value.Tokens reside in  a blockchain which are unique to a network. Ethereum, Binance Chain, Polygon are few examples of a network.",
      },
      {
        id: 4,
        question: "What are NFTs?",
        answer:
          "NFTs are short for Non Fungible Tokens which are unique and not interchangeable.In CYBERDOGZ each dog will be a NFT which is owner by the players who use the platform.",
      },
      {
        id: 5,
        question: "What is a wallet?",
        answer:
          "Wallet is a unique address owned by the user for holding cryptocurrency tokens.",
        link: "",
      },
      {
        id: 6,
        question: "How to access the wallet and tokens?",
        answer:
          "Metamask is a crypto wallet application & gateway to blockchain apps. Available as a browser extension and as a mobile app, MetaMask equips you with a key vault, secure login, token wallet, and token exchange—everything you need to manage your digital assets. Install the metamask extension to your browser.",
      },
      {
        id: 7,
        question: "What is seed and private key",
        answer:
          "A seed is superset key for all the wallets with the user. Private key is the unique password or access for a single wallet.",
      },
      {
        id: 8,
        question: "How to create new account in CYBERDOGZ?",
        answer:
          "Navigate to CYBERDOGZ.io or the marketplace in the browser and click on connect wallet. It will ask for metamask extension and request to select the wallet. Select the preferred wallet in metamask and connect.",
      },
      {
        id: 9,
        question: "What is available for users in CYBERDOGZ?",
        answer:
          "In phase 1 users are allowed to purchase selective CYBERDOGZ NFTS. These NFTs will give users the access to Dogz racing and other games that will be part of the ecosystem.",
      },
      {
        id: 10,
        question: "Do I need Dogz NFTs to access CYBERDOGZ?",
        answer:
          "Dogz NFTs are the access pass for any user. These are characters with which a user can participate all the games in the CYBERDOGZ ecosystem. extension and request to select the wallet. Select the preferred wallet in metamask and connect.",
      },
    ]
  return (
    <div className={cn("section", Styles.section)}>
      <div className={cn("container", Styles.container)}>
        <h3 className={cn("h3", Styles.title)}>FAQ</h3>
        <div className={Styles.content}>
        {faqs.map((faq) => (
          <div key={faq.id}>
            <div className={index === faq.id ? Styles.faqFlex1 : Styles.faqFlex}>
              <div className={Styles.store}>{faq.question}</div>
              {index !== faq.id ? (
                <MdKeyboardArrowDown
                  onClick={() => {
                    setIndex(faq.id);
                  }}
                />
              ) : (
                <MdKeyboardArrowUp
                  onClick={() => {
                    setIndex(0);
                  }}
                />
              )}
            </div>
            {index === faq.id && (
              <div className={Styles.grop}>
                {faq.answer}
                {faq.link && (
                  <a href={faq.link} target="_blank">
                    {faq.link}
                  </a>
                )}
              </div>
            )}
          </div>
        ))}
        </div>
        {/* <div className={Styles.title}>
            <div className={Styles.store}>1. What is CYBERDOGZ?</div>
            <div className={Styles.grop}>
              <p>
                {
                  "CYBERDOGZ is a metaverse game where users can own & Interact with your Digital Dog the CYBERDOGZ metaverse users do not just get to own a digital dog but you can also interact, pet, play and feed with it in Augmented Reality.Your very own digital pet!galaxy, Andromeda with them. These purebred dogs are enhanced."
                }
              </p>
            </div>
            <div className={Styles.store}>2. What is Blockchain?</div>
            <div className={Styles.grop}>
              <p>
                {
                  "A blockchain is a distributed ledger that is shared among the nodes of a computer network. In blockchain information is stored electronically in digital format. Blockchains supports various cryptocurrency systems, such as Bitcoin, Ethereum, etc.The technology blockchain supports immutable transactions generating trust."
                }
              </p>
            </div>
            <div className={Styles.store}>3. What are tokens and network?</div>
            <div className={Styles.grop}>
              <p>
                {
                  "Crypto tokens are fungible digital assets which has certain supply and value.Tokens reside in  a blockchain which are unique to a network. Ethereum, Binance Chain, Polygon are few examples of a network."
                }
              </p>
            </div>
            <div className={Styles.store}>4. What are NFTs?</div>
            <div className={Styles.grop}>
              <p>
                {
                  "NFTs are short for Non Fungible Tokens which are unique and not interchangeable.In CYBERDOGZ each dog will be a NFT which is owner by the players who use the platform."
                }{" "}
              </p>{" "}
            </div>
            <div className={Styles.store}>5. What is a wallet?</div>
            <div className={Styles.grop}>
              <p>
                {
                  " Wallet is a unique address owned by the user for holding cryptocurrency tokens."
                }
              </p>
            </div>
            <div className={Styles.store}>
              6. How to access the wallet and tokens?
            </div>
            <div className={Styles.grop}>
              <p>
                {
                  "Metamask is a crypto wallet application & gateway to blockchain apps. Available as a browser extension and as a mobile app, MetaMask equips you with a key vault, secure login, token wallet, and token exchange—everything you need to manage your digital assets. Install the metamask extension to your browser."
                }
              </p>
            </div>
            <div className={Styles.store}>7. What is seed and private key</div>
            <div className={Styles.grop}>
              <p>
                {
                  "A seed is superset key for all the wallets with the user. Private key is the unique password or access for a single wallet."
                }
              </p>{" "}
            </div>
            <div className={Styles.store}>
              8. How to create new account in CYBERDOGZ?
            </div>
            <div className={Styles.grop}>
              <p>
                {
                  "Navigate to CYBERDOGZ.io or the marketplace in the browser and click on connect wallet. It will ask for metamask extension and request to select the wallet. Select the preferred wallet in metamask and connect."
                }
              </p>
            </div>
            <div className={Styles.store}>
              9. What is available for users in CYBERDOGZ?
            </div>
            <div className={Styles.grop}>
              <p>
                {
                  "In phase 1 users are allowed to purchase selective CYBERDOGZ NFTS. These NFTs will give users the access to Dogz racing and other games that will be part of the ecosystem."
                }
              </p>
            </div>{" "}
            <div className={Styles.store}>
              10. Do I need Dogz NFTs to access CYBERDOGZ?
            </div>
            <div className={Styles.grop}>
              <p>
                {
                  "Dogz NFTs are the access pass for any user. These are characters with which a user can participate all the games in the CYBERDOGZ ecosystem. extension and request to select the wallet. Select the preferred wallet in metamask and connect."
                }
              </p>
            </div>
          </div> */}
      </div>
    </div>
  );
};
export default Faq;
{
  /* <script>
var coll = document.getElementsByClassName("collapsible");
var i;

for (i = 0; i < coll.length; i++) {
  coll[i].addEventListener("click", function() {
    this.classList.toggle("active");
    var content = this.nextElementSibling;
    if (content.style.maxHeight){
      content.style.maxHeight = null;
    } else {
      content.style.maxHeight = content.scrollHeight + "px";
    }
  })
}
</script> */
}
// export default Faq;
