import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import { Link, useParams } from "react-router-dom";
import styles from "./Profile.module.sass";
import Icon from "../../components/Icon";
import User from "./User";
import Items from "./Items";
import OwnedLand from "../../components/Profile/index";
import Followers from "./Followers";
import Bids from "./Bids";
import PolygonAssets from "../../components/PolygonAsset";
import Auction from "../../abi/Auction.json";
import { ethers, utils, providers } from "ethers";

import {
  GetFollowers,
  GetFollowings,
  GetUserAssets,
  GetBuyerUser,
  VerifyUser,
  GetBidsByUser,
  RentAssets,
  RentHistories,
  GetKibblezHistory,
  GetBonezHistory,
  GetWithdrawHistory,
} from "../../services/ApiServices";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd";
import RentHistory from "./RentHistory";
import GemCard from "../../components/GemCard";
import { INFURA_PROVIDER_KEY } from "../../Config/config";
import Tab from "../../components/tab";
import {
  MdOutlineAccountBalanceWallet,
  MdOutlineCollectionsBookmark,
} from "react-icons/md";
import { BiCoinStack, BiTransferAlt } from "react-icons/bi";
import { GrTransaction } from "react-icons/gr";
import { IoNotificationsOutline } from "react-icons/io5";
import { CiSettings } from "react-icons/ci";
import Assets from "./Assets";
import TransactionHistories from "../../components/TransactionHistories";
import { useWeb3React } from "@web3-react/core";
import { useNavigate } from "react-router-dom";
import Bank from "../Bank";
import Stake from "../../components/Header/Stake";

const navLinks = [
  "All Assets",
  "Assets - Ethereum",
  "Assets - Polygon",
  "Rented Out Assets",
  "Gem Cardz",
  "Bids",

  // "Likes",
  // "Following",
  // "Followers",
];

const Profile = (props) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isBidLoading, setIsBidLoading] = useState(false);
  const [kebblezHistoires, setKibblezHistories] = useState([]);
  const [isKibblezLoading, setIsKibblezLoading] = useState(false);
  const [bonezHistoires, setBonezHistories] = useState([]);
  const [isBonezLoading, setIsBonezLoading] = useState(false);
  const [ERC_20Histoires, setERC_20Histories] = useState([]);
  const [isERC_20Loading, setIsERC_20Loading] = useState(false);
  const [user, setUser] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [userLoading, setUserLoading] = useState(false);
  const [isFollow, setIsFollow] = useState(false);
  const [bids, setBids] = useState([]);
  const [polygonAssets, setPolygonAssets] = useState([]);
  const [ethereumAssets, setEthereumAssets] = useState([]);
  const [rinkebyAssets, setRinkebyAssets] = useState([]);
  const [rentLoading, setRentLoading] = useState(false);
  const [rentAssets, setRentAssets] = useState([]);
  const [rentHistory, setRentHistory] = useState([]);
  const [rentHistoryLoading, setRentHistoryLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [selectedValue, setSelectedValue] = useState(0);
  const { account, active, activate, error, deactivate } = useWeb3React();
  const navigate = useNavigate();

  const menu = [
    { name: "Assets", icon: <MdOutlineCollectionsBookmark />, isLogin: false },
    { name: "Stake", icon: <BiCoinStack /> },
    { name: "Balance", icon: <MdOutlineAccountBalanceWallet /> },
    { name: "Transactions", icon: <BiTransferAlt /> },
    { name: "Personal Settings", icon: <CiSettings /> },
  ];
  // const { uid } = useParams();
  useEffect(() => {
    if (account) {
      getAssets();
      // getUser();
      getBids();
      getRentAssetsHistory();
    } else {
      navigate("/");
    }
  }, [account]);
  // const AuctionAddress = "0x7AD718D00C99867ad7917830Fa170b91C57A0Adb";
  const AuctionContractAddress = "0x28b5633Dc11Df4D55f545B753f4E8528addeAf80";
  useEffect(() => {
    if (account) AuctionContractContract();
  }, [account]);

  useEffect(() => {
    if (account) {
      // getKibblez(uid);
      getKibblezHistory(account);
      getBonezHistory(account);
      // getBonez(uid)
      getWithdrawHistory(account);
    }
  }, [account]);

  const getKibblezHistory = async (id) => {
    setIsKibblezLoading(true);
    let result;
    try {
      result = await GetKibblezHistory(id);
    } catch (e) {
      console.log(e);
      setIsKibblezLoading(false);
    }
    try {
      if (result.success) {
        setIsKibblezLoading(false);
        setKibblezHistories(result.result);
      }
    } catch (e) {
      setIsKibblezLoading(false);
      console.log(e);
    }
  };
  const getBonezHistory = async (id) => {
    setIsBonezLoading(true);
    let result;
    try {
      result = await GetBonezHistory(id);
    } catch (e) {
      console.log(e);
      setIsBonezLoading(false);
    }
    try {
      if (result.success) {
        setIsBonezLoading(false);
        setBonezHistories(result.result.docs);
      }
    } catch (e) {
      setIsBonezLoading(false);
      console.log(e);
    }
  };
  const getWithdrawHistory = async (id) => {
    setIsERC_20Loading(true);
    let result;
    try {
      result = await GetWithdrawHistory(id);
    } catch (e) {
      console.log(e);
      setIsERC_20Loading(false);
    }
    try {
      if (result.success) {
        setIsERC_20Loading(false);
        setERC_20Histories(result.result.docs);
      }
    } catch (e) {
      setIsERC_20Loading(false);
      console.log(e);
    }
  };

  const AuctionContractContract = async () => {
    try {
      let provider;
      let auction;
      if (typeof window.ethereum == "undefined") {
        provider = new providers.InfuraProvider("homestead", {
          projectId: INFURA_PROVIDER_KEY,
        });

        auction = new ethers.Contract(
          AuctionContractAddress,
          Auction.abi,
          provider
        );
      } else {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        provider = new providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        auction = new ethers.Contract(
          AuctionContractAddress,
          Auction.abi,
          signer
        );
      }
      let data = await auction.getCurrentBidForUser(1, account);
      let owner = await auction.owner();
      // setBids(data);
      let tokenAddress = data.toString().split(",")[0];
      let tokenId = data.toString().split(",")[1];
      let startPrice = data.toString().split(",")[2];
      let amount = data.toString().split(",")[3];
      let tradable = data.toString().split(",")[4];
      let sold = data.toString().split(",")[5];
      let duration = data.toString().split(",")[6];
      let endTime = data.toString().split(",")[7];
      let price = utils.formatUnits(
        utils.parseUnits(startPrice.toString(), "wei"),
        "ether"
      );
      var value = {
        tokenAddress: tokenAddress,
        tokenId: tokenId,
        startPrice: price,
        amount: amount,
        tradable: tradable,
        sold: sold,
        duration: duration,
        endTime: endTime,
      };
      // setAuctionValue(value);
    } catch (e) {
      console.log(e);
    }
  };

  const getAssets = async () => {
    setIsLoading(true);
    let result;

    try {
      result = await GetUserAssets(account, "onlydogz=false&rents=true");
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }

    try {
      if (result.success) {
        setIsLoading(false);
        getRentAssets(result.result.token);
        setAssets(result.result.assets);
        setUser(result.result);
        setBids(result.result.assets);
        let card = result.result.assets.filter(function (data) {
          if (data.chainId === 10000) {
            return data;
          }
        });
        setCards(card);
        let polygon = result.result.assets.filter(function (data) {
          if (data.chainId === 137) {
            return data;
          }
        });
        setPolygonAssets(polygon);
        let mainnet = result.result.assets.filter(function (data) {
          if (data.chainId === 1) {
            return data;
          }
        });
        setEthereumAssets(mainnet);
        let rinkeby = result.result.assets.filter(function (data) {
          if (data.chainId === 4) {
            return data;
          }
        });
        setRinkebyAssets(rinkeby);
      }
    } catch (e) {
      setIsLoading(false);

      console.log(e);
    }
  };
  const getRentAssets = async (token) => {
    setRentLoading(true);
    let result;

    try {
      result = await RentAssets(token);
    } catch (e) {
      console.log(e);
      setRentLoading(false);
    }

    try {
      if (result.success) {
        setRentLoading(false);
        setRentAssets(result.result);
      }
    } catch (e) {
      setRentLoading(false);
      console.log(e);
    }
  };
  const getRentAssetsHistory = async () => {
    setRentHistoryLoading(true);
    let result;

    try {
      result = await RentHistories(account);
    } catch (e) {
      console.log(e);
      setRentHistoryLoading(false);
    }

    try {
      if (result.success) {
        setRentHistoryLoading(false);
        setRentHistory(result.result);
      }
    } catch (e) {
      setRentHistoryLoading(false);
      console.log(e);
    }
  };
  const getBids = async () => {
    setIsBidLoading(true);
    let result;

    try {
      result = await GetBidsByUser(account);
    } catch (e) {
      console.log(e);
      setIsBidLoading(false);
    }

    try {
      if (result.success) {
        setIsBidLoading(false);
        setBids(result.result);
      }
    } catch (e) {
      setIsBidLoading(false);

      console.log(e);
    }
  };
  // const getUser = async () => {
  //   setUserLoading(true);
  //   let result;

  //   try {
  //     result = await VerifyUser(uid);
  //   } catch (e) {
  //     console.log(e);
  //   }

  //   try {
  //     if (result.success) {
  //       setUserLoading(false);
  //       setUser(result.result);
  //       // setIsFollow(follow);
  //     }
  //   } catch (e) {
  //     setUserLoading(false);
  //     console.log(e);
  //   }
  // };
  const onChange = (value) => {
    setSelectedValue(value);
  };
  return (
    <div className={styles.container}>
      <Tab menu={menu} value={selectedValue} onChange={onChange} />
      {selectedValue == 0 ? (
        <Assets />
      ) : selectedValue == 1 ? (
        <div className={styles.stakeContainer}>
          <Stake />
        </div>
      ) : selectedValue == 2 ? (
        <Bank />
      ) : selectedValue == 3 ? (
        <TransactionHistories
          kebblezHistoires={kebblezHistoires}
          isKibblezLoading={isKibblezLoading}
          isBonezLoading={isBonezLoading}
          bonezHistoires={bonezHistoires}
          ERC_20Histoires={ERC_20Histoires}
          isERC_20Loading={isERC_20Loading}
        />
      ) : selectedValue == 4 ? (
        <User
          className={styles.user}
          item={user}
          getUser={getAssets}
          isFollow={isFollow}
        />
      ) : null}
    </div>

    // <div className={cn("container", styles.container)}>

    //   <p className={styles.title}>
    //     <span className={styles.heading}>Account </span> Details
    //   </p>
    //   <Row>
    //     <Col lg={5} md={8} sm={24} xs={24}>
    //       <User
    //         className={styles.user}
    //         item={user}
    //         getUser={getAssets}
    //         isFollow={isFollow}
    //       />
    //     </Col>
    //     <Col lg={19} md={16} sm={24} xs={24}>
    //       <div className={styles.nav}>
    //         {navLinks.map((x, index) => (
    //           <button
    //             className={cn(styles.link, {
    //               [styles.active]: index === activeIndex,
    //             })}
    //             key={index}
    //             onClick={() => setActiveIndex(index)}
    //           >
    //             {x}
    //           </button>
    //         ))}
    //       </div>
    //       <div className={styles.group}>
    //         <div className={styles.items}>
    //           {activeIndex === 0 && (
    //             <>
    //               {/* <div>
    //           <p className={styles.sub_title}>
    //             <span className={styles.heading}>Ethereum </span> Assets
    //           </p>

    //         </div> */}
    //               {/* <OwnedLand class={styles.items} uid={uid} showPolygon={() => setActiveIndex(1)} items={assets} isLoading={isLoading} /> */}
    //               <PolygonAssets
    //                 class={styles.items}
    //                 index={activeIndex}
    //                 isLoading={isLoading}
    //                 assets={assets}
    //               />
    //             </>
    //           )}
    //           {activeIndex === 1 && (
    //             <PolygonAssets
    //               class={styles.items}
    //               index={activeIndex}
    //               isLoading={isLoading}
    //               showPolygon={() => setActiveIndex(1)}
    //               assets={ethereumAssets}
    //             />
    //           )}
    //           {activeIndex === 2 && (
    //             <PolygonAssets
    //               class={styles.items}
    //               index={activeIndex}
    //               isLoading={isLoading}
    //               assets={polygonAssets}
    //             />
    //           )}
    //           {activeIndex === 3 && (
    //             <PolygonAssets
    //               class={styles.items}
    //               index={activeIndex}
    //               isLoading={rentLoading}
    //               assets={rentAssets}
    //             />
    //           )}
    //           {activeIndex === 4 && <GemCard items={cards} />}
    //           {activeIndex === 5 && (
    //             <Bids class={styles.items} items={assets} />
    //           )}
    //           {activeIndex === 6 && (
    //             <PolygonAssets
    //               class={styles.items}
    //               index={activeIndex}
    //               isLoading={isLoading}
    //               assets={rinkebyAssets}
    //             />
    //           )}
    //           {activeIndex === 7 && (
    //             <Followers className={styles.followers} items={following} />
    //           )}
    //           {activeIndex === 8 && (
    //             <Followers className={styles.followers} items={followers} />
    //           )}
    //         </div>
    //       </div>
    //     </Col>
    //   </Row>
    //   {/* <div className={styles.wrapper}> */}

    //   {/* </div> */}
    //   {/* </div> */}
    //   {/* </div>
    // // <div className={styles.profile}>*/}
    //   {/* <div
    //     className={cn(styles.head, { [styles.active]: visible })}
    //     style={{
    //       backgroundImage: "url(/images/content/bg-profile.jpg)",
    //     }}
    //   >
    //     <div className={cn("container", styles.container)}>
    //       <div className={styles.btns}>
    //         <button
    //           className={cn("button-stroke button-small", styles.button)}
    //           onClick={() => setVisible(true)}
    //         >
    //           <span>Edit cover photo</span>
    //           <Icon name="edit" size="16" />
    //         </button>
    //         <Link
    //           className={cn("button-stroke button-small", styles.button)}
    //           to="/profile-edit"
    //         >
    //           <span>Edit profile</span>
    //           <Icon name="image" size="16" />
    //         </Link>
    //       </div>
    //       <div className={styles.file}>
    //         <input type="file" />
    //         <div className={styles.wrap}>
    //           <Icon name="upload-file" size="48" />
    //           <div className={styles.info}>Drag and drop your photo here</div>
    //           <div className={styles.text}>or click to browse</div>
    //         </div>
    //         <button
    //           className={cn("button-small", styles.button)}
    //           onClick={() => setVisible(false)}
    //         >
    //           Save photo
    //         </button>
    //       </div>
    //     </div>

    //   </div>
    //   */}
    //   {/* </div> */}
    //   {activeIndex === 3 && (
    //     <div className={styles.item}>
    //       <div className={styles.title}>
    //         <span className={styles.heading}>Rent</span> History
    //       </div>

    //       <RentHistory history={rentHistory} getRentAssetsHistory={getRentAssetsHistory} loading={rentHistoryLoading} uid={uid} />
    //     </div>
    //   )}
    // </div>
  );
};

export default Profile;
