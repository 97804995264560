import { combineReducers } from 'redux'

import PostsReducer from './Posts/PostsReducer'
import UserReducer from './User/UserReducer'
import ContractReducer from './Contract/ContractReducer'
import UsdReducer from './Usd/UsdReducer'

const rootReducer = combineReducers ({
    Posts:PostsReducer,
    User:UserReducer,
    Contract:ContractReducer,
    Usd:UsdReducer

})

export default rootReducer
