import React, { useEffect, useState } from "react";
import cn from "classnames";
// import styles from "./Profile.module.sass";
import styles from "./Polygon.module.sass";
import TextInput from "../TextInput";
import Loader from "../../components/Loader";
import { Row, Col, Tooltip } from "antd";
import { ethers, utils, providers } from "ethers";
import Modal from "../Modal";
import AndromedaLand from "../../abi/AndromedaLand.json";
import Male from "../../images/male.png";
import Female from "../../images/female.png";
import frozonCapsule from "../../abi/capsule.json";
import Claim from "../../screens/Claim";
import LanderMint from "../../screens/LanderMint";
import Avatar from "antd/lib/avatar/avatar";
import EthereumImg from "../../images/Ethereum.png";
import Polygon from "../../images/polygon.png";
import Cup from "../../images/cup.png";
import { Link, useParams } from "react-router-dom";
import { ALCHEMY_PROVIDER_KEY, INFURA_PROVIDER_KEY } from "../../Config/config";
import PrimaryButton from "../PrimaryButton";
import BlackModal from "../BlackModal";
const Profile = (props, { className }) => {
  const [address, setAddress] = useState();
  const [errormsg, setErrormsg] = useState();
  const [isSelectWallet, setIsSelectWallet] = useState(false);
  const [ownedLand, setOwnedLand] = useState(0);
  const [ownedpolygonLand, setOwnedpolygonLand] = useState(0);
  const [ownfrozoncapsule, setFrozonCapsule] = useState(2);
  const [ownAsset, setOwnAsset] = useState();
  const [delevered, setDeleverd] = useState(false);
  const [isClaim, setIsClaim] = useState(false);
  const [capsule, setCapsule] = useState({});
  const { uid } = useParams();
  const andromedaAddress = "0xc72E061C4BCf042f8DDd2b82Ce0A9a7f0CF9329F";
  const forzoncapsuleAddress = "0x459124c1320BB66a2D2e58E04E7D28C344631Ded";
  const AssetAddress = "0x8daBBB3b26c2Eb1De543028b525Ba277bFC28227";
  const contractAddress = "0x862Dcd88D204d081896803152De5311A19999d2d";

  useEffect(() => {
    wallet();
    setAddress(uid);
  }, [uid]);
  const wallet = async () => {
    setAddress(uid);
    // getOwnLand();
    // getpolygonOwnLand()
  };

  const getOwnLand = async () => {
    setIsSelectWallet(false);
    setErrormsg("");
    try {
      const provider = new providers.InfuraProvider("homestead", {
        projectId: INFURA_PROVIDER_KEY,
      });
      // const provider = new ethers.providers.Web3Provider(window.ethereum);
      // const signer = provider.getSigner();
      // const signer = new ethers.Wallet(INFURA_PROVIDER_KEY, provider)
      let andromedaLand = new ethers.Contract(
        andromedaAddress,
        AndromedaLand.abi,
        provider
      );
      try {
        let supplyAfter = await andromedaLand.balanceOf(uid, "1");
        setOwnedLand(parseFloat(supplyAfter.toString()));
      } catch (error) {
        return console.log("Checking error from token price...", error);
      }
    } catch (error) {
      console.log(error);
      setErrormsg(error.message);
    }
  };
  const getpolygonOwnLand = async () => {
    setIsSelectWallet(false);
    setErrormsg("");
    try {
      const provider = new providers.AlchemyProvider(
        "matic",
        ALCHEMY_PROVIDER_KEY
      );

      // const signer = provider.getSigner();
      let andromedaLand = new ethers.Contract(
        "0x8d0C648f4a218398379D14A6E8eef72cb9E5d05D",
        AndromedaLand.abi,
        provider
      );
      try {
        let supplyAfter = await andromedaLand.balanceOf(uid, "1");
        setOwnedpolygonLand(parseFloat(supplyAfter.toString()));
      } catch (error) {
        return console.log("Checking error from token price...", error);
      }
    } catch (error) {
      console.log(error);
      setErrormsg(error.message);
    }
  };

  const getCapsuleSupply = async () => {
    setIsSelectWallet(false);
    setErrormsg("");
    // const web3 = new Web3(window.ethereum)
    try {
      if (!window.ethereum)
        throw new Error("No crypto wallet found. Please install it.");

      let user, meta;
      if (address) {
        setAddress(address);
        setIsSelectWallet(false);
      } else {
        meta = await window.ethereum.send("eth_requestAccounts");
        setAddress(meta.result[0]);
      }

      try {
        // user = await verifyBuyerAddress(address);
        // if (user.success && !user.message.includes("Address not exist.")) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        let value = 0;

        const signer = provider.getSigner();

        const address = await signer.getAddress();
        setAddress(address);
        // console.log("checking signer...", meta.result[0]);

        ethers.utils.getAddress(address);

        // await provider.getBalance(address).then((balance) => {
        //   value = utils.formatUnits(utils.parseUnits(balance.toString(), 'gwei'), 'ether')
        //   // value = utils.formatUnits(
        //   //   utils.parseUnits(buyReceipt.gasUsed.toString(), 'gwei'),
        //   //   'ether')
        // });

        value = await provider.getBalance(address, "latest");

        let displayBalance = utils.formatUnits(
          utils.parseUnits(value.toString(), "wei"),
          "ether"
        );

        // const tx = await signer.sendTransaction({
        //   to: address,
        //   value: ethers.utils.parseEther(
        //     (props.item.tokenPrice * eth).toString()
        //   ),
        // });

        let frozoncapsule = new ethers.Contract(
          forzoncapsuleAddress,
          frozonCapsule.abi,
          signer
        );
        try {
          let supplyAfter = await frozoncapsule.balanceOf(address, "1");

          setFrozonCapsule(parseFloat(supplyAfter.toString()));
        } catch (error) {
          return console.log("Checking error from token price...", error);
        }
        // }
        // else {
        //   setIsCreate(true);
        // }
      } catch (error) {
        // setIsDeleverd(true);
        setErrormsg(error.message);
      }
    } catch (err) {
      return setErrormsg(err.message);
    }
  };

  const land = (quantity, network) => {
    return (
      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
        <div className={styles.asset}>
          <Link to={"/detail/61e5377f7ccabd0e6cf6fde2"}>
            <div className={styles.preview}>
              <div className={styles.of}>
                <Tooltip title={"Rare"}>
                  <img src={Cup} />
                </Tooltip>
                <br />
              </div>
              <img
                src="https://cyberdogz.s3.us-west-1.amazonaws.com/nft/andromeda/images/parcel.png"
                alt="Card"
              />
            </div>
            <div className={styles.body}>
              <div className={styles.line}>
                <div className={styles.title}>{"CYBERDOGZ LAND"}</div>
                <div className={styles.buyNowDiv}>
                  <Avatar src={network == "ethereum" ? EthereumImg : Polygon} />
                </div>
              </div>
              <div className={styles.counter}>
                <div className={styles.quantity}>
                  <b>Quantity &nbsp;&nbsp;{quantity} </b>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </Col>
    );
  };

  return (
    <>
      {props.isLoading ? (
        <Loader className={styles.loader} />
      ) : props.assets.length >= 1 ? (
        <Row>
          {/* {ownedLand > 0 && (props.index == 1 || props.index == 0) && (
            land(ownedLand,"ethereum")
          )}
          {ownedpolygonLand > 0 && (props.index == 2 || props.index == 0) && (
            land(ownedpolygonLand,"polygon")
          )} */}
          {props.assets.map((x, index) => (
            <Col xs={24} sm={24} md={12} lg={6} xl={6} key={index}>
              <div className={styles.asset} key={x._id + index}>
                <Link
                  to={"/details/" + x._id + "/" + x.tokenId + "/" + x.chainId}
                >
                  <div className={styles.preview}>
                    <div className={styles.top}>
                      <div># &nbsp;&nbsp;{x.tokenId} </div>
                      {x.gender !== "NONE" && (
                        <div className={styles.genderDiv}>
                          <span>{x.gender}</span>
                          <img
                            src={x.gender == "MALE" ? Male : Female}
                            className={styles.gender}
                          />
                        </div>
                      )}{" "}
                    </div>
                    <div className={styles.of}>
                      <Tooltip title={x.rarity.name}>
                        <img src={x.rarity.icon} />
                      </Tooltip>
                      <br />
                    </div>
                    <img src={x.previewImage} alt="Card" />
                  </div>
                </Link>
                <div className={styles.divider}></div>

                <div className={styles.details}>
                  <div className={styles.body}>
                    <div className={styles.line}>
                      <div className={styles.title}>
                        {x.name.split(":")[0].toUpperCase()}
                      
                      </div>
                     
                      <div className={styles.buyNowDiv}>
                        <Avatar
                          // style={{background:"#EE9200"}}
                          src={x.chainId == 1 ? EthereumImg : Polygon}
                        />
                      </div>
                    </div>
                    {x.nickName && (
                          <div className={styles.nickName}>
                            ( {x.nickName} )
                          </div>
                        )}
                  </div>
                  <div className={styles.counter}>
                    {x._id == "61e5377f7ccabd0e6cf6fde2" ? null : x._id !=
                      "61c9c7ed5f3a0854f77fa96f" ? (
                      <div className={styles.size}>
                        {x.isRented && (
                          <div className={styles.rentdiv}>Rented</div>
                        )}
                      </div>
                    ) : (
                      <div className={styles.size}>
                        <b>Quantity: {x.available} </b>
                      </div>
                    )}

                    {x._id == "61c9c7ed5f3a0854f77fa96f" ||
                    x._id == "61c9c7ed5f3a0854f77fa96d" ? (
                      <div className={styles.buyNowDiv}>
                        <button
                          className={styles.buyNow}
                          onClick={() => {
                            setCapsule(x);
                            setIsClaim(!isClaim);
                          }}
                        >
                          MINT NOW
                        </button>
                      </div>
                    ) : x.assetType.type == "dog" ? (
                      <div className={styles.quantity}>
                        <b>Quantity: {x.available} </b>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </Col>
          ))}
          <BlackModal visible={isClaim} onClose={() => setIsClaim(false)}>
            {capsule._id == "61c9c7ed5f3a0854f77fa96f" ? (
              <Claim
                item={capsule}
                title={"Frozen Capsule"}
                onClose={() => {
                  setIsClaim(false);
                }}
                showPolygon={() => {
                  setIsClaim(false);
                  props.showPolygon();
                }}
                uid={uid}
                quantity={capsule.amount}
              />
            ) : capsule._id == "61c9c7ed5f3a0854f77fa96d" ? (
              <LanderMint
                item={capsule}
                title={"Frozen Capsule"}
                onClose={() => {
                  setIsClaim(false);
                }}
                showPolygon={() => {
                  setIsClaim(false);
                  props.showPolygon();
                }}
                uid={uid}
                quantity={capsule.amount}
              />
            ) : null}
          </BlackModal>
        </Row>
      ) : (
        <div className={styles.noassets}>{"No Assets Found"}</div>
      )}
    </>
  );
};

export default Profile;
