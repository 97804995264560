import React from 'react'
import Styles from './pagenation.module.sass'
import './pagenation.css'
import { Pagination } from 'antd'
 const Pagenation=(props)=> {
    
    return (
        <div className={Styles.pagenation}>
            <Pagination total={1000} size="small" className='pagenate' showSizeChanger={false} {...props} />
        </div>
    )
}
export default Pagenation