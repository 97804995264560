import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import moment from "moment";
import Ethereum from "../../images/Ethereum.png";
import { minifyAddress } from "../../utils/helpers";
import Styles from "./RentHistory.module.sass";
import Loader from "../Loader";
import HistoryShade from "../../images/historyshade.png";
import { Link } from "react-router-dom";

function RentHistory({ history ,loading}) {
  const [rentHistoryAccordian, setRentHistoryAccordian] = useState(true);

  return (
    <div className={Styles.history_table}>
      <div className={Styles.rowdata}>
        <Row>
          <Col span={1}>
            <div className={Styles.buyer}></div>
          </Col>
          <Col span={2}>
            <div className={Styles.seller}>
              Date <br /> Rented
            </div>
          </Col>
          <Col span={3}>
            <div className={Styles.seller}>
              Rentee <br /> Address
            </div>
          </Col>
          <Col span={2}>
            <div className={Styles.seller}>
              Owner <br /> Address
            </div>
          </Col>
          <Col span={2}>
            <div className={Styles.seller}>
              Matches <br /> Played
            </div>
          </Col>
          <Col span={2}>
            <div className={Styles.seller}>
              Time <br />
              Played
            </div>
          </Col>
          <Col span={3}>
            <div className={Styles.seller}>Total <br />$BONEZ Won</div>
          </Col>
          <Col span={2}>
            <div className={Styles.seller}>% Rental</div>
          </Col>
          <Col span={2}>
            <div className={Styles.seller}>
              My <br /> Earnings
            </div>
          </Col>
          <Col span={3}>
            <div className={Styles.seller}>
              Scholar
              <br /> Earnings
            </div>
          </Col>
          <Col span={2}>
            <div className={Styles.seller}>Status</div>
          </Col>
        </Row>
      </div>
      {/* <img src={HistoryShade} className={Styles.history_shade} /> */}
      {loading ? (
        <Loader className={Styles.loader} />
      ) : history.length ? (
        <>
          {history.map((data, index) => {
            return (
              <div
                className={Styles.rowdata}
                key={index}
              >
                <Row>
                  <Col span={1}>
                    <div className={Styles.pricefield}>  {index + 1}</div>
                  </Col>
                  <Col span={2}>
                    <div className={Styles.pricefield1}>
                      {moment.utc(data.rentTime*1000).format("DD-MM-YYYY")}
                    </div>
                  </Col>
                  <Col span={3}>
                    <div className={Styles.hash}>
                      <Link to={"/account/" + data.tenant}>
                        {minifyAddress(data.tenant, 6)}
                      </Link>
                    </div>
                  </Col>
                  <Col span={2}>
                    <div className={Styles.hash}>
                      <Link to={"/account/" + data.address}>
                        {minifyAddress(data.address, 6)}
                      </Link>
                    </div>
                  </Col>
                  <Col span={2}> <div className={Styles.pricefield1}>{data.totalMatches}</div></Col>
                  <Col span={2}>
                  <div className={Styles.pricefield1}>
                        {moment.utc(data.totalDuration * 1000).format("HH")} hrs&nbsp;
                        {moment.utc(data.totalDuration * 1000).format("mm")} mins
                      </div>
                  </Col>
                  <Col span={3}>
                    <div className={Styles.pricefield1}>{data.totalEarnings.toFixed(2)}</div>
                  </Col>
                  <Col span={2}> <div className={Styles.pricefield1}>{data.percentage}%</div></Col>
                  <Col span={2}> <div className={Styles.pricefield1}>{data.myEarnings?data.myEarnings.toFixed(2):0}</div></Col>
                  <Col span={3}> <div className={Styles.pricefield1}>{data.tenantEarnings?data.tenantEarnings.toFixed(2):0}</div></Col>
                  <Col span={2}> <div className={data.status=="REVOKED"?Styles.revokeStatus:Styles.rentStatus}>{data.status}</div></Col>
                </Row>
              </div>
            );
          })}
        </>
      ) : (
        <p className={Styles.empty}>No data available</p>
      )}
    </div>
  );
}

export default RentHistory;
