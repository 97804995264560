import React, { useEffect, useState } from "react";
import Styles from "../Challanges.module.sass";
import { Row, Col, Empty } from "antd";
import HistoryShade from "../../../../images/historyshade.png";
import moment from "moment";
import Loader from "../../../../components/Loader";
import Bonez from "../../../../images/bonez.png";
const CurrentChallenge = ({ currentChallenge, isLeaderboardLoading }) => {
  return (
    <>
      <div className={Styles.history_table}>
        <div className={Styles.rowdata}>
          <Row>
            <Col span={3}>
              <div className={Styles.seller}>Position</div>
            </Col>
            <Col span={3}>
              <div className={Styles.seller}>Name</div>
            </Col>
            <Col span={2}>
              <div className={Styles.seller}>Level</div>
            </Col>
            <Col span={2}>
              <div className={Styles.seller}>Kills</div>
            </Col>
            <Col span={3}>
              <div className={Styles.seller}>Points</div>
            </Col>
            <Col span={4}>
              <div className={Styles.seller}>Matches played</div>
            </Col>
            <Col span={4}>
              <div className={Styles.seller}>Total time spent</div>
            </Col>
            <Col span={3}>
              <div className={Styles.seller}>To win</div>
            </Col>
          </Row>
        </div>
        {/* <img src={HistoryShade} className={Styles.history_shade} /> */}
        {isLeaderboardLoading ? (
          <Loader className={Styles.loader} />
        ) : currentChallenge.length ? (
          <>
            {currentChallenge.map((data, index) => {
              return (
                <div
                  className={ Styles.rowdata}
                  key={index}
                >
                  <Row>
                    <Col span={3}>
                      <div className={Styles.pricefield}>{index + 1}</div>
                    </Col>
                    <Col span={3}>
                      <div className={Styles.pricefield}>{data.name}</div>
                    </Col>
                    <Col span={2}>
                      <div className={Styles.status}>{data.level}</div>
                    </Col>
                    <Col span={2}>
                      <div className={Styles.status}>{data.totalKills}</div>
                    </Col>
                    <Col span={3}>
                      <div className={Styles.status}>{data.total}</div>
                    </Col>
                    <Col span={4}>
                      <div className={Styles.status}>{data.totalMatches}</div>
                    </Col>
                    <Col span={4}>
                      <div className={Styles.pricefield}>
                        {moment.utc(data.totalDuration * 1000).hours()}
                        hrs&nbsp;
                        {moment.utc(data.totalDuration * 1000).minutes()}
                        mins&nbsp;
                        {moment.utc(data.totalDuration * 1000).seconds()}secs
                      </div>
                    </Col>

                    <Col span={3}>
                      {data.toWin ? (
                        <div className={Styles.status}>
                          {data.toWin}
                          <img className={Styles.icon} src={Bonez} />
                        </div>
                      ) : (
                        <div className={Styles.pricefield}>NA</div>
                      )}
                    </Col>
                  </Row>
                </div>
              );
            })}
          </>
        ) : (
          <p className={Styles.empty}>No data available</p>
        )}
      </div>
    </>
  );
};
export default CurrentChallenge;
