import React, { useEffect, useRef, useState } from "react";
import Styles from "./SelectModal.module.sass";
import { Row, Col } from "antd";
import TextInput from "../../../components/NewTextInput";
import Shade from "../../../images/stakeshade.png";
import {
  GetGameInfo,
  GetPlayableAssets,
  GetUserAssets,
} from "../../../services/ApiServices";
import Leftborder from "../../../images/leftborder.png";
import Loader from "../../../components/Loader";
import Bonze from "../../../images/bonez.png";
import Kibblez from "../../../images/kibblez.png";
import Button from "../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import CountDown from "../../../components/CountDown";
import { useWeb3React } from "@web3-react/core";
import Modal from "../../../components/NewModal";
import RaceModal from "../RaceModal";
import LottieLoader from "react-lottie-loader";
import Skeleton from "../../../json/skeleton.json";
import BlackModal from "../../../components/BlackModal";
const SelectModal = ({ GameShow, event, refreshRaceEvents }) => {
  const { account, active, activate, deactivate } = useWeb3React();
  const state = useSelector((state) => state);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isType1Loading, setIsType1Loading] = useState(false);
  const [isType2Loading, setIsType2Loading] = useState(false);
  const [user, setUser] = useState({});
  const [name, setName] = useState(
    state.User.user.userName ? state.User.user.userName : ""
  );
  const [error, setError] = useState("");
  const [asset, setAsset] = useState({});
  const [isRaceModal, setIsRaceModal] = useState(false);
  useEffect(() => {
    setUser(state.User.user);
    if (state.User.user) {
      setName(state.User.user.userName);
      setUser(state.User.user);
    }
  }, [state.User.user]);
  useEffect(() => {
    getAssets();
  }, [account]);
  const getAssets = async () => {
    setIsLoading(true);
    let result;
    try {
      result = await GetPlayableAssets(account);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
    try {
      if (result.success) {
        setIsLoading(false);
        let assets = result.result.filter((asset) => asset.gameId != 1000);
        setAssets(assets);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  return (
    <div className={Styles.stake}>
      <div className={Styles.head}>
        <span className={Styles.yield}>SELECT </span>YOUR CYBERDOGZ
      </div>
      <div className={Styles.container}>
        {isLoading ? (
          <Row>
            {[...Array(8)].map((x, index) => (
              <Col md={6} xs={24} sm={12} key={index}>
                {/* <Loader className={Styles.loader} /> */}
                <div className={Styles.skeleton}>
                  <LottieLoader animationData={Skeleton} />
                </div>
              </Col>
            ))}
          </Row>
        ) : assets.length ? (
          <Row>
            {assets.map((x, index) => (
              <Col md={6} xs={24} sm={12} key={index}>
                <div className={Styles.Card}>
                  <div
                    className={Styles.card}
                    onClick={() => {
                      setIsRaceModal(true);
                      setAsset(x);
                    }}
                  >
                    <img src={x.image} className={Styles.previewImg} />
                  </div>
                  <div className={Styles.lineBorder}></div>
                  <div className={Styles.cards}>
                    <div className={Styles.cardsTxt}>
                      <div className={Styles.title}>
                        {x.name.split(":")[0].toUpperCase()}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        ) : (
          <p className={Styles.empty}>No asset available</p>
        )}
      </div>
      <BlackModal
        onClose={() => setIsRaceModal(false)}
        visible={isRaceModal}
        outerClassName={Styles.modal}
      >
        <RaceModal
          asset={asset}
          event={event}
          refreshRaceEvents={refreshRaceEvents}
          onClose={() => setIsRaceModal(false)}
        />
      </BlackModal>
    </div>
  );
};
export default SelectModal;
