import React, { useEffect, useRef, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import styles from "./Stock.module.sass";
import { TfiTwitterAlt } from "react-icons/tfi";
import { SiDiscord } from "react-icons/si";
import { AiFillInstagram } from "react-icons/ai";
import { Col, Row } from "antd";
import Stockbg from "../../../images/stockbg.png";
import { GetAsset } from "../../../services/ApiServices";
import TransparentButton from "../../../components/TransparentButton";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";

const Stock = (props) => {
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getLiveAssets();
  }, []);
  const getLiveAssets = async () => {
    let result;
    setIsLoading(true);
    try {
      result = await GetAsset("&status=LIVE");
    } catch (e) {
      console.log(e);
    }
    try {
      if (result.success) {
        setAssets(result.assets.docs);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  return (
    <div className={styles.stock}>
      <div className={styles.viewMarketPlace}>
      <div className={styles.heading}>In Stock</div>
      <Link className={styles.link} to={"/marketplace"}><TransparentButton label="View MarketPlace" className={styles.views} /></Link>
      </div>
      {isLoading ? <Loader className={styles.loader}/> :
      <Row>
        {assets.slice(0, 10).map((asset, index) => (
          <Col
            md={11}
            sm={24}
            lg={11}
            xs={24}
            className={styles.card}
            key={index}
          >
            <img src={Stockbg} className={styles.stockbg} />
            {/* <Row>
              <Col xs={24} sm={24} lg={3} md={3}>
                <img src={asset.previewImage} className={styles.previewImage} />
              </Col>
              <Col xs={24} sm={24} lg={6} md={6} className={styles.heading}>
                {asset.name}
              </Col>
              <Col xs={24} sm={24} lg={4} md={4} className={styles.text}>
                {asset.available} of {asset.supply}
              </Col>
              <Col xs={24} sm={24} lg={4} md={4}className={styles.price}>
                {asset.tokenPrice} {asset.currency}
              </Col>
              <Col xs={24} sm={24} lg={4} md={4} className={styles.view}>
                <Link className={styles.link} to={"/detail/" + asset._id}>
                  <TransparentButton label="View" className={styles.views} />
                </Link>
              </Col>
            </Row> */}
            <div className={styles.stockFlex}>
            <img src={asset.previewImage} className={styles.previewImage} alt="asset"/>
            <div className={styles.nameFlex}>
              <div className={styles.price}>{asset.name}</div>
              <div className={styles.text}>
                {asset.available} of {asset.supply}
              </div>
              <div className={styles.price}>
              {asset.tokenPrice} {asset.currency}
              </div>
            </div>
            <div className={styles.view}>
                <Link className={styles.link} to={"/detail/" + asset._id}>
                  <TransparentButton label="View" className={styles.views} />
                </Link>
              </div>
            </div>
          </Col>
        ))}
      </Row>}
    </div>
  );
};
export default Stock;
