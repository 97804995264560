import React, { useState, useEffect } from "react";
import styles from "../../screens/Item/Item.module.sass";

function Characteristics({ faction }) {
  const [characteristicsAccordian, setCharacteristicsAccordian] =
    useState(true);
  // const [asset,setAsset] = useState()

  // useEffect(()=>{
  //     setAsset(props.asset)
  // },[props.asset])

  return (
    <>
    <div className={styles.accordianHead}>Characteristics</div>
    <div className={styles.characteristsAccordian}>
      {/* <div className={styles.accordian}>
        <div className={styles.accordianHead}>Characteristics</div>
        {characteristicsAccordian ? (
          <button
            className={styles.accordianImgMinus}
            onClick={() => setCharacteristicsAccordian(false)}
          ></button>
        ) : (
          <button
            className={styles.accordianImgPlus}
            onClick={() => setCharacteristicsAccordian(true)}
          ></button>
        )}
      </div> */}
      {characteristicsAccordian ? (
        <div className={styles.mysterious}>
          <div className={styles.mystryhead}>{faction.displayName}</div>
          <div>
            <div className={styles.first}>
              <div className={styles.combat}>
                <div className={styles.mystryheading}>{faction.characteristics[0].type}</div>
                <div className={styles.mystrydata}>
                  {faction.characteristics[0].value}
                </div>
              </div>
            </div>
            <div className={styles.second}>
              <div className={styles.cognition}>
                <div className={styles.mystryheading}>{faction.characteristics[1].type}</div>
                <div className={styles.mystrydata}>
                {faction.characteristics[1].value}
                </div>
              </div>
              <div className={styles.culture}>
                <div className={styles.mystryheading}>{faction.characteristics[2].type}</div>
                <div className={styles.mystrydata}>
                {faction.characteristics[2].value}
                </div>
              </div>
            </div>
            {/* <div className={styles.third}>
          <div className={styles.breed}>
            <div className={styles.mystryheading}>BREED</div>
            <div className={styles.mystrydata}>BOXER</div>
          </div>
        </div>
        <div className={styles.fourth}>
          <div className={styles.corevalue}>
            <div className={styles.mystryheading}>
              CORE VALUE
            </div>
            <div className={styles.mystrydata}>
              SECRETS OF UNIVERSE
            </div>
          </div>
          <div className={styles.distinct}>
            <div className={styles.mystryheading}>
              DISTINCT FEATURE
            </div>
            <div className={styles.mystrydata}>
              SILVER TOUNGHT
            </div>
          </div>
        </div> */}
        {faction.characteristics.length>3 &&
            <div className={styles.fifth}>
              <div className={styles.visual}>
                <div className={styles.mystryheading}>{faction.characteristics[3].type}</div>
                <div className={styles.mystrydata}>
                {faction.characteristics[3].value}
                </div>
              </div>
            </div>}
          </div>
        </div>
      ) : null}
    </div>
    </>
  );
}

export default Characteristics;
