import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import moment from "moment";
import { minifyAddress } from "../../../utils/helpers";
import Styles from "./RentHistory.module.sass";
import Loader from "../../../components/Loader";
import HistoryShade from "../../../images/historyshade.png";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import Modal from "../../../components/NewModal";
import TakeBack from "../../../components/TakeBack";
import { useWeb3React } from "@web3-react/core";
import BlackModal from "../../../components/BlackModal";
import PrimaryButton from "../../../components/PrimaryButton";

function RentHistory({ history, loading, uid,getRentAssetsHistory }) {
  const [isTakeBack, setIsTakeBack] = useState(false);
  const [rent, setRent] = useState({});
  const { account, active, activate, error, deactivate } = useWeb3React();

  return (
    <div className={Styles.history_table}>
      <div className={Styles.rowdata}>
        <Row>
          <Col span={1}>
            <div className={Styles.buyer}></div>
          </Col>
          <Col span={2}>
            <div className={Styles.seller}>
              Date <br /> Rented
            </div>
          </Col>
          <Col span={3}>
            <div className={Styles.seller}>
              Rentee <br /> Address
            </div>
          </Col>
          <Col span={2}>
            <div className={Styles.seller}>
              Matches <br /> Played
            </div>
          </Col>
          <Col span={2}>
            <div className={Styles.seller}>
              Time <br />
              Played
            </div>
          </Col>
          <Col span={3}>
            <div className={Styles.seller}>
              Total <br />
              $BONEZ Won
            </div>
          </Col>
          <Col span={2}>
            <div className={Styles.seller}>% Rental</div>
          </Col>
          <Col span={2}>
            <div className={Styles.seller}>
              My <br /> Earnings
            </div>
          </Col>
          <Col span={2}>
            <div className={Styles.seller}>
              Scholar
              <br /> Earnings
            </div>
          </Col>
          <Col span={2}>
            <div className={Styles.seller}>
            KIBBLEZ
              <br />  Balance
            </div>
          </Col>
          <Col span={3}>
            <div className={Styles.seller}>Status</div>
          </Col>
        </Row>
      </div>
      {/* <img src={HistoryShade} className={Styles.history_shade} /> */}
      {loading ? (
        <Loader className={Styles.loader} />
      ) : history.length ? (
        <>
          {history.map((data, index) => {
            return (
              <div
                className={Styles.rowdata}
                key={index}
              >
                <Row>
                  <Col span={1}>
                    <div className={Styles.pricefield}> {index + 1}</div>
                  </Col>
                  <Col span={2}>
                    <div className={Styles.pricefield}>
                      {moment.utc(data.rentTime * 1000).format("DD-MM-YYYY")}
                    </div>
                  </Col>
                  <Col span={3}>
                    <div className={Styles.hash}>
                      <Link to={"/account/" + data.tenant}>
                        {minifyAddress(data.tenant, 6)}
                      </Link>
                    </div>
                  </Col>
                  <Col span={2}>
                    {" "}
                    <div className={Styles.pricefield}>{data.totalMatches}</div>
                  </Col>
                  <Col span={2}>
                    <div className={Styles.pricefield}>
                      {moment.utc(data.totalDuration * 1000).format("HH")}{" "}
                      hrs&nbsp;
                      {moment.utc(data.totalDuration * 1000).format("mm")} mins
                    </div>
                  </Col>
                  <Col span={3}>
                    <div className={Styles.pricefield}>
                      {data.totalEarnings.toFixed(2)}
                    </div>
                  </Col>
                  <Col span={2}>
                    {" "}
                    <div className={Styles.pricefield}>{data.percentage}%</div>
                  </Col>
                  <Col span={2}>
                    {" "}
                    <div className={Styles.pricefield}>
                      {data.myEarnings ? data.myEarnings.toFixed(2) : 0}
                    </div>
                  </Col>
                  <Col span={2}>
                    {" "}
                    <div className={Styles.pricefield}>
                      {data.tenantEarnings ? data.tenantEarnings.toFixed(2) : 0}
                    </div>
                  </Col>
                  <Col span={2}>
                    {" "}
                    <div className={Styles.pricefield}>
                      {data.kibblezBalance ? data.kibblezBalance.toFixed(2) : 0}
                    </div>
                  </Col>
                  <Col span={3}>
                    {data.status == "REVOKED" ? (
                      <div className={Styles.revokeStatus}>{data.status}</div>
                    ) : account==uid?(

                      <div className={Styles.takebackButton}>
                        <button
                          onClick={() => {
                            setRent(data);
                            setIsTakeBack(true);
                          }}
                        >
                          Take Back
                        </button>
                      </div>
                    ):(
                      <div className={Styles.rentStatus}>{data.status}</div>
                    )
                    }
                  </Col>
                </Row>
              </div>
            );
          })}
        </>
      ) : (
        <p className={Styles.empty}>No data available</p>
      )}
      <BlackModal
        visible={isTakeBack}
        outerClassName={Styles.modal}
        onClose={() => setIsTakeBack(false)}
      >
        <TakeBack
          onClose={() => setIsTakeBack(false)}
          rentData={rent}
          uid={uid}
          getRentAssetsHistory={getRentAssetsHistory}
        />
      </BlackModal>
    </div>
  );
}

export default RentHistory;
