import React, { useEffect, useState } from "react";
import Styles from "../Table.module.sass";
import { Row, Col, Empty, Badge, Tooltip } from "antd";
import HistoryShade from "../../../../images/historyshade.png";
import {
  GetKibblezHistory,
  GetUserAssets,
} from "../../../../services/ApiServices";
import cn from "classnames";
import moment from "moment";
import Loader from "../../../../components/Loader";
import Kibblez from "../../../../images/kibblez.png";
import Bonez from "../../../../images/bonez.png";
import CounDown from "../../../../components/CountDown";
import Pagination from "../../../../components/Pagenation";
import { minifyAddress } from "../../../../utils/helpers";
import { Link } from "react-router-dom";
import Modal from "../../../../components/NewModal";
import RaceModal from "../../SelectModal";
import Countdown from "antd/lib/statistic/Countdown";
import ListLoading from "../../../../json/listLoading.json";
import LottieLoader from "react-lottie-loader";
import { FiPlay } from "react-icons/fi";
import { FaPlay } from "react-icons/fa";
import "../index.css";
import TableLoader from "../../../../components/TableLoader";
import { AiOutlinePlayCircle } from "react-icons/ai";
import BlackModal from "../../../../components/BlackModal";
const colors = [
  "#9B51E0",
  "#00a0a3",
  "#DA00B0",
  "#F2994A",
  "#DA1900",
  "#3A00FC",
  "#808b2d",
  "#0a9061",
  "#FC209F",
  "#FF555C",
];
const KibblezTable = ({
  events,
  assets,
  isLoading,
  eventsLoading,
  setPage,
  totalDocs,
  page,
  refreshRaceEvents,
}) => {
  const [selectedIdex, setSelectedIdex] = useState(0);
  const [assetSelect, setAssetSelect] = useState(false);
  const [selectedEvent, setSlelectedEvent] = useState({});
  return (
    <>
      <div className={Styles.history_table}>
        <div className={Styles.rowdata}>
          <Row>
            {/* <Col span={1}></Col> */}
            <Col span={2}> <div className={Styles.seller}>Race</div></Col>
            <Col span={4}>
              <div className={Styles.seller}>Race Details</div>
            </Col>
            <Col span={3}>
              <div className={Styles.seller}>Time (UTC)</div>
            </Col>

            <Col span={3}>
              <div className={Styles.seller}>Availability</div>
            </Col>
            <Col span={3}>
              <div className={Styles.seller}>Bet Amount</div>
            </Col>
            <Col span={2}>
              <div className={Styles.seller}>To Win</div>
            </Col>
            <Col span={3}>
              <div className={Styles.seller}>Action</div>
            </Col>
            <Col span={3}>
              <div className={Styles.seller}>Status</div>
            </Col>
          </Row>
        </div>
        {/* <img src={HistoryShade} className={Styles.history_shade} /> */}
        {/* <Loader className={Styles.loader} /> */}

        {eventsLoading ? (
          <TableLoader />
        ) : events.length ? (
          <>
            {events.map((event, index) => {
              return (
                <div className={Styles.rowdata} key={index}>
                  <Row>
                    {/* <Col span={1}>
                      <div
                        style={{
                          backgroundColor: colors[index % 10],
                          boxShadow: colors[index % 10] + " 0px 5px 15px",
                        }}
                        className={Styles.style}
                      ></div>
                    </Col> */}
                    <Col span={2}>
                      <div className={Styles.raceText}>
                        Race {event.batch}
                        <br />
                        <div
                         className={Styles.raceDay}
                          style={{
                            backgroundColor: colors[index % 10],
                            color:'white'
                          }}
                        >
                          {moment(event.timestamp * 1000)
                            .utc()
                            .format("dddd")}
                        </div>
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className={Styles.statusName}>
                        {event.name} #{event.roomNumber}
                      </div>
                    </Col>
                    <Col span={3}>
                      <div className={Styles.countdown}>
                        {moment(event.timestamp * 1000)
                          .utc()
                          .isAfter() ? (
                          <Countdown
                            value={event.timestamp * 1000}
                            format="H:mm:ss"
                          />
                        ) : (
                          <div className={Styles.status}>
                            {moment(event.timestamp * 1000)
                              .utc()
                              .format("hh:mm A")}
                          </div>
                        )}
                      </div>
                    </Col>

                    <Col span={3}>
                      <div className={Styles.pricefield}>
                        {event.registeredDogs.length}/10
                      </div>
                    </Col>
                    <Col span={3} className={Styles.freebadge}>
                      {event.betAmount ? (
                        <div className={Styles.winfield}>
                          <img className={Styles.icon} src={Kibblez} />
                          {event.betAmount}
                        </div>
                      ) : (
                        <div>
                          <div className={Styles.freebadgeDiv}>FREE ENTRY</div>
                          {/* <Badge.Ribbon
                            color="#32DA00"
                            text="FREE ENTRY"
                          ></Badge.Ribbon> */}
                        </div>
                      )}
                    </Col>

                    <Col span={2}>
                      <div className={Styles.winfield}>
                        <img className={Styles.icon} src={Bonez} />
                        {event.toWin}
                      </div>
                    </Col>
                    <Col span={3}>
                      <Row>
                        <Col span={24}>
                          {event.registrable ? (
                            <div className={Styles.joinContainer}>
                              <button
                                className={Styles.viewButton}
                                onClick={() => {
                                  setAssetSelect(true);
                                  setSlelectedEvent(event);
                                }}
                              >
                                Join
                              </button>
                              <Tooltip
                                placement="top"
                                color="#373B3B"
                                title="View Live race"
                              >
                                <Link
                                  to={"/race/live/" + event.gameId}
                                  className={Styles.playButton}
                                >
                                  <AiOutlinePlayCircle className={Styles.openicon} />
                                </Link>
                              </Tooltip>
                            </div>
                          ) : (
                            <>
                              {event.registeredDogs.length > 2 ? (
                                <div className={Styles.joinContainer}>
                                  <Link
                                    to={
                                      event.isLive
                                        ? "/race/live/" + event.gameId
                                        : "/race/replay/" + event.gameId
                                    }
                                    className={Styles.viewButton}
                                  >
                                    View Now
                                  </Link>
                                </div>
                              ) : (
                                <div className={Styles.status}>-</div>
                              )}
                            </>
                          )}
                        </Col>
                        {/* <Col span={12}>
                          <button className={Styles.betButton}>Side Bet</button>
                        </Col> */}
                      </Row>
                    </Col>
                    <Col span={3} className={Styles.freebadge}>
                      {event.isLive ? (
                        <div className={Styles.badge}>
                          <AiOutlinePlayCircle />
                          LIVE
                          {/* <Badge.Ribbon
                            color="#32DA00"
                            text="LIVE"
                          ></Badge.Ribbon> */}
                        </div>
                      ) : (
                        <div className={Styles.endBadge}>
                          ENDED
                          {/* <Badge.Ribbon color="red" text="ENDED"></Badge.Ribbon> */}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              );
            })}
          </>
        ) : (
          <p className={Styles.empty}>No data available</p>
        )}
        <div>
          <Pagination
            onChange={(page) => {
              setPage(page);
            }}
            total={totalDocs}
            current={page}
          />
        </div>
      </div>
      <BlackModal
        onClose={() => setAssetSelect(false)}
        visible={assetSelect}
        outerClassName={Styles.modal}
      >
        <RaceModal
          event={selectedEvent}
          refreshRaceEvents={refreshRaceEvents}
        />
      </BlackModal>
    </>
  );
};
export default KibblezTable;
