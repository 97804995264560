import React, { useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
// import { clearAllBodyScrollLocks } from "body-scroll-lock";
import styles from "./Page.module.sass";
import Header from "../Header";
import Footer from "../Footer";
import DiscordImg from "../../images/footDiscord.png";
import { useSnackbar } from "notistack";
import { Alert } from "antd";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  getErrorMessage,
  injected,
  useEagerConnect,
  useInactiveListener,
} from "../../utils/connections";
import { RiArrowDropUpLine } from "react-icons/ri";
import { BsArrowUpCircleFill } from "react-icons/bs";

const withLocation = (Component) => (props) => {
  const location = useLocation();

  return <Component {...props} location={location} />;
};
const Page = ({ children, history }) => {
  const header = React.useRef();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [showWarningBanner, setShowWarningBanner] = useState(false);
  const { account, active, activate, error, deactivate } = useWeb3React();
  const [showButton, setShowButton] = useState(false);
  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager);
  // useInactiveListener(false);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    }
  }, [error]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // clearAllBodyScrollLocks();
  }, [pathname]);
  useEffect(() => {
    if (error) {
      if (error instanceof UnsupportedChainIdError) {
        setShowWarningBanner(true);
      }
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    }
  }, [error]);
  useEffect(() => {
    if (active) {
      setShowWarningBanner(false);
    }
  }, [active]);

  const onCloseNotification = () => setShowWarningBanner(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 280) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  function smoothScroll(target) {
    const defaultValue = { width: 0 };
    const { top } = target.getBoundingClientRect();

    window.scrollTo({
      top: top + window.pageYOffset,
      behavior: "smooth",
    });
  }
  return (
    <div className={styles.page} ref={header}>
      <Header
        active={active}
        account={account}
        error={error}
        connect={() => activate(injected)}
        logout={() => deactivate()}
        // ref={header}
      />
      {showWarningBanner && (
        <Alert
          message="Your wallet is not connected to Mainnet. Please change your network."
          type="warning"
          closable
          onClose={onCloseNotification}
          style={{
            top: "73px",
            backgroundColor: "#ee9200",
            border: "none",
            fontWeight: 700,
            textAlign: "center",
            zIndex: 1,
          }}
        />
      )}
      <div className={styles.inner}>{children}</div>
      {/* <a href="https://discord.gg/CYBERDOGZ" target="_blank">
        <img src={DiscordImg} className={styles.discord} />
      </a> */}
      {showButton && (
        <div
          className={styles.BackToTop}
          onClick={() => smoothScroll(header.current)}
        >
          <BsArrowUpCircleFill />
        </div>
      )}
      <Footer />
    </div>
  );
};

// export default withRouter(Page);
export default withLocation(Page);
