import React, { useEffect, useState } from "react";
import Styles from "./RentModal.module.sass";
import { Row, Col, Slider, Checkbox, Steps } from "antd";
import TextInput from "../../../components/TransparentTextInput";
import Shade from "../../../images/stakeshade.png";
import { GetUserAssets } from "../../../services/ApiServices";
import Leftborder from "../../../images/leftborder.png";
import Loader from "../../../components/Loader";
import Bonze from "../../../images/bonez.png";
import Kibblez from "../../../images/kibblez.png";
import Button from "../../../components/Button";
import SecondaryButton from "../../../components/SecondaryButton";
import Form from "react-bootstrap/Form";
import "../index.css";
import Web3 from "web3";
import RentManager from "../../../abi/RentManager.json";
import { ethers, utils, providers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import CyberdogzNFT from "../../../abi/CyberdogzNFT.json";
import { useSnackbar } from "notistack";
import PrimaryButton from "../../../components/PrimaryButton";
import TransparentButton from "../../../components/TransparentButton";

const RentModal = ({ asset, tokenId, rentClose, getBalanace ,chainId}) => {
  const { Step } = Steps;
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [percentage, setPercentage] = useState(30);
  const [address, setAddress] = useState("");
  const [error, setError] = useState("");
  const [check, setCheck] = useState(false);
  const { account, active, activate, deactivate } = useWeb3React();
  const [txHash, setTxHash] = useState("");
  const [isApproving, setIsApproving] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  // const CyberdogzNFTAddress = "0x459124c1320BB66a2D2e58E04E7D28C344631Ded";//ethereum mainnet
  const CyberdogzNFTAddress = "0x6c9cbd0be01768f11865dafaf41a10ce40fbb7e3"; //polygon mainnet
  // const CyberdogzNFTAddress = "0xbD14f20eE32553e28F75488868A39Dce006fbC6f"; //Rinkeby
  // const RentManagerAddress = "0xaB1273EC34a156A985dEfAD873607B8b646093Ed"; //Rinkeby
  const RentManagerAddress = "0x9Fa958F64b7cC0F9C305B33620b7f66Ca86d6c2A";

  const handelPercentage = (e) => {
    setPercentage(e.target.value);
  };
  const addressHandler = (e) => {
    let result = Web3.utils.isAddress(e.target.value);
    if (result) {
      setError("");
      setAddress(e.target.value);
    } else {
      setAddress("");
      setError("Enter valid Ethereum Address");
    }
  };
  const approve = () => {
    if (!address) setError("Scholar Address is required!");
    else if (!check) setError("Agree the Terms of Service!");
    else if (address===account) setError("Cannot give rent to owner address!");
    else {
      ApproveFunction();
    }
  };
  const rent = () => {
    if (!address) setError("Scholar Address is required!");
    else if (!check) setError("Agree the Terms of Service!");
    else if (address===account) setError("Cannot give rent to owner address!");
    else {
      rentAsset();
    }
  };
  const rentAsset = async () => {
    setIsLoading(true);
    let provider = new ethers.providers.Web3Provider(window.ethereum);
    let signer = provider.getSigner();
    let network = await provider.getNetwork();
   if (chainId == 137) {
      if (network.chainId !== 137) {
        setError("Please connect to Polygon Mainnet");
        return setIsLoading(false);
      }
    }
    try {
      if (!window.ethereum)
        throw new Error("No crypto wallet found. Please install it.");
      try {
        let rentManager = new ethers.Contract(
          RentManagerAddress,
          RentManager.abi,
          signer
        );
        try {
          let checkRent = await rentManager.depositForRental(
            address,
            tokenId,
            1,
            100 - percentage
          );
          setTxHash(checkRent.hash);
          console.log("checkRent", checkRent);
          let buyReceipt;
          try {
            buyReceipt = await checkRent.wait();
            console.log("checkRent", buyReceipt);
            if (buyReceipt.status == 1) {
              enqueueSnackbar("Rent submitted succesfully!", {
                variant: "info",
              });

              rentClose();
              getBalanace();
            }
          } catch (error) {
            setIsLoading(false);
            if (error.message) {
              let message = error.message.split(":");
              return setError(
                "Unknown Error occured please contact support!"
              );
            }
          }
        } catch (error) {
          let message = error.message.split(":");
          setIsLoading(false);
          console.log("Rent deposite exception", error);
          return setError(
            "Unknown Error occured please contact support!"
          );
        }
      } catch (error) {
        let message = error.message.split(":");
        setIsLoading(false);
        console.log("Rent  exception", error);
        return setError(
          "Unknown Error occured please contact support!"
        );
      }
    } catch (err) {
      let message = err.message.split(":");
      setIsLoading(false);
      console.log("Rent approve exception", err);
      return setError(
        "Unknown Error occured please contact support!"
      );
    }
  };
  const ApproveFunction = async () => {
    setApproveLoading(true);
    let provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    try {
      let network = await provider.getNetwork();
      if (chainId == 137) {
        if (network.chainId !== 137) {
          setError("Please connect to Polygon Mainnet");
          return setApproveLoading(false);
        }
      }
      try {
        setApproveLoading(true);
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        let Token = new ethers.Contract(
          CyberdogzNFTAddress,
          CyberdogzNFT.abi,
          signer
        );
        let isApproval;
        let createApproval;
        try {
          isApproval = await Token.isApprovedForAll(
            account,
            RentManagerAddress
          );
          if (isApproval) {
            setIsApproving(isApproval);
            return setApproveLoading(false);
          } else {
            try {
              createApproval = await Token.setApprovalForAll(
                RentManagerAddress,
                true
              );

              let buyReceipt;
              try {
                buyReceipt = await createApproval.wait();
                console.log("checkRent", buyReceipt);
                if (buyReceipt.status == 1) {
                  setIsApproving(true);
                  setApproveLoading(false);
                }
              } catch (error) {
                setIsLoading(false);
                if (error.message) {
                  let message = error.message.split(":");
                  console.log("Rent approve exception", message);
                  return setError(
                    "Unknown Error occured please contact support!"
                  );
                }
              }
            } catch (e) {
              let message = e.message.split(":");
              setApproveLoading(false);
              console.log("Rent approve exception", message);
              return setError("Unknown Error occured please contact support!");
            }
          }
        } catch (e) {
          let message = e.message.split(":");
          setApproveLoading(false);
          console.log("Rent approve exception", message);
          return setError("Unknown Error occured please contact support!");
        }
      } catch (error) {
        let message = error.message.split(":");
        setApproveLoading(false);
        console.log("Checking error from Approve...", message);
        return setError("Unknown Error occured please contact support!");
      }
    } catch (error) {
      setApproveLoading(false);
      let message = error.message.split(":");
      console.log("Checking error from Approve...", message);
      return setError("Unknown Error occured please contact support!");
    }
  };
  return (
    <div className={Styles.stake}>
      <div className={Styles.head}>
        <span className={Styles.yield}>RENT</span> SERVICE
      </div>

      <div>
        <TextInput label="Address" onChange={addressHandler} />
        <br />
        <div className={Styles.values}>
          <p className={Styles.renter}>Scholar {percentage}%</p>
          <p className={Styles.owner}>Owner {100 - percentage}%</p>
        </div>
        {/* <Slider defaultValue={30} tooltipVisible={false} onChange={handelPercentage} value={percentage} /> */}
        <Form.Range onChange={handelPercentage} value={percentage} />
        <br />
        <br />
        <Checkbox
          onChange={(e) => {
            setCheck(e.target.checked);
            setError("");
          }}
        >
          <span className={Styles.checking}>
            By Clicking this box, I agree to CYBERDOGZ Terms of Service
          </span>
        </Checkbox>
      </div>
      {error && <div className={Styles.error}>{error}</div>}
      {/* <img src={Shade} className={Styles.shade} /> */}
      <div className={Styles.button}>
        <div className={Styles.approve}>
          {" "}
          <PrimaryButton
            onClick={approve}
            label="Approve"
            disabled={isApproving}
            loading={approveLoading}
            loadingtext="Approving"
          />
        </div>
        <div className={Styles.rent}>
          <TransparentButton
            disabled={!isApproving}
            onClick={rent}
            loading={isLoading}
            loadingtext="Processing"
            label="Rent"
          />
        </div>
      </div>
      {txHash && (
        <div className={Styles.success}>
          Transaction submitted.
          <a
            href={
              chainId == 1
                ? "https://etherscan.io/tx/" + txHash
                : "https://polygonscan.com/tx/" + txHash
            }
            target="_blank"
          >
            View in explorer
          </a>
        </div>
      )}
      <div className={Styles.steps}>
        <div className={Styles.firstStep}>1</div>
        <div className={isApproving ? Styles.fullBar : Styles.bar}></div>
        <div className={isApproving ? Styles.firstStep : Styles.secondStep}>
          2
        </div>
      </div>
    </div>
  );
};
export default RentModal;
