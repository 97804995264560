import React from "react";
import cn from "classnames";
import styles from "./TextInput.module.sass";
import BlueLine from '../../images/activetextlines.png'
import WhiteLine from '../../images/textlines.png'

const TextInput = ({ className, label,description,labelIcon,icon,disabled, ...props }) => {
  const [focused, setFocused] = React.useState(false)
const onFocus = () => setFocused(true)
const onBlur = () => setFocused(false)
  return (
    <div className={cn(styles.field, className,disabled && styles.disabled)}>
      {label && <div id="label" className={focused?styles.activelabel:styles.label}>
      {labelIcon &&(<img src={labelIcon} />)}
      {label}</div>}
      <img src={focused?BlueLine:WhiteLine} className={styles.line} />
      <div className={styles.wrap}>
       {icon && <img src={icon} className={focused?styles.kibblezFocus:styles.kibblez} />}
        <input id="input" className={cn(icon?styles.iconIput:styles.input,disabled && styles.disabled)} {...props} onFocus={onFocus} onBlur={onBlur} disabled={disabled ? true : false} />
      </div>
      {description && <div id="label" className={styles.description}>{description}</div>}
    </div>
  );
};

export default TextInput;
