import React,{useState,useEffect} from "react"
import styles from "../../screens/Item/Item.module.sass"
import { Row, Col } from "antd";
import moment from "moment";

function BidsHistory (props){

    const [offerAccordian,setOfferAccordian] = useState(true)
    const [bids,setBids] = useState()

    useEffect(()=>{
      setBids(props.bids)
    },[props.bids])

  return ( 
    <>
    <div className={styles.accordianHead}>Bids History</div>
  <div className={styles.historyAccordian}>
  {/* <div className={styles.accordian}>
    <div className={styles.accordianHead}>Bids History</div>
    {offerAccordian ? (
      <button
        className={styles.accordianImgMinus}
        onClick={() => setOfferAccordian(false)}
      ></button>
    ) : (
      <button
        className={styles.accordianImgPlus}
        onClick={() => setOfferAccordian(true)}
      ></button>
    )}
  </div> */}
  {offerAccordian ? (
    <div className={styles.history}>
      {bids?.length ? (
        <>
          <Row>
            <Col span={10}>
              <div className={styles.buyer}>Requested by</div>
            </Col>
            <Col span={8}>
              <div className={styles.seller}>Created at</div>
            </Col>
            <Col span={6}>
              <div className={styles.seller}>Price</div>
            </Col>
          </Row>
          {bids.map((data) => {
            return (
              <div className={styles.rowdata} key={data.userId} >
                <Row>
                  <Col span={10}>
                    <div className={styles.buyerid}>
                      <a
                        href={
                          "https://etherscan.io/address/" +
                          data.userId
                        }
                        target="_blank"
                      >
                        {data.userId}
                      </a>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className={styles.sellerid}>
                      {moment(
                        data.timestamp * 1000
                      ).fromNow()}
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className={styles.offerPrice}>
                      {data.tokenPrice} ETH
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}
        </>
      ) : (
        <div className={styles.listingText}>
          No Bids Placed
        </div>
      )}
    </div>
  ) : null}
</div>
</>)
 
}

export default BidsHistory