
import {
    GETPOSTS,
    GETUSERPOSTS,
    GETFILTERPOSTS,
    GETASSETTYPE,
    GETSALESTYPE,
    GETLODEMOREPOSTS,
    RESETFILTER,
    GETBREEDS,
    GETFACTIONS,
    EDIT_FILTER,
    CLEARGETPOSTS,
    FILTER,
    GETSEARCHPOSTS,
    GETLASTKEY,
    GETPOSTSLOADNIG,
    CLEARGETPOSTSLOADNIG,
    POST_CANCELLED,
    SETFIRSTLOADED,
    POST_ADDED,
    CREATE_POST,
    CREATE_POST_RESPONSE,
    CREATE_POST_RESPONSE_CLEAR,
    CREATE_POST_ERROR,
    CREATE_POST_ERROR_CLEAR,
    EDIT_POST,
    EDIT_POST_ERROR,
    EDIT_POST_ERROR_CLEAR,
    EDIT_POST_RESPONSE,
    EDIT_POST_RESPONSE_CLEAR,
    POST_CANCELLED_CLEAR,
    CLEARPOSTS
} from './PostsTypes'

const initialState = {
    posts: [],
    searchposts: [],
    userPost:[],
    lastKey:{},
    breeds:[],
    factions:[],
    assetType:[],
    salesType:[],
    postLoading: true,
    error: '',
    filter:[{page:""}, {time:""},{breed:""},{faction:""},{assetType:""},{saleType:""},{gender:""},{status:""}],
    isFirstLoaded: false,
    isSearchLoaded: false,
    totalDocs: 0,
    totalPages: 0,
    page: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: true,
    prevPage: 0,
    nextPage: 0,
    isFilterLoaded:false
}


const PostsReducer = (state = initialState, action) => {

    switch (action.type) {

        case CREATE_POST:
            return { ...state, isAdding: true }
            
        case EDIT_POST:
            return { ...state, isUpdating: true }

        case EDIT_POST_RESPONSE:
            let postsList = state.posts.map((data, index) => {
                if (data._id === action.payload._id) {
                    return action.payload
                }
                else {
                    return data
                }
            })
            return { ...state, posts: postsList, isUpdated: true, isUpdating: false }

        case EDIT_POST_RESPONSE_CLEAR:
            return { ...state, isUpdated: false }
        case EDIT_POST_ERROR:
            return { ...state,updateError:action.payload, isUpdating: false }
        case EDIT_POST_ERROR_CLEAR:
                 return { ...state, updateError: '' }

        case CREATE_POST_RESPONSE:
            let listPost = state.posts
            return { ...state, isAdding: false, updateFilterPost: true, isAdded: true, posts: [action.payload, ...listPost] }
        case CREATE_POST_RESPONSE_CLEAR:
            return { ...state, isAdded: false }
        case CREATE_POST_ERROR:
            return { ...state, addingError: action.payload, isAdding: false }
        case CREATE_POST_ERROR_CLEAR:
            return { ...state, addingError: '' }
        case SETFIRSTLOADED:
            return { ...state, isFirstLoaded: true,isFilterLoaded: true }
        case EDIT_FILTER:
            return {...state,filter:action.payload}
        case RESETFILTER:
            return {...state,filter:[{page:""}, {time:""},{breed:""},{faction:""},{assetType:""},{saleType:""},{gender:""},{status:""}]}
        case GETPOSTS:
            return { ...state, posts: action.payload.docs, postLoading: false, isFirstLoaded: true,isFilterLoaded:false,
                // return { ...state, posts: state.posts.concat(action.payload.docs), postLoading: false, isFirstLoaded: true,
                totalDocs: action.payload.totalDocs,
                totalPages: action.payload.totalPages,
                page: action.payload.page,
                pagingCounter: action.payload.pagingCounter,
                hasPrevPage: action.payload.hasPrevPage,
                hasNextPage: action.payload.hasNextPage,
                prevPage: action.payload.prevPage,
                nextPage: action.payload.nextPage,
                // filter:action.params
             }
        case GETLODEMOREPOSTS:
            // return { ...state, posts: action.payload.docs, postLoading: false, isFirstLoaded: true,
                    return { ...state, posts: state.posts.concat(action.payload.docs), postLoading: false, isFirstLoaded: true,isFilterLoaded:false,
                    totalDocs: action.payload.totalDocs,
                    totalPages: action.payload.totalPages,
                    page: action.payload.page,
                    pagingCounter: action.payload.pagingCounter,
                    hasPrevPage: action.payload.hasPrevPage,
                    hasNextPage: action.payload.hasNextPage,
                    prevPage: action.payload.prevPage,
                    nextPage: action.payload.nextPage,
                    // filter:action.params
            }
        case GETUSERPOSTS:
            return { ...state, userPost: action.payload }
        case GETBREEDS:
            return { ...state, breeds: action.payload }
        case GETASSETTYPE:
            return { ...state, assetType: action.payload }
        case GETSALESTYPE:
            return { ...state, salesType: action.payload }
        case GETFACTIONS:
                return { ...state, factions: action.payload }
        case GETLASTKEY:
            return { ...state, lastKey: action.payload}
        case GETSEARCHPOSTS:
            return { ...state, searchposts: action.payload, isSearchLoaded: true }
        case POST_CANCELLED:
            let post_list = state.posts.filter(data => { return data._id != action.payload })
            return { ...state, posts: post_list ,isPostCancelled:true }

            case POST_CANCELLED_CLEAR:
                return { ...state, isPostCancelled:false,isUpdating:false}

            case CLEARPOSTS:
                return {
                    posts: [],
                    postLoading: true,
                    error: '',
                    isFirstLoaded: false,
                    isAdding: false,
                    isAdded: false,
                    addingError: '',
                    isUpdated: false,
                    isUpdating: false,
                    updateError:'',
                    isPostCancelled:false
                }

                    

        default: return state;
    }



}

export default PostsReducer