import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./SpaceshipMint.module.sass";
import TextInput from "../TextInput";
import { ethers, providers, utils } from "ethers";
import { Row, Col } from "antd";
import { Spinner } from "react-bootstrap";
import NumberFormat from "react-number-format";
import {
  RINKEBY_SPACESHIPCRATE_MARKET,
  INFURA_PROVIDER_KEY,
  MAINNET_SPACESHIPCRATE_MARKET,
} from "../../Config/config";
import SpaceshipCrate from "../../images/spaceshipCrate.png";
import WhiteLine from "../../images/textlines.png";
import Shade from "../../images/stakeshade.png";
import { TiPlus, TiMinus } from "react-icons/ti";
import Button from "../Button";
import Ethereum from "../../images/Ethereum.png";
import moment from "moment";
import { GetCratezProof, GetCratezRoot } from "../../services/ApiServices";
import { useWeb3React } from "@web3-react/core";
import { useSnackbar } from "notistack";
import SpaceshipCratesMarket from "../../abi/SpaceshipCratesMarket.json";
import PrimaryButton from "../PrimaryButton";
const SpaceshipMint = (props, { className }) => {
  const [quantity, setQuantity] = useState(1);
  const [limit, setLimit] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [balanceLoading, setBalanceLoading] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const [txHash, setTxHash] = useState("");
  const [freeMint, setFreeMint] = useState(0);
  const [success, setIsSuccess] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [goldTiming, setGoldTiming] = useState(0);
  const [silverTiming, setSilverTiming] = useState(0);
  const [bronzeTiming, setBronzeTiming] = useState(0);
  const { account, active, activate, error, deactivate } = useWeb3React();
  const [proof, setProof] = useState([]);
  const [hash, setHash] = useState("");
  const [amount, setAmount] = useState(0);
  const [amountLeft, setAmountLeft] = useState(0);
  const [userAmountLeft, setUserAmountLeft] = useState(0);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [category, setCategory] = useState("");
  const networkName = "rinkeby";
  const chainId = 1;
  const increment = () => {
    // if (quantity != limit) {
    setQuantity(quantity + 1);
    // }
  };
  const decrement = () => {
    if (quantity != 1) {
      setQuantity(quantity - 1);
    }
  };
  useEffect(() => {
    getBalance();
  }, [account]);

  const getBalance = async () => {
    setAmount(0);
    setAmountLeft(0);
    setFreeMint(0);
    setCurrentPrice(0);
    setProof([]);
    setHash("");
    setUserAmountLeft(0);
    setErrormsg("");
    setBalanceLoading(false);
    setCategory("")
    let token_Price,
      currentPrice,
      eligibility,
      isEligible,
      available,
      freeAvailable,
      category = "",
      isGold,
      isSilver,
      proof,
      userAmount;

    let provider = new ethers.providers.Web3Provider(window.ethereum);
    let signer = provider.getSigner();
    let network = await provider.getNetwork();
    if (network.chainId !== chainId) {
      setBalanceLoading(false);
      return setErrormsg("Please connect to Ethereum Mainnet Network.");
    }
    let spaceshipMarket = new ethers.Contract(
      MAINNET_SPACESHIPCRATE_MARKET,
      SpaceshipCratesMarket.abi,
      signer
    );

    try {
      token_Price = await spaceshipMarket.getCurrentPrice();
      userAmount = token_Price.toString().split(",")[2];
      currentPrice = token_Price.toString().split(",")[0];
      setUserAmountLeft(parseFloat(userAmount));
      // console.log(token_Price.toString());
      currentPrice = utils.formatUnits(
        utils.parseUnits(currentPrice.toString(), "wei"),
        "ether"
      );
      setCurrentPrice(parseFloat(currentPrice));

      isGold = await spaceshipMarket.goldWhitelistEnd();
      setGoldTiming(parseInt(isGold));
      isSilver = await spaceshipMarket.silverWhitelistEnd();
      setSilverTiming(parseInt(isSilver));
    } catch (error) {
      setBalanceLoading(false);
      console.log("Checking error from token price...", error);
      return;
    }

    if (moment().isBefore(parseInt(isGold) * 1000)) {
      category = "gold";
      setCategory("gold")
    } else if (moment().isBefore(parseInt(isSilver) * 1000)) {
      category = "silver";
      setCategory("silver");
    } else {
      category = "public";
      setCategory("public");
    }
    // console.log(category);
      try {
        proof = await GetCratezProof(account, category);
      } catch (e) {
        setBalanceLoading(false);
        return setErrormsg("Your address is not whitelisted");
      }
      if (proof.success) {
        setProof(proof.proof);
        setHash(proof.hash);
        setAmount(proof.amount);
      }
    try {
      eligibility = await spaceshipMarket.getEligibility(
        proof.hash,
        proof.proof,
        proof.amount,
        account
      );
      // console.log(eligibility.toString());
      isEligible = eligibility.toString().split(",")[0];
      available = eligibility.toString().split(",")[1];
      setAmountLeft(parseInt(available));
      freeAvailable = eligibility.toString().split(",")[2];
      setFreeMint(parseInt(freeAvailable));
      if ((isEligible === "false" || isEligible === false)) {
        setBalanceLoading(false);
        return setErrormsg("Your address is not found in Whitelist.");
      }
      else{
      setBalanceLoading(true);
      }
    } catch (error) {
      setBalanceLoading(false);
      return console.log("Checking error from Eligibility price...", error);
    }
  };

  const purchase = async () => {
    setTxHash("");
    setIsSuccess(false);
    setIsLoading(true);
    setErrormsg("");
    try {
      if (!window.ethereum) {
        setErrormsg("Metamask is not found. Please install it.");
        throw new Error("Metamask is not found.");
      }
      try {
        let provider = new ethers.providers.Web3Provider(window.ethereum);
        let value = 0;
        let network = await provider.getNetwork();
        if (network.chainId !== chainId) {
          setIsLoading(false);
          return setErrormsg("Please connect to Ethereum Mainnet Network.");
        }
        // }
        if (quantity < 1) {
          setErrormsg("Please enter minimum quantity ( Qty 1 )");
          setIsLoading(false);
          return;
        }
        let signer = provider.getSigner();

        ethers.utils.getAddress(account);
        value = await provider.getBalance(account, "latest");

        let displayBalance = utils.formatUnits(
          utils.parseUnits(value.toString(), "wei"),
          "ether"
        );
        let token_Price, currentPrice;
        console.log("Inside spaceship crate market...");
        let spaceshipMarket = new ethers.Contract(
          MAINNET_SPACESHIPCRATE_MARKET,
          SpaceshipCratesMarket.abi,
          signer
        );

        try {
          token_Price = await spaceshipMarket.getCurrentPrice();

          currentPrice = token_Price.toString().split(",")[0];

          currentPrice = utils.formatUnits(
            utils.parseUnits(currentPrice.toString(), "wei"),
            "ether"
          );

          // console.log("Balance:", displayBalance, "expected:", parseFloat(currentPrice) * (quantity - freeMint));
          if (
            quantity > freeMint &&
            parseFloat(displayBalance) < parseFloat(currentPrice) * (quantity - freeMint)
          ) {
            setIsLoading(false);
            return setErrormsg("Insufficient Balance to complete minting");
          } else {
            let estimatedGasLimit;
            let estimatedGasPrice;
            let tokenId = "2";
            let amountToBuy = quantity;
            let totalGwei = 0;
            if (quantity >= freeMint) {
              totalGwei = token_Price[0].mul(amountToBuy - freeMint);
            }
            console.log("Value sent", totalGwei.toString());
            try {
              estimatedGasLimit = await spaceshipMarket.estimateGas.buyToken(
                amountToBuy,
                hash,
                proof,
                amount,
                { value: totalGwei }
              );
            } catch (e) {
              console.log("Error estimatedGasLimit: ", e);
              setIsLoading(false);
              let message = e.message.split(":");
              return setErrormsg("Error in Estimated Gas Limit");
            }
            try {
              estimatedGasPrice = await provider.getGasPrice();
            } catch (error) {
              let message = error.message.split(":");
              setIsLoading(false);
              return setErrormsg(
                message[0] === "MetaMask Tx Signature" ? message[1] : message
              );
            }
            let overrides = {
              gasLimit: estimatedGasLimit,
              gasPrice: estimatedGasPrice,
              value: totalGwei,
            };

            let buyTx;
            try {
              buyTx = await spaceshipMarket.buyToken(
                amountToBuy,
                hash,
                proof,
                amount,
                overrides
              );
              setIsLoading(true);
              setTxHash(buyTx.hash);
            } catch (error) {
              if (error.message) {
                let message = error.message.split(":");
                setIsLoading(false);
                return setErrormsg(
                  message[0] === "MetaMask Tx Signature" ? message[1] : message
                );
              }
              console.log("Erron on buy token...", error);
              setIsLoading(false);
              setErrormsg("Transaction failed");
            }
            let buyReceipt;

            try {
              buyReceipt = await buyTx.wait();
            } catch (error) {
              // setIsLoading(false)
              if (error.message) {
                let message = error.message.split(":");
                setIsLoading(false);
                return setErrormsg(
                  message[0] === "MetaMask Tx Signature" ? message[1] : message
                );
              }
              console.log("Erron on wait ...", error);
              setIsLoading(false);
              setIsSuccess(false);
              return enqueueSnackbar("Transaction failed");
            }
            // console.log("buyReceipt:", buyReceipt.transactionHash);
            let ethUsed = utils.formatUnits(
              utils.parseUnits(buyReceipt.gasUsed.toString(), "gwei"),
              "ether"
            );
            if (buyReceipt.status) {
              setIsLoading(false);
              setIsSuccess(true);
              // setSuccesssMsg(`https://etherscan.io/tx/${buyReceipt.transactionHash}`)
              console.log(
                `Buy Transaction receipt : https://etherscan.io/tx/${buyReceipt.transactionHash}`
              );
            }

            console.log("tx", buyReceipt.transactionHash);
            return;
            // props.onClose();
          }
        } catch (error) {
          setIsLoading(false);
          console.log("Checking error from token price...", error);
          let message = error.message.split(":");
          return setErrormsg(
            message[0] === "MetaMask Tx Signature" ? message[1] : message
          );
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setErrormsg(error.message);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      return setErrormsg(err.message);
    }
  };
  return (
    <div className={styles.spaceshipMint}>
      <div className={styles.head}>
        Mint Your Spaceship Cratez
      </div>
      <div>
        <Row>
          <Col md={8} xs={24} sm={24}>
            <img src={SpaceshipCrate} className={styles.img} />
          </Col>
          <Col md={16} xs={24} sm={24}>
            <div className={styles.body}>
              <div className={styles.available}>
                <div id="label" className={styles.label}>
                  Available to mint
                </div>
                {/* <img src={WhiteLine} className={styles.line} /> */}
                <div className={styles.wrap}>
                  <div className={styles.value}>
                    {category!="public" ? <span className={styles.orange}>
                      {amountLeft <= 0
                        ? "-"
                        : amountLeft}{" "}
                      /{" "}
                    </span>:
                    <span className={styles.orange}>
                      {amountLeft?amountLeft:"-"}{" "}
                      /{" "}
                    </span>
                    }
                    
                    {" "}
                    &nbsp; &nbsp;
                    {category!="public"?<span className={styles.white}>
                      {" "}
                       10{" "}
                    </span>:
                    <span className={styles.white}>
                      {" "}
                      12084{" "}
                    </span>}
                    {" "}
                    &nbsp; &nbsp;at {currentPrice.toFixed(2)} ETH each
                  </div>
                </div>
               {category=="gold" && <div id="label" className={styles.description}>
                  *Based on the number of CYBERDOGZ you own
                </div>}
              </div>
            {/* {category!="public" && <img src={Shade} className={styles.shade} />} */}
              <div className={styles.text}>
                {category == "gold" ? (
                  <div>
                    Offer for confirmed Owners
                    <br />
                    {!balanceLoading ? "-" : freeMint} Free Mint +{" "}
                    {!balanceLoading ? "-" : amountLeft - freeMint} Available at
                    0.01 ETH
                  </div>
                ) : category == "silver" ? (
                  <div>
                    {" "}
                    Offer for Whitelist Users
                    <br />
                    {amountLeft <= 0
                      ? "-"
                      : amountLeft}{" "}
                    / 10{" "}
                    Available at {currentPrice.toFixed(2)} ETH
                  </div>
                ) : null}{" "}
              </div>
              <div className={styles.lineBorder} />
              {/* <img src={Shade} className={styles.shade} /> */}
              {errormsg && <div className={styles.error}>{errormsg}</div>}
              <Row>
                <Col md={8} sm={24} xs={24}>
                  <div className={styles.quantity}>
                    <div id="label" className={styles.label}>
                      Enter quantity
                    </div>
                    {/* <img src={WhiteLine} className={styles.line} /> */}

                    <div className={styles.wrap}>
                      <div className={styles.input}>
                        <TiMinus
                          className={
                            quantity == 1 ? styles.blockIcon : styles.minusIcon
                          }
                          onClick={decrement}
                        />
                        <span className={styles.quantityValue}>{quantity}</span>
                        <TiPlus
                          className={styles.plusIcon}
                          onClick={increment}
                        />
                      </div>
                    </div>
                    <div id="label" className={styles.description}>
                      {freeMint >= quantity ? (
                        <span className={styles.orange}>FREE MINT</span>
                      ) : (
                        <>
                          <img src={Ethereum} className={styles.ethimg} />
                          <span className={styles.orange}>
                            {(0.05 * (quantity - freeMint)).toFixed(2)}{" "}
                            ETH&nbsp;&nbsp;&nbsp;
                          </span>
                          <span className={styles.white}>
                            {(
                              props.value *
                              0.05 *
                              (quantity - freeMint)
                            ).toFixed(2)}{" "}
                            USD
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md={16} sm={24} xs={24}>
                  <div className={styles.button}>
                    <PrimaryButton
                      label="MINT"
                      loadingtext="MINTING"
                      loading={isLoading}
                      disabled={!balanceLoading}
                      onClick={() => {
                        purchase();
                      }}
                    />
                  </div>
                </Col>
              </Row>
              {txHash && (
                <div className={styles.success}>
                  Transaction submitted.{" "}
                  <a href={"https://etherscan.io/tx/" + txHash} target="_blank">
                    View in explorer
                  </a>
                </div>
              )}{" "}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SpaceshipMint;
