import React, { useState, useEffect } from "react";
import moment from "moment";

function CounDown(props) {
  const [date, setDate] = useState();
  const [days, setDays] = useState();
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();
  const [auctionValue, setAuctionValue] = useState({});

  const getTimeDifference = (date) => {
    const dateNow = moment.utc();
    const expiryDate = moment.utc(date);
    var duration = moment.duration(expiryDate.diff(dateNow));
    setDays(duration.days());
    setHours(duration.hours());
    setMinutes(duration.minutes());
    setSeconds(duration.seconds());
  };

  useEffect(() => {
    getTimeDifference(date);
    setInterval(() => getTimeDifference(date), 1000);
  });

  useEffect(() => {
    const interval = setInterval(() => {
      getTimeDifference(date);
    }, 1000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  useEffect(() => {
    setDate(props.asset);
  }, [props.asset]);

  return (
    <>
      {props.fromHour ? (
        <>
          {hours} Hrs&nbsp;&nbsp;{minutes} Mins&nbsp;&nbsp;{seconds} Secs
        </>
      ) : (
        <> 
        {/* {days} Days&nbsp;&nbsp; */}
         {hours} Hrs&nbsp;&nbsp;{minutes}{" "}
          Mins&nbsp;&nbsp;{seconds} Secs
        </>
      )}
    </>
  );
}

export default CounDown;
