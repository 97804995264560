import React from "react";
import catAnimationData from '../../json/404.json';
import LottieLoader from 'react-lottie-loader';

function Screen404() {
  return (
    <div style={{width:"50%",margin:"100px auto"}}>
      <LottieLoader animationData={catAnimationData} />
    </div>
  );
}

export default Screen404;