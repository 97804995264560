import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Bids.module.sass";
import Loader from "../../../components/Loader";
import { Row, Col } from "antd";

import moment from "moment";
import PlaceBid from "../../../components/PlaceBid";
import Modal from "../../../components/Modal";
import { Link, useParams } from "react-router-dom";
import Auction from "../../../abi/Auction.json";
import { ethers, utils, providers } from "ethers";
import Moment from "moment";
import {
  AddCommnets,
  GetAssetById,
  GetUSD,
  GetBids,
} from "../../../services/ApiServices";
import { ControlOutlined } from "@ant-design/icons";
import { INFURA_PROVIDER_KEY } from "../../../Config/config";
const Bids = ({ className, items }) => {
  const id = "e56c380d-d9bf-4f12-b582-771712d6f6ea";
  const [isLoading, setIsLoading] = useState(false);

  const [asset, setAsset] = useState({});

  const [bidModal, setBidModal] = useState(false);

  const { uid } = useParams();
  const [auctionValue, setAuctionValue] = useState({});
  const [legendary, setLegendary] = useState({});
  const [bids, setBids] = useState([]);
  const [tokenmybid, setTokenmybid] = useState([]);
  const [currentTier, setCurrentTier] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [currentBalance, setCurrentBalance] = useState("");
  const [selectedItem, setSelectedItem] = useState(0);

  const [currentPrice, setCurrentPrice] = useState("");
  useEffect(() => {
    setIsLoading(true);
    getAsset();
    getBids();
  }, [id]);

  const getAsset = async () => {
    setCurrentBalance("");
    setCurrentPrice("");
    setTotalSupply("");
    setCurrentTier("");
    setIsLoading(true);
    let result;
    try {
      result = await GetAssetById(id);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }

    try {
      if (result.success) {
        setIsLoading(false);
        setAsset(result.result[0]);

        // console.log(result.posts[0].likes);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const [item, setItem] = useState({});
  const [currentBid, setCurrentBid] = useState("");
  const getBids = async () => {
    let result;
    try {
      result = await GetBids(id);
    } catch (e) {
      console.log(e);
    }
    try {
      if (result.success) {
        setBids(result.result);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // useEffect(() => {
  //   getTimeDifference();
  //   setInterval(() => getTimeDifference(), 1000);
  // }, [items.createdAt]);
  const AuctionContractAddress = "0x14D7c2d970cDcD402c86349e3956B55EFa255CA4";
  useEffect(() => {
    AuctionContractContract();
  }, [uid]);

  const AuctionContractContract = async () => {
    try {
      let provider;
      let auction;
      if (typeof window.ethereum == "undefined") {
        provider = new providers.InfuraProvider("homestead", {
          projectId: INFURA_PROVIDER_KEY,
        });

        auction = new ethers.Contract(
          AuctionContractAddress,
          Auction.abi,
          provider
        );
      } else {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        provider = new providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        auction = new ethers.Contract(
          AuctionContractAddress,
          Auction.abi,
          signer
        );
      }
      let data = await auction.getCurrentBidForUser(1, uid);
      let data1 = await auction.fetchAuctionItem(
        "0x459124c1320bb66a2d2e58e04e7d28c344631ded",
        10001,
        1
      );

      let tokenAddress = data1.toString().split(",")[0];
      let tokenId = data1.toString().split(",")[1];
      let startPrice = data1.toString().split(",")[2];
      let amount = data1.toString().split(",")[3];
      let tradable = data1.toString().split(",")[4];
      let sold = data1.toString().split(",")[5];
      let duration = data1.toString().split(",")[6];
      let endTime = data1.toString().split(",")[8];
      let price = utils.formatUnits(
        utils.parseUnits(startPrice.toString(), "wei"),
        "ether"
      );

      const dateNow = moment.utc();
      const expiryDate = moment.utc(endTime * 1000);
      let duration9 = moment.duration(expiryDate.diff(dateNow));

      setDays(duration9.minutes());
      // getTimeDifference();
      var value = {
        tokenAddress: tokenAddress,
        tokenId: tokenId,
        startPrice: price,
        amount: amount,
        tradable: tradable,
        sold: sold,
        duration: duration,
        endTime: endTime,
      };
      setAuctionValue(value);
      setTokenmybid(data.toString().split(",")[0]);
      let tokenAddress1 = data.toString().split(",")[0];
      let tokenId1 = data.toString().split(",")[1];
      let startPrice1 = data.toString().split(",")[2];
      let amount1 = data.toString().split(",")[3];
      let tradable1 = data.toString().split(",")[4];
      let sold1 = data.toString().split(",")[5];
      let duration1 = data.toString().split(",")[6];
      let endTime1 = data.toString().split(",")[7];
      let price1 = utils.formatUnits(
        utils.parseUnits(startPrice1.toString(), "wei"),
        "ether"
      );
      let price2 = utils.formatUnits(
        utils.parseUnits(tokenAddress1.toString(), "wei"),
        "ether"
      );

      var value1 = {
        mybid: price2,
        tokenId: tokenId1,
        currentbid: price1,
        amount: amount1,
        tradable: tradable1,
        sold: sold1,
        duration: duration1,
        endTime: endTime1,
      };
      setLegendary(value1);
    } catch (e) {
      console.log(e);
    }
  };
  const closebidModal = () => {
    AuctionContractContract();
    setBidModal(false);
  };
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState("");

  const [days, setDays] = useState();
  // const days =-1;
  return (
    <>
      {isLoading ? (
        <Loader className={styles.loader} />
      ) : (
        <div className={cn(styles.followers, className)}>
          <div className={styles.list}>
            {/* {items.map((x, index) => ( */}
            {/* {tokenmybid == 0 && (
            <div className={styles.noassets}>{"No Bids Found"}</div>
          )} */}
            {/* {tokenmybid==0} <div className={styles.noassets}>{"No Assets Found"}</div> */}
            {tokenmybid > 0 ? (
              <div className={styles.card}>
                <Row>
                  <Col span={4} className={styles.avatar}>
                    <img
                      src="https://cyberdogz.s3.us-west-1.amazonaws.com/nft/pinscher/LEGENDARY-RARE-C/1.png"
                      alt="Card"
                    />
                  </Col>
                  <Col md={8} xs={24}>
                    <div className={styles.title}>{"LEGENDARY RARE"}</div>
                    <br />

                    <Row>
                      <Col md={12} xs={7}>
                        <div className={styles.value}>Auction Status</div>
                      </Col>
                      <Col md={12} xs={7}>
                        <p>
                          {days > 0 ? (
                            <span className={styles.status1}>Active</span>
                          ) : (
                            <span className={styles.status}>Ended</span>
                          )}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} xs={7}>
                        <div className={styles.value}>Current Bid</div>
                      </Col>
                      <Col md={12} xs={7}>
                        <p className={styles.of}>{legendary.currentbid} ETH</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={8} xs={24}>
                    <br />
                    <br />
                    <Row>
                      <Col md={12} xs={7}>
                        <div className={styles.value}>My Bid</div>
                      </Col>
                      <Col md={12} xs={7}>
                        <p className={styles.of}>{legendary.mybid} ETH</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} xs={7}>
                        <div className={styles.value}>My Status</div>
                      </Col>

                      <Col md={12} xs={7}>
                        <p className={styles.of}>
                          {" "}
                          {legendary.mybid >= legendary.currentbid ? (
                            <span className={styles.status1}>Highest Bid</span>
                          ) : (
                            <span className={styles.status}>Outbidden</span>
                          )}
                        </p>
                      </Col>
                    </Row>
                    <Row span={8}>
                      <Col md={8} xs={24}>
                        <Link
                          to={"/detail/e56c380d-d9bf-4f12-b582-771712d6f6ea"}
                        >
                          <button
                            // onClick={() => {
                            //   setItem(asset);
                            //   setBidModal(true);
                            // }}
                            className={styles.game}
                          >
                            Increase Your Bid
                          </button>
                        </Link>
                      </Col>
                    </Row>
                    <br />
                    <br />
                  </Col>
                </Row>
              </div>
            ) : (
              <div className={styles.noassets}>{"No Bids Found"}</div>
            )}
            <Modal
              containerClassName="purchase"
              visible={bidModal}
              onClose={() => closebidModal()}
            >
              <PlaceBid
                item={item}
                className="purchase"
                refresh={getBids}
                getAsset={getAsset}
                currentBid={currentBid}
                auction={auctionValue}
                onClose={() => closebidModal()}
              />
            </Modal>
          </div>
          {/* <Loader className={styles.loader} /> */}
        </div>
      )}
    </>
  );
};

export default Bids;
