import { Col, Row, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import { RiFileCopyLine } from "react-icons/ri";
import { minifyAddress } from "../../utils/helpers";
import styles from "./Referral.module.sass";
import { useLocation, useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { useSelector } from "react-redux";
import {
  GetAllMyReferral,
  GetReferralBonez,
  GetReferralTransactions,
} from "../../services/ApiServices";
import moment from "moment";
import Pagenation from "../../components/Pagenation";
import cn from "classnames";
import ReferralBanner from "../../images/referralBanner.png";
import Refer from "../../images/Connect.png";
import BonezLogo from "../../images/bonezLogo.png";
import Dots from "../../images/raceDots.png";
import Dots1 from "../../images/raceDots1.png";
import Bonez from "../../images/bonez.png";
import TransactionLogo from "../../images/transactions.png";
import TableLoader from "../../components/TableLoader";
import { Bars } from "react-loader-spinner";
const Referral = (props) => {
  const navigate = useNavigate();
  const { account, active, activate, error, deactivate } = useWeb3React();
  const [user, setUser] = useState();
  const state = useSelector((state) => state);
  const [referrals, setReferrals] = useState([{ docs: [] }]);
  const [transactions, setTransactions] = useState([{ docs: [] }]);
  const [bonez, setBonez] = useState(0);
  const [referralLoading, setReferralLoading] = useState(false);
  let location = useLocation();
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [origin, setOrigin] = useState("");
  useEffect(() => {
    if (state.User.user) setUser(state.User.user);
  }, [state.User.user]);
  useEffect(() => {
    if (account) {
      getAllReferral();
      getReferralTransactions();
      getReferralBonez();
    } else navigate("/");
  }, [account]);
  const getAllReferral = async () => {
    setReferralLoading(true);
    let result;
    try {
      result = await GetAllMyReferral(account);
    } catch (e) {
      console.log(e);
      setReferralLoading(false);
    }
    try {
      if (result.success) {
        setReferralLoading(false);
        setReferrals(result.referral);
      }
    } catch (e) {
      setReferralLoading(false);
      console.log(e);
    }
  };
  const getReferralTransactions = async () => {
    setTransactionsLoading(true);
    let result;
    try {
      result = await GetReferralTransactions(account);
    } catch (e) {
      console.log(e);
      setTransactionsLoading(false);
    }
    try {
      if (result.success) {
        setTransactionsLoading(false);
        setTransactions(result.referral);
      }
    } catch (e) {
      setTransactionsLoading(false);
      console.log(e);
    }
  };
  const getReferralBonez = async () => {
    setBonez(0);
    let result;
    try {
      result = await GetReferralBonez(account);
    } catch (e) {
      console.log(e);
    }
    try {
      if (result.success) {
        setBonez(result.bonez[0].total ? result.bonez[0].total : 0);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    setOrigin(window.location.origin);
  }, [window.location.origin]);
  return (
    <div className={cn("container")}>
      <div className={styles.referral}>
        <div className={styles.referralBg}>
          <img src={ReferralBanner} className={styles.referralImage} />
          <div className={styles.mask}>
            <h2>Referral System</h2>
            <p>Refer your friends and earn $BONEZ.</p>
          </div>
        </div>
        <div className={styles.background}>
          <div className={styles.dotContainer}>
            <img src={Dots} className={styles.dot} />
            <div>
              <h3>Refer and earn</h3>
              {user?.uid ? (
                <div className={styles.invite}>
                  <span>My Invite URL:</span>
                  <div className={styles.referralCode}>
                    <div className={styles.link}>
                      {origin}/?referralId={user?.uid}
                    </div>

                    <Tooltip title="Copied!" color="#414141" trigger="click">
                      <RiFileCopyLine
                        className={styles.icon}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            origin + "/?referralId=" + user?.uid
                          );
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              ) : (
                <Bars
                  height="50"
                  width="80"
                  color="#FDC22E"
                  ariaLabel="bars-loading"
                  wrapperStyle={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 60,
                    justifyContent: "center",
                  }}
                  wrapperClass=""
                  visible={true}
                />
              )}
            </div>
            <img src={Dots1} className={styles.dot1} />
          </div>
        </div>

        <Row className={styles.transaction}>
          <Col span={8}>
            <div className={styles.statistics}>
              <img src={Refer} className={styles.logo} />
              <div className={styles.field}>
                <span className={styles.value}>
                  {referrals?.totalDocs ? referrals?.totalDocs : 0}
                  {"  "}
                </span>
                REFERRAL
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.statistics}>
              <img src={BonezLogo} className={styles.logo} />
              <div className={styles.field}>
                <span className={styles.value}>{bonez}</span>
                {"  "}$BONEZ
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.statistics}>
              <img src={TransactionLogo} className={styles.logo} />
              <div className={styles.field}>
                <span className={styles.value}>
                  {transactions?.totalDocs ? transactions?.totalDocs : 0}
                  {"  "}
                </span>
                TRANSACTIONS
              </div>
            </div>
          </Col>
        </Row>
        <div className={styles.column}>
          <Row>
            <Col xs={24} sm={24} md={11} lg={11}>
              <div className={styles.earnings}>
                <h3 className={styles.heading}>My Referrals</h3>
                {referralLoading ? (
                  <TableLoader length={10} />
                ) : !referrals?.docs?.length ? (
                  <div className={styles.nodata}>No data available</div>
                ) : (
                  referrals?.docs?.map((referral, index) => (
                    <Row
                      key={index}
                      className={
                        index % 2 == 0 ? styles.highlightedRow : styles.row
                      }
                    >
                      <Col sm={6} xs={6} xl={8} lg={8} className={styles.date}>
                        {moment(referral.createdAt).format("DD/MM/YYYY")}
                      </Col>
                      <Col
                        sm={8}
                        xs={8}
                        xl={8}
                        lg={8}
                        className={styles.address}
                      >
                        {minifyAddress(referral.address, 6)}
                      </Col>
                      <Col
                        sm={10}
                        xs={10}
                        xl={8}
                        lg={8}
                        className={styles.email}
                      >
                        {referral.userName || "-"}
                      </Col>
                    </Row>
                  ))
                )}
                {/* <Pagenation /> */}
              </div>
            </Col>
            <Col xs={24} sm={24} md={2} lg={2} />
            <Col xs={24} sm={24} md={11} lg={11}>
              <div className={styles.earnings}>
                <h3 className={styles.heading}>My Earnings</h3>
                {transactionsLoading ? (
                  <TableLoader length={10} />
                ) : !transactions?.docs?.length ? (
                  <div className={styles.nodata}>No data available</div>
                ) : (
                  transactions?.docs?.map((transaction, index) => (
                    <Row
                      key={index}
                      className={
                        index % 2 == 0 ? styles.highlightedRow : styles.row
                      }
                    >
                      <Col span={8} className={styles.date}>
                        {moment(transaction.createdAt).format("DD/MM/YYYY")}
                      </Col>
                      <Col
                        span={8}
                        className={styles.address}
                        onClick={() =>
                          navigate("/account/" + transaction.wallet)
                        }
                      >
                        {minifyAddress(transaction.wallet, 6)}
                      </Col>
                      <Col span={8} className={styles.reward}>
                        {transaction.bonez} <span>$BONEZ</span>
                        {"  "}
                        <img src={Bonez} width="16px" />
                      </Col>
                    </Row>
                  ))
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Referral;
