import React, { useEffect, useRef, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import {
  GetBreedingHistory,
} from "../../../services/ApiServices";
import Styles from "./BreedingHistory.module.sass";
import cn from "classnames";
import moment from "moment";
import { Col, Row, Tooltip } from "antd";
import Loader from "../../../components/Loader";
import HistoryShade from "../../../images/historyshade.png";
import { MdOpenInNew } from "react-icons/md";
import { BREEDING_NFT_ADDRESS } from "../../../Config/config";

const BreedingHistory = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const { account, active, activate, error, deactivate } = useWeb3React();
  useEffect(() => {
    if (account) getHistory(account);
  }, [account]);
  const getHistory = async () => {
    setIsLoading(true);
    let result;
    try {
      result = await GetBreedingHistory(account);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
    try {
      if (result.success) {
        setIsLoading(false);
        setHistory(result.result);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const calculatePercentage = (start, end) => {
    let current = parseInt(Date.now() / 1000);
    return ((100 * (current - start)) / (end - start)).toFixed(2) >= 100
      ? ""
      : ((100 * (current - start)) / (end - start)).toFixed(2);
  };
  return (
    <div className={cn("container", Styles.container)}>
      <p className={Styles.title}>
        BREED HISTORY
        {/* <span className={Styles.heading}>HISTORY</span> */}
      </p>
      <Row>
        <Col md={24} xs={24}>
          {/* <p className={Styles.KibblezTitle}>Kibblez</p> */}
          <div className={Styles.kibblezTable}>
            <div className={Styles.history_table}>
              <div className={Styles.rowdata}>
                <Row>
                  <Col span={5}>
                    <div className={Styles.seller}>Date</div>
                  </Col>
                  <Col span={5}>
                    <div className={Styles.seller}>Puppy</div>
                  </Col>
                  <Col span={5}>
                    <div className={Styles.seller}>Father</div>
                  </Col>
                  <Col span={5}>
                    <div className={Styles.seller}>Mother</div>
                  </Col>
                  <Col span={4}>
                    <div className={Styles.seller}>Status</div>
                  </Col>
                </Row>
              </div>
              {/* <img src={HistoryShade} className={Styles.history_shade} /> */}
              {isLoading ? (
                <Loader className={Styles.loader} />
              ) : history.length ? (
                <>
                  {history.map((data, index) => {
                    return (
                      <div
                        className={
                          Styles.rowdata
                        }
                        key={index}
                      >
                        <Row>
                          <Col span={5}>
                            <div className={Styles.pricefield}>
                              {moment(data?.timestamp * 1000)
                                .utc()
                                .format("DD-MM-YYYY hh:mm A")}{" "}
                              UTC
                            </div>
                          </Col>
                          <Col span={5}>
                            
                      <a
                        href={
                          "https://opensea.io/assets/matic/" +
                          BREEDING_NFT_ADDRESS +
                          "/" +
                          data.tokenId
                        }
                        target="_blank"
                        className={Styles.hash}
                      >
                              {data?.token.name}
                      </a>
                           
                           
                          </Col>
                          <Col span={5}>
                            <div className={Styles.pricefield}>
                              {data?.token.attributes[0].value.split(":")[0]}
                            </div>
                          </Col>
                          <Col span={5}>
                            <div className={Styles.pricefield}>
                              {data?.token.attributes[1].value.split(":")[0]}
                            </div>
                          </Col>
                          <Col span={4}>
                            <div className={Styles.status}>
                              {moment().isAfter(
                                data.token.revealTime * 1000
                              ) ? (
                                <span className={Styles.complete}>
                                  COMPLETED
                                </span>
                              ) : (
                                <span className={Styles.progress}>
                                  BREEDING
                                </span>
                              )}&nbsp;
                              {calculatePercentage(
                                data.timestamp,
                                data.token.revealTime
                              ) && <>{calculatePercentage(
                                data.timestamp,
                                data.token.revealTime
                              )}{" "}
                              %</>}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p className={Styles.empty}>No data available</p>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default BreedingHistory;
