import React,{useState,useEffect} from "react"
import { Row, Col } from "antd";
import styles from "../../screens/Item/Item.module.sass"
import Ethereum from "../../images/Ethereum.png";
import Polygon from "../../images/polygon.png";

const price = [
  { quantity: "1-5", price: "0.01" },
  { quantity: "6-10", price: "0.25" },
  { quantity: "11-15", price: "0.50" },
  { quantity: "16-20", price: "0.75" },
  { quantity: "21-25", price: "1.00" },
];
const capsule = [
  { quantity: "1-50", price: "0.1" },
  { quantity: "51-99", price: "0.25" },
  { quantity: "100-199", price: "0.50" },
  { quantity: "200-399", price: "0.75" },
  { quantity: "400-1000", price: "1.00" },
];
const land = [
  { quantity: "1-100,000", price: ".00002" },
  { quantity: "100,001-999,9999", price: ".0001" },
  { quantity: "1,000,000-2,500,000", price: ".0005" },
  { quantity: "2,500,001-5,000,000", price: ".0007" },
  { quantity: "5,000,001-7,500,000", price: ".0008" },
  { quantity: "7,500,001-8,500,000", price: ".001" },
  { quantity: "8,500,001-9,500,000", price: ".002" },
  { quantity: "9,500,001-10,000,000", price: ".003" },
];

function TieredPrice ({asset}){

    const [priceAccordian,setPriceAccordian] = useState(true)
    // const [asset,setAsset] = useState()

    // useEffect(()=>{
    //     setAsset(props.asset)
    // },[props.asset])

    return  (
      <>
    <div className={styles.accordianHead}>Tiered Pricing</div>
    <div className={styles.historyAccordian}>
    {/* <div className={styles.accordian}>
      <div className={styles.accordianHead}>Tiered Pricing</div>
      {priceAccordian ? (
        <button
          className={styles.accordianImgMinus}
          onClick={() => setPriceAccordian(false)}
        ></button>
      ) : (
        <button
          className={styles.accordianImgPlus}
          onClick={() => setPriceAccordian(true)}
        ></button>
      )}
    </div> */}
    {priceAccordian ? (
      <div className={styles.history}>
          <div className={styles.AccordianBody}>
            <Row>
              <Col span={10}>
                <div className={styles.buyer}>Lot</div>
              </Col>
              <Col span={7}>
                <div className={styles.seller}>ETH</div>
              </Col>
              <Col span={7}>
              <div className={styles.seller}>Status</div>
              </Col>
            </Row>
            {(asset.assetType.type == "capsule"
              ? capsule
              : asset.assetType.type == "land"
                ? land
                : price
            ).map((data, index) => {
              return (
                <div className={styles.rowdata} key={index} >
                  <Row>
                    <Col span={10}>
                      <div className={styles.pricefield}>
                        {data.quantity}
                      </div>
                    </Col>
                    <Col span={7}>
                      <img
                        src={asset.chainId==1?Ethereum:Polygon}
                        className={styles.ethimage}
                      />
                      <div className={styles.pricevalue}>
                        {data.price}
                      </div>
                    </Col>
                    <Col span={7}>
                    {asset.tokenPrice>data.price ?
                    <div className={styles.priceStatus}>
                        Sold Out
                      </div>:
                      asset.tokenPrice==data.price?
                      <div className={styles.activePrice}>
                        Active
                      </div>:null}
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
      </div>
    ) : null}
  </div>
  </>
    )
}

export default TieredPrice