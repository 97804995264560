import React, { useEffect, useRef, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import styles from "./Winners.module.sass";
import { TfiTwitterAlt } from "react-icons/tfi";
import { SiDiscord } from "react-icons/si";
import { AiFillInstagram } from "react-icons/ai";
import { Col, Row } from "antd";
import Stockbg from "../../../images/stockbg.png";
import Placeholder from "../../../images/placeholder.jpg";
import {
  GetAsset,
  GetAssetByToken,
  GetRaceLeaderbord,
} from "../../../services/ApiServices";
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from "react-icons/hi";
import Slider from "react-slick";
import moment from "moment";

const Winners = (props) => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
    <button {...props}>{children}</button>
  );
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    infinite: true,
    nextArrow: (
      <SlickArrow>
        <div className={styles.nextArrow}>
          <HiOutlineArrowSmRight name="arrow-next" size="30" />
        </div>
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <div className={styles.prevArrow}>
          <HiOutlineArrowSmLeft name="arrow-prev" size="30" />
        </div>
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 1179,
        settings: {
          slidesToShow: 2,
          autoplay: true,
          infinite: true,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          infinite: true,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          infinite: true,
        },
      },
    ],
  };

  useEffect(() => {
    getRaceLeaderbord();
  }, []);
  const getRaceLeaderbord = async () => {
    setIsLoading(true);
    let result;
    try {
      result = await GetRaceLeaderbord(true, 1);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
    try {
      if (result.success) {
        setIsLoading(false);
        setLeaderboard(result.leaderboard.docs);
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const Image = ({ id }) => {
    const [imageUrl, setImageUrl] = useState("");
    useEffect(() => {
      getAssetByTokenId(id);
    }, []);
    const getAssetByTokenId = async (id) => {
      let result;
      try {
        result = await GetAssetByToken(id);
      } catch (e) {
        console.log(e);
      }
      try {
        if (result.success) {
          return setImageUrl(result.asset[0].asset.previewImage);
        }
      } catch (e) {
        console.log(e);
      }
    };
    return <img src={imageUrl?imageUrl:Placeholder} className={styles.previewImage} />;
  };
  const dateFunction = (date, time) => {
    let formatDate = moment
      .utc(20 + date + " " + time.replaceAll("-", ":"))
      .format();
    return moment(formatDate).fromNow();
  };
  return (
    <div className={styles.winner}>
      <div className={styles.heading}>Recent Winners</div>
      <Slider className="news-slider winners" {...settings}>
        {leaderboard.slice(0, 10).map((list, index) => (
          <div className={styles.card} key={index}>
            <img src={Stockbg} className={styles.stockbg} />
            <Row>
              <Col span={5}>
                <Image id={list.userDogId} />
              </Col>
              <Col span={19}>
                <div className={styles.container}>
                  <div className={styles.text}>
                    {list.userDogName} #{list.userDogId} won
                  </div>
                  <div className={styles.amount}>{list.winAmount} $BONEZ</div>
                  <div className={styles.date}>
                    {dateFunction(list.date, list.time)}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </Slider>
      {/* <Row >
        {assets.slice(0,10).map((asset, index) => (
          <Col md={11} sm={24} lg={11} xs={24} className={styles.card} key={index}>
            <img src={Stockbg} className={styles.stockbg} />
            <Row>
              <Col span={3}>
                <img src={asset.previewImage} className={styles.previewImage} />
              </Col>
              <Col span={21}>{asset.name}</Col>
            </Row>
          </Col>
        ))}
      </Row> */}
    </div>
  );
};
export default Winners;
