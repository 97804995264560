import React, { useState, useEffect } from "react";
import cn from "classnames";
import Slider from "react-slick";
import styles from "./HotBid.module.sass";
import Icon from "../Icon";
import Card from "../Card";
import { GetAsset, GetAssetpaginate } from "../../services/ApiServices";
import { useDispatch, useSelector } from "react-redux";
import Shade from '../../images/shade.png'
// data
import { bids } from "../../mocks/bids";
import TransparentButton from "../TransparentButton";
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from "react-icons/hi";
import {Link} from "react-router-dom"

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Hot = ({ classSection }) => {
  const [assets, setAssets] = useState([]);
  const [lastKey, setLastkey] = useState({});
  const state = useSelector((state) => state);

  useEffect(() => {
    setAssets(state.Posts.searchposts);
  }, [state.Posts.searchposts.length]);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: (
      <SlickArrow>
        <div className={styles.nextArrow}>
        <HiOutlineArrowSmRight name="arrow-next" size="30" />
        </div>
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <div className={styles.prevArrow}>
        <HiOutlineArrowSmLeft name="arrow-prev" size="30" />
        </div>
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 1179,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className={cn(classSection, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrapper}>
          <div className={styles.similar}>
          <h3 className={cn("h3", styles.title)}>Related</h3>
          <Link to={"/marketplace"} ><TransparentButton label="View Marketplace" className={styles.viewBtn}/></Link>
          </div>
          {/* <img src={Shade} className={styles.shade} /> */}
          <div className={styles.inner}>
            <Slider className="bid-slider" {...settings}>
              {assets.map((x, index) => (
                <Card key={index} className={styles.card} item={x} />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hot;
