import React, { useEffect, useRef, useState } from "react";
import Styles from "./RaceModal.module.sass";
import { Row, Col } from "antd";
import TextInput from "../../../components/NewTextInput";
import Shade from "../../../images/stakeshade.png";
import {
  GetGameInfo,
  GetUserAssets,
  RegisterRace,
} from "../../../services/ApiServices";
import Leftborder from "../../../images/leftborder.png";
import Loader from "../../../components/Loader";
import Bonze from "../../../images/bonez.png";
import Kibblez from "../../../images/kibblez.png";
import Button from "../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import CountDown from "../../../components/CountDown";
import Countdown from "antd/lib/statistic/Countdown";
import { SIGNATURE_MESSAGE_KEY } from "../../../Config/config";
import Web3 from "web3";
import { ethers } from "ethers";
import PrimaryButton from "../../../components/PrimaryButton";

const RaceModal = ({ asset, onClose, event, refreshRaceEvents }) => {
  const state = useSelector((state) => state);
  const { enqueueSnackbar } = useSnackbar();
  const [signatureLoading, setSignatureLoading] = useState(false);

  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [isType1Loading, setIsType1Loading] = useState(false);
  const [isType2Loading, setIsType2Loading] = useState(false);
  const [user, setUser] = useState({});
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [bet, setBet] = useState(0);
  useEffect(() => {
    setUser(state.User.user);
    if (state.User.user) {
      setUser(state.User.user);
    }
  }, [state.User.user]);
  useEffect(() => {
    if (asset.nickName) setName(asset.nickName);
    else setName(asset.name);
  }, [asset]);

  const betAmountHandler = (e) => {
    let betValue = parseInt(e.target.value);
    setError("");
    if (betValue < 100) {
      setError("Bet Amount should be greater than 100");
      setBet(betValue);
    } else {
      setError("");
      setBet(betValue);
    }
  };
  const registerRace = async (signature) => {
    setIsLoading(true);
    setLoadingText("Joining");
    let result;
    let body = {
      gameId: event.gameId,
      dogId: asset.tokenId,
      isRented: asset.isRented ? asset.isRented : false,
    };
    try {
      result = await RegisterRace(signature, body);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      return setError(e.message);
    }
    if (result.success) {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "info" });
      refreshRaceEvents();
      onClose();
    } else {
      setIsLoading(false);
      setError(result.message);
    }
  };
  const getSignature = async () => {
    if (!window.ethereum)
      return enqueueSnackbar("Metamask is not found.", { variant: "error" });

    setIsLoading(true);
    setLoadingText("Signing");

    var message = SIGNATURE_MESSAGE_KEY;
    var hash = Web3.utils.sha3(message);
    let signature;

    await window.ethereum.send("eth_requestAccounts");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    try {
      signature = await signer.signMessage(hash);
    } catch (e) {
      let message = e.message.split(":");
      console.log(e);
      setIsLoading(false);
      return enqueueSnackbar(
        message[0] === "MetaMask Message Signature" ? message[1] : message,
        { variant: "error" }
      );
    }
    registerRace(signature);
  };

  return (
    <div className={Styles.stake}>
      <div className={Styles.head}>
        <span className={Styles.yield}>JOIN</span> RACE
      </div>
      <div className={Styles.challengeText}>
        This race happens every{" "}
        <span className={Styles.yellowTxt}>15 minutes. </span>
        {/* <div className={Styles.wager}> */}
        Join for &nbsp;
        {event.betAmount ? (
          <>Wager {event.betAmount} $KIBBLEZ</>
        ) : (
          <span className={Styles.yellowTxt}> Free </span>
        )}{" "}
        to win <span className={Styles.yellowTxt}> {event.toWin} $BONEZ</span>
      </div>
      <div className={Styles.Card}>
        <div className={Styles.imageDiv}>
          <img src={asset.image} className={Styles.previewImg} />
        </div>
        <div className={Styles.lineBorder}></div>
        <div className={Styles.cards}>
          <div className={Styles.cardsTxt}>
            <div className={Styles.title}>{name}</div>
          </div>
        </div>
      </div>
      {/* <div className={Styles.input}>
        <TextInput
          label="Dog name"
          value={name}
          disabled={true}
          autoComplete={false}
        />
      </div> */}
      {/* <Row>
        <Col md={12} sm={24} xs={24}>
        <div className={Styles.imageDiv}>
            <img src={asset.previewImage} className={Styles.previewImg} />
          </div>
          <div className={Styles.input}>
            <TextInput
              label="Dog name"
              value={name}
              disabled={true}
              autoComplete={false}
            />
          </div>
        </Col>
        <Col md={12} sm={24} xs={24}>
        <div className={Styles.input}>
            <TextInput
              label="Dog name"
              value={name}
              disabled={true}
              autoComplete={false}
            />
          </div>
          <br />
          <div className={Styles.input}>
            <TextInput
              label="Bet Amount  ($KIBBLEZ)"
              value={bet}
              icon={Kibblez}
              onChange={betAmountHandler}
              autoComplete={false}
              type="number"
            />
          </div>
        </Col>
      </Row> */}

      {/* <img src={Shade} className={Styles.shade} /> */}

      <div className={Styles.note}>
        <p>Important Notes</p>
        <div>Before join you have to signin metamask.</div>
        <div>Minimum 3 players required to start the race.</div>
      </div>
      {error && <div className={Styles.error}>{error}</div>}

      <div className={Styles.button}>
        <PrimaryButton
          label="Join Now"
          loading={isLoading}
          loadingtext={loadingText}
          onClick={() => {
            getSignature();
          }}
          className={Styles.button}
        />
      </div>
    </div>
  );
};
export default RaceModal;
