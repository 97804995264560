import React, { useEffect, useRef, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { GetUserAssets } from "../../../services/ApiServices";
import styles from "./Collection.module.sass";
import PawLoading from "../../../json/pawLoading.json";
import LottieLoader from "react-lottie-loader";
import MaleIcon from "../../../images/maleIcon.png";
import FemaleIcon from "../../../images/femaleIcon.png";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import BreedModal from "../BreedingG1/BreedModal";
import { useSnackbar } from "notistack";
import { ToWords } from "to-words";
import PrimaryButton from "../../../components/PrimaryButton";
import BlackModal from "../../../components/BlackModal";
const Collection = ({ countdown4hr }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [male, setMale] = useState([]);
  const [female, setFemale] = useState([]);
  const { account, active, activate, error, deactivate } = useWeb3React();
  const [isBreedModal, setIsBreedModal] = useState(false);
  const [selectedMale, setSelectedMale] = useState([]);
  const [selectedFemale, setSelectedFemale] = useState([]);
  const [maleIndex, setMaleIndex] = useState([]);
  const [femaleIndex, setFemaleIndex] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const toWords = new ToWords();
  const navigate= useNavigate();
  useEffect(() => {
    if (account) getAssets();
    getAssets();
  }, [account]);
  const getAssets = async () => {
    setIsLoading(true);
    let result;
    try {
      result = await GetUserAssets(account, "onlydogz=true&rents=true");
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
    try {
      if (result.success) {
        setIsLoading(false);
        setAssets(result.result.assets);
        let male = result.result.assets.filter(function (data) {
          if (data.gender === "MALE") {
            return data;
          }
        });

        setMale(male);
        let female = result.result.assets.filter(function (data) {
          if (data.gender === "FEMALE") {
            return data;
          }
        });
        setFemale(female);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const collectMales = (data, index) => {
    let maleData;
    if (selectedMale.length) {
      maleData = selectedMale.some((value) => {
        return (
          JSON.stringify(data._id) == JSON.stringify(value._id) &&
          data.tokenId == value.tokenId
        );
      });
      if (maleData) {
        let filteredArray = selectedMale.filter((item) => {
          return (
            JSON.stringify(item._id) !== JSON.stringify(data._id) ||
            data.tokenId != item.tokenId
          );
        });

        setSelectedMale(filteredArray);
        let filteredIndex = maleIndex.filter((item) => {
          return item !== index;
        });
        setMaleIndex(filteredIndex);
      } else {
        setMaleIndex((maleIndex) => [...maleIndex, index]);
        setSelectedMale((selectedMale) => [...selectedMale, data]);
      }
    } else {
      setMaleIndex((maleIndex) => [...maleIndex, index]);
      setSelectedMale((selectedMale) => [...selectedMale, data]);
    }
  };
  const collectFemales = (data, index) => {
    let femaleData;
    if (selectedFemale.length) {
      femaleData = selectedFemale.some((value) => {
        return (
          JSON.stringify(data._id) == JSON.stringify(value._id) &&
          data.tokenId == value.tokenId
        );
      });
      if (femaleData) {
        let filteredArray = selectedFemale.filter((item) => {
          return (
            JSON.stringify(item._id) !== JSON.stringify(data._id) ||
            data.tokenId != item.tokenId
          );
        });
        setSelectedFemale(filteredArray);
        let filteredIndex = femaleIndex.filter((item) => {
          return item !== index;
        });
        setFemaleIndex(filteredIndex);
      } else {
        setFemaleIndex((femaleIndex) => [...femaleIndex, index]);
        setSelectedFemale((selectedFemale) => [...selectedFemale, data]);
      }
    } else {
      setFemaleIndex((femaleIndex) => [...femaleIndex, index]);
      setSelectedFemale((selectedFemale) => [...selectedFemale, data]);
    }
  };
  const confirm = () => {
    if (selectedFemale.length < selectedMale.length) {
      return enqueueSnackbar(
        `Select ${toWords.convert(
          selectedMale.length - selectedFemale.length
        )} more female asset`,
        { variant: "error" }
      );
    } else if (selectedMale.length < selectedFemale.length) {
      return enqueueSnackbar(
        `Select ${toWords.convert(
          selectedFemale.length - selectedMale.length
        )} more male asset`,
        { variant: "error" }
      );
    } else {
      setIsBreedModal(true);
    }
  };
  return (
    <>
      <div className={styles.breeding_g1_container}>
        <div className={styles.breeding_center}>
          <div className={styles.center_title}>Rare/Legendary Collection</div>
          {/* <div className={styles.note}>
            Select the Cyberdogz
          </div> */}
          <Row>
            <Col span={11}>
              <div className={styles.maleSection}>
                <div className={styles.gender}>
                  MALE <span className={styles.count}>{male.length}</span>
                </div>
                <div>
                  {isLoading ? (
                    <LottieLoader
                      animationData={PawLoading}
                      className={styles.loader}
                    />
                  ) : male.length <= 0 ? (
                    <div className={styles.noData}>
                      No Male assets available
                      <button
                        className={styles.buyButton}
                        onClick={() => navigate("/marketplace")}
                      >
                        Buy Male Assets
                      </button>
                    </div>
                  ) : (
                    <div className={styles.imgdiv}>
                      {" "}
                      {male.map((data, index) => (
                        <div
                          className={
                            maleIndex.includes(index)
                              ? styles.assetSelected
                              : styles.genderCard
                          }
                        >
                          <img
                            key={index}
                            className={styles.dogimg}
                            src={data.previewImage}
                            onClick={() => {
                              collectMales(data, index);
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className={styles.Breeding_Bottom}>
                  <div className={styles.bottom_center}>
                    <div className={styles.center}>
                      <div className={styles.price}>
                        <p>Price / pair</p>
                        <span className={styles.no}>
                          <span className={styles.priceValue}>25 USDT</span>{" "}
                          <span style={{ color: "#00A2FD" }}>(Polygon)</span>{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={2}>
              {/* <img src={HorizontalShade} className={styles.horizontalshade} /> */}
            </Col>
            <Col span={11}>
              <div className={styles.maleSection}>
                <div className={styles.gender}>
                  FEMALE <span className={styles.count}>{female.length}</span>
                </div>
                <div>
                  {isLoading ? (
                    <LottieLoader
                      animationData={PawLoading}
                      className={styles.loader}
                    />
                  ) : female.length <= 0 ? (
                    <div className={styles.noData}>
                      No female assets available
                      <button
                        className={styles.buyButton}
                        onClick={() =>
                          navigate("/detail/61c9c7ed5f3a0854f77fa96d")
                        }
                      >
                        Buy Female Lander
                      </button>
                    </div>
                  ) : (
                    <div className={styles.imgdiv}>
                      {" "}
                      {female.map((data, index) => (
                        // <img
                        //   key={index}
                        //   className={
                        //     femaleIndex.includes(index)
                        //       ? styles.dogselectimg
                        //       : styles.dogimg
                        //   }
                        //   src={data.previewImage}
                        //   onClick={() => {
                        //     collectFemales(data, index);
                        //   }}
                        // />
                        <div
                          className={
                            femaleIndex.includes(index)
                              ? styles.assetSelected
                              : styles.genderCard
                          }
                        >
                          <img
                            className={styles.dogimg}
                            src={data.previewImage}
                            onClick={() => {
                              collectFemales(data, index);
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className={styles.Breeding_Bottom}>
                  <div className={styles.bottom_right}>
                    <div className={styles.center}>
                      <div className={styles.balance}>
                        <p>No. Of Cyberdogz Selected</p>
                        <div className={styles.values}>
                          <img
                            src={MaleIcon}
                            style={{ width: "23px", color: "#00A2FD" }}
                          />{" "}
                          <span className={styles.no}>
                            {selectedMale.length}
                          </span>
                          <br></br>
                          <img
                            src={FemaleIcon}
                            style={{ width: "20px", color: "#00A2FD" }}
                          />{" "}
                          <span className={styles.no}>
                            {selectedFemale.length}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {/* <div className={styles.percentage_container}>
            <div className={styles.percentage}>?</div>
          </div> */}

          <div className={styles.center}>
            <div className={styles.tranferButton}>
              <PrimaryButton
                label="CONFIRM NOW"
                disabled={
                  selectedMale.length && selectedFemale.length ? false : true
                }
                onClick={() => {
                  confirm();
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <BlackModal
        visible={isBreedModal}
        onClose={() => {
          setIsBreedModal(false);
        }}
        outerClassName={styles.modal}
      >
        <BreedModal male={selectedMale} female={selectedFemale} />
      </BlackModal>
    </>
  );
};
export default Collection;
