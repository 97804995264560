import React from "react";
import styles from "./rent.module.sass";
import RentImg from "../../../images/rent.png";
import Mask from "../../../images/rentMask.png";
import MobRentImg from "../../../images/mobMask.png";
import MobMask from "../../../images/mobRentMask.png";
import { isMobile } from "react-device-detect";
import TransparentButton from "../../../components/TransparentButton";
import { useNavigate } from "react-router-dom";

const Rent = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.rent}>
      {/* <div className={styles.webRent}> */}
      <img
        src={isMobile ? MobRentImg : RentImg}
        alt="img"
        className={styles.webMaskRent}
      />
      {/* </div> */}
      {/* <div className={styles.webRent}>
        <img
          src={isMobile ? MobMask : Mask}
          alt="img"
          className={styles.webMaskRent}
        />
      </div> */}
      <div className={styles.content}>
        <div className={styles.heading}>Rent a Gen One CYBERDOGZ</div>
        <div className={styles.text}>
          Enter your email for your chance to win the Gen One Daily Lottery
          (enter email field).
        </div>
        <TransparentButton
          label="Read More"
          className={styles.readMore}
          onClick={() => {
            navigate("/lottery");
          }}
        />
      </div>
    </div>
  );
};

export default Rent;
