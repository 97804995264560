import { useWeb3React } from "@web3-react/core";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { RegisterLottery } from "../../services/ApiServices";
import Button from "../Button";
import PrimaryButton from "../PrimaryButton";
import TransparentButton from "../TransparentButton";
import TextInput from "../TransparentTextInput";
import styles from "./LotteryRegister.module.sass";
const LotteryRegister = (props,{ onClose,registerResult }) => {
  const { account, active, activate, error, deactivate } = useWeb3React();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleEmail = (e) => {
    let value = e.target.value;
    setEmailError("");
    setEmail("")
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value.length <= 0) {
      setEmailError("Please enter Email");
    } else if (!re.test(String(value).toLowerCase())) {
      setEmailError("Please enter the valid Email");
    } else {
      setEmailError("");
      setEmail(value);
    }
  };

  const register = async () => {
    if (!email) return setEmailError("Please enter Email");

    setLoading(true);
    let result;
    let body = {
      address: account,
      email: email,
    };
    try {
      result = await RegisterLottery(body);
    } catch (e) {
      setLoading(false);
      return enqueueSnackbar(e.message, { variant: "error" });
    }
    if (result.success) {
      setLoading(false);
      props.registerResult(result.message)
      enqueueSnackbar(result.message, { variant: "info" });
      return props.onClose();
    }
  };
  return (
    <div>
      <div className={styles.title}>
        Lottery Register
      </div>
      <TextInput type="text" label="Wallet Address" value={account} disabled={true} />
      <br />
      <TextInput type="email" label="Email" onChange={handleEmail} error={emailError} success={email} />
      {emailError && <div className={styles.error}>{emailError}</div>}
      <div className={styles.button1}>
        {email ? <PrimaryButton label="Register" loading={loading} onClick={register} className={styles.button}/> :
        <TransparentButton label="Register" loading={loading} onClick={register} className={styles.button}/>}
      </div>
    </div>
  );
};
export default LotteryRegister;
