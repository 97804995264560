
import {
    GETLANDCONTRACT,
    GETCAPSULECONTRACT,
    GETASSETCONTRACT
} from './ContractTypes'

const initialState = {
    landContract: {},
    capsuleContract: {},
    legendaryRareContract: {},
    assetContract: {},
    contract: [],
    contractFirstLoading: false,
    contractLoading: false,
}



const ContractReducer = (state = initialState, action) => {

    switch (action.type) {

       
        case GETLANDCONTRACT:
            return { ...state, landContract: action.payload, contractFirstLoading: true }
        case GETCAPSULECONTRACT:
            return { ...state, capsuleContract: action.payload, contractFirstLoading: true }
        case GETASSETCONTRACT:
            return { ...state, assetContract: action.payload }
            // case CLEARCONTRACT:
            //     return {
            //         landContract: {},
            //         capsuleContract: {},
            //         legendaryRareContract: {},
            //         contract: [],
            //         contractLoading: true,
            //         contractLoading: true,
            //     }

                    

        default: return state;
    }



}

export default ContractReducer