import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import moment from "moment";
import Ethereum from "../../images/Ethereum.png";
import { minifyAddress } from "../../utils/helpers";
import Styles from "./GameHistory.module.sass";
import Loader from "../Loader";
import HistoryShade from "../../images/historyshade.png";
import { Link } from "react-router-dom";
import Bonez from "../../images/bonez.png";

function GameHistory({ history }) {
  const [gameHistoryAccordian, setRentHistoryAccordian] = useState(true);

  return (
    <div className={Styles.history_table}>
      <div className={Styles.rowdata}>
        <Row>
          <Col span={4}>
            <div className={Styles.buyer}>Game</div>
          </Col>
          <Col span={3}>
            <div className={Styles.seller}>Kills</div>
          </Col>
          <Col span={4}>
            <div className={Styles.seller}>Deths</div>
          </Col>
          <Col span={5}>
            <div className={Styles.seller}>Date</div>
          </Col>
          <Col span={4}>
            <div className={Styles.seller}>Points</div>
          </Col>
          <Col span={4}>
            <div className={Styles.seller}>Bonez</div>
          </Col>
        </Row>
      </div>
      <img src={HistoryShade} className={Styles.history_shade} />
      {false ? (
        <Loader className={Styles.loader} />
      ) : history.length ? (
        <>
          {history.map((data, index) => {
            return (
              <div
                className={index % 2 == 0 ? Styles.rowdata : Styles.rowdatabg}
                key={index}
              >
                <Row>
                  <Col span={4}>
                  <div className={Styles.pricefield}>{data.game}</div>

                  </Col>
                  <Col span={3}>
                  <div className={Styles.pricefield}>{data.kill}</div>

                  </Col>
                  <Col span={4}>
                  <div className={Styles.pricefield}>{data.death}</div>

                  </Col>
                  <Col span={5}>
                    <div className={Styles.pricefield}>
                    {moment.utc(data.date).format("DD-MM-YYYY")}
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className={Styles.pricefield}>{data.points}</div>
                  </Col>
                  <Col span={4}>
                  <div className={Styles.status}>
                          {data.bonez}
                          <img className={Styles.icon} src={Bonez} />
                        </div>
                  </Col>
                </Row>
              </div>
            );
          })}
        </>
      ) : (
        <p className={Styles.empty}>No data available</p>
      )}
    </div>
  );
}

export default GameHistory;
