import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./GemCard.module.sass";
import Button from "../Button";
import PrimaryButton from "../PrimaryButton";
const GemCard = ({ items }) => {

  const [zircon, setZircon] = useState([]);
  const [diamond, setDiamond] = useState([]);
  const [ruby, setRuby] = useState([]);
  const [sapphire, setSapphire] = useState([]);
  const [topaz, setTopaz] = useState([]);
  const cards = [1, 2, 3, 4, 5];
  useEffect(() => {
    items.filter((x) => {
      switch (x.assetName) {
        case "ZIRCON":
        case "zircon":
          setZircon((zircon) => [...zircon, x]);
          break;
        case "DIAMOND":
        case "diamond":
          setDiamond((diamond) => [...diamond, x]);
          break;
        case "RUBY":
        case "ruby":
          setRuby((ruby) => [...ruby, x]);
          break;
        case "SAPPHIRE":
        case "sapphire":
          setSapphire((sapphire) => [...sapphire, x]);
          break;
        case "TOPAZ":
        case "topaz":
          setTopaz((topaz) => [...topaz, x]);
          break;
      }
    });
  }, [items]);
  const quantity = (cardName, id) => {
    let quantity = items.filter(
      (x) => x.assetName == cardName && x.itemId == id
    );
    return quantity[0].available;
  };

  return (
    // <div className={styles.container}>
    //   <div className={styles.soon}>Coming Soon...</div>
    // </div>
    <div className={styles.container}>
    {items.length<=0?
      <div className={styles.soon}>No Cardz found</div>:
    <>
      {zircon.length > 0 && (
        <>
          <div className={styles.heading}>Zircon</div>
          <Row>
            {cards.map((value, index) => (
              <Col md={4} sm={8} xs={8} key={index}>
                <div className={styles.card}>
                  {zircon.find((data) => {
                    return data.itemId == value;
                  }) ? (
                    <>
                      <div className={styles.quantity}>
                        ( {quantity("zircon", value)} )
                      </div>
                      <img
                        src={
                          "https://cyberdogz.s3.us-west-1.amazonaws.com/nft/assets/gem-cards/zircon/" +
                          value +
                          ".png"
                        }
                        className={styles.cardimg}
                      />
                    </>
                  ) : (
                    <>
                      <div className={styles.quantity}>( 0 )</div>

                      <img
                        src={
                          "https://cyberdogz.s3.us-west-1.amazonaws.com/nft/assets/gem-cards/zircon/" +
                          value +
                          ".png"
                        }
                        className={styles.cardimghide}
                      />
                    </>
                  )}
                </div>
              </Col>
            ))}
            <Col md={4} sm={24} xs={24}>
            {zircon.length===5 ?
              <div className={styles.body}>
                <div className={styles.text}>
              Burn all 5 Gem Cardz and Mint an Alpha CYBERDOGZ with Advanced
              Capabilities.{" "}
            </div>

                <div className={styles.text1}>You can mint now ALPHA CYBERDOGZ</div>

                <div className={styles.button}>
                  {" "}
                  <PrimaryButton label="Coming Soon" disabled={true} />
                </div>
              </div>:
              <div className={styles.cardButton}>
       

       <div className={styles.text1}>You need {5-zircon.length} more before you can Mint an Alpha CYBERDOGZ. </div>
       <div className={styles.button}>
         {" "}
         <PrimaryButton label="Coming Soon" disabled={true} />
       </div>
     </div>}
            </Col>
          </Row>
        </>
      )}
      {diamond.length > 0 && (
        <>
          <div className={styles.heading}>Diamond</div>
          <Row>
            {cards.map((value, index) => (
              <Col md={4} sm={8} xs={8} key={index}>
                <div className={styles.card}>
                  {diamond.find((data) => {
                    return data.itemId == value;
                  }) ? (
                    <>
                      <div className={styles.quantity}>
                        ( {quantity("diamond", value)} )
                      </div>
                      <img
                        src={
                          "https://cyberdogz.s3.us-west-1.amazonaws.com/nft/assets/gem-cards/diamond/" +
                          value +
                          ".png"
                        }
                        className={styles.cardimg}
                      />
                    </>
                  ) : (
                    <>
                      <div className={styles.quantity}>( 0 )</div>

                      <img
                        src={
                          "https://cyberdogz.s3.us-west-1.amazonaws.com/nft/assets/gem-cards/diamond/" +
                          value +
                          ".png"
                        }
                        className={styles.cardimghide}
                      />
                    </>
                  )}
                </div>
              </Col>
            ))}
            <Col md={4} sm={24} xs={24}>
            {diamond.length===5 ?
              <div className={styles.body}>
                <div className={styles.text}>
              Burn all 5 Gem Cardz and Mint an Alpha CYBERDOGZ with Advanced
              Capabilities.{" "}
            </div>

                <div className={styles.text1}>You can mint now ALPHA CYBERDOGZ</div>

                <div className={styles.button}>
                  {" "}
                  <PrimaryButton label="Coming Soon" disabled={true} />
                </div>
              </div>:
              <div className={styles.cardButton}>
       

       <div className={styles.text1}>You need {5-diamond.length} more before you can Mint an Alpha CYBERDOGZ. </div>
       <div className={styles.button}>
         {" "}
         <PrimaryButton label="Coming Soon" disabled={true} />
       </div>
     </div>}
            </Col>
          </Row>
        </>
      )}

      {ruby.length > 0 && (
        <>
          <div className={styles.heading}>Ruby</div>
          <Row>
            {cards.map((value, index) => (
              <Col md={4} sm={8} xs={8}>
                <div className={styles.card}>
                  {ruby.find((data) => {
                    return data.itemId == value;
                  }) ? (
                    <>
                      <div className={styles.quantity}>
                        ( {quantity("ruby", value)} )
                      </div>
                      <img
                        src={
                          "https://cyberdogz.s3.us-west-1.amazonaws.com/nft/assets/gem-cards/ruby/" +
                          value +
                          ".png"
                        }
                        className={styles.cardimg}
                      />
                    </>
                  ) : (
                    <>
                      <div className={styles.quantity}>( 0 )</div>

                      <img
                        src={
                          "https://cyberdogz.s3.us-west-1.amazonaws.com/nft/assets/gem-cards/ruby/" +
                          value +
                          ".png"
                        }
                        className={styles.cardimghide}
                      />
                    </>
                  )}
                </div>
              </Col>
            ))}
            <Col md={4} sm={24} xs={24}>
            {ruby.length===5 ?
              <div className={styles.body}>
                <div className={styles.text}>
              Burn all 5 Gem Cardz and Mint an Alpha CYBERDOGZ with Advanced
              Capabilities.{" "}
            </div>

                <div className={styles.text1}>You can mint now ALPHA CYBERDOGZ</div>

                <div className={styles.button}>
                  {" "}
                  <PrimaryButton label="Coming Soon" disabled={true} />
                </div>
              </div>:
              <div className={styles.cardButton}>
       

       <div className={styles.text1}>You need {5-ruby.length} more before you can Mint an Alpha CYBERDOGZ. </div>
       <div className={styles.button}>
         {" "}
         <PrimaryButton label="Coming Soon" disabled={true} />
       </div>
     </div>}
            </Col>
          </Row>
        </>
      )}

      {sapphire.length > 0 && (
        <>
          <div className={styles.heading}>Sapphire</div>
          <Row>
            {cards.map((value, index) => (
              <Col md={4} sm={8} xs={8} key={index}>
                <div className={styles.card}>
                  {sapphire.find((data) => {
                    return data.itemId == value;
                  }) ? (
                    <>
                      <div className={styles.quantity}>
                        ( {quantity("sapphire", value)} )
                      </div>
                      <img
                        src={
                          "https://cyberdogz.s3.us-west-1.amazonaws.com/nft/assets/gem-cards/sapphire/" +
                          value +
                          ".png"
                        }
                        className={styles.cardimg}
                      />
                    </>
                  ) : (
                    <>
                      <div className={styles.quantity}>( 0 )</div>

                      <img
                        src={
                          "https://cyberdogz.s3.us-west-1.amazonaws.com/nft/assets/gem-cards/sapphire/" +
                          value +
                          ".png"
                        }
                        className={styles.cardimghide}
                      />
                    </>
                  )}
                </div>
              </Col>
            ))}
            <Col md={4} sm={24} xs={24}>
            {sapphire.length===5 ?
              <div className={styles.body}>
                <div className={styles.text}>
              Burn all 5 Gem Cardz and Mint an Alpha CYBERDOGZ with Advanced
              Capabilities.{" "}
            </div>

                <div className={styles.text1}>You can mint now ALPHA CYBERDOGZ</div>

                <div className={styles.button}>
                  {" "}
                  <PrimaryButton label="Coming Soon" disabled={true} />
                </div>
              </div>:
              <div className={styles.cardButton}>
       

       <div className={styles.text1}>You need {5-sapphire.length} more before you can Mint an Alpha CYBERDOGZ. </div>
       <div className={styles.button}>
         {" "}
         <PrimaryButton label="Coming Soon" disabled={true} />
       </div>
     </div>}
            </Col>
          </Row>
        </>
      )}
      {topaz.length > 0 && (
        <>
          <div className={styles.heading}>Topaz</div>
          <Row>
            {cards.map((value, index) => (
              <Col md={4} sm={8} xs={8} key={index}>
                <div className={styles.card}>
                  {topaz.find((data) => {
                    return data.itemId == value;
                  }) ? (
                    <>
                      <div className={styles.quantity}>
                        ( {quantity("topaz", value)} )
                      </div>
                      <img
                        src={
                          "https://cyberdogz.s3.us-west-1.amazonaws.com/nft/assets/gem-cards/topaz/" +
                          value +
                          ".png"
                        }
                        className={styles.cardimg}
                      />
                    </>
                  ) : (
                    <>
                      <div className={styles.quantity}>( 0 )</div>

                      <img
                        src={
                          "https://cyberdogz.s3.us-west-1.amazonaws.com/nft/assets/gem-cards/topaz/" +
                          value +
                          ".png"
                        }
                        className={styles.cardimghide}
                      />
                    </>
                  )}
                </div>
              </Col>
            ))}
            <Col md={4} sm={24} xs={24}>
            {topaz.length===5 ?
              <div className={styles.body}>
                <div className={styles.text}>
              Burn all 5 Gem Cardz and Mint an Alpha CYBERDOGZ with Advanced
              Capabilities.{" "}
            </div>

                <div className={styles.text1}>You can mint now ALPHA CYBERDOGZ</div>

                <div className={styles.button}>
                  {" "}
                  <PrimaryButton label="Coming Soon" disabled={true} />
                </div>
              </div>:
              <div className={styles.cardButton}>
       

       <div className={styles.text1}>You need {5-topaz.length} more before you can Mint an Alpha CYBERDOGZ. </div>
       <div className={styles.button}>
         {" "}
         <PrimaryButton label="Coming Soon" disabled={true} />
       </div>
     </div>}
            </Col>
          </Row>
        </>
      )}
      </>
    }
    </div>
  );
};
export default GemCard;
