import React from "react";
import Styles from "./Racing.module.sass";

import cn from "classnames";

import RaceTrack from "../../images/raceTrack.png";
import { Row, Col, Input } from "antd";
const Racing = () => {
  

  return (
    <div className={cn("section", Styles.section)}>
            <div className={cn("container", Styles.container)}>
                  <h3 className={cn("h3", Styles.title)}>Racing</h3>
                  <div className={Styles.raceTrack}>
          <Row>
            <Col md={14} xs={24}>
              <img src={RaceTrack} className={Styles.trackImg} />
            </Col>
            <Col md={10} xs={24}>
              <div className={Styles.track}>
              <p>
                The centrepiece of the CYBERDOGZ Metaverse. A series of racing
                tournaments open to all players.
                <br />
                <br />
                Bring your trained CYBERDOGZ, enter the race, compete with
                players across the globe and win exciting P2E rewards and level
                up in ranking!
                <br />
                <br />
                As you level up in ranking you can enter races with higher
                rewards and be able to purchase/own more CYBERDOGZ and Kennels.{" "}
              </p>
              </div>
            </Col>
          </Row>
        </div>

            <br/>
            <br/>
            </div>
            </div>
  );

};

export default Racing;
