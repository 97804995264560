import React, { useEffect, useRef, useState } from "react";
import Styles from "./Stake.module.sass";
import { Row, Col } from "antd";
import TextInput from "../../TransparentTextInput";
import Shade from "../../../images/stakeshade.png";
import {
  GetClaimKibblez,
  GetStakedAssets,
} from "../../../services/ApiServices";
import Leftborder from "../../../images/leftborder.png";
import Loader from "../../Loader";
import Bonze from "../../../images/bonez.png";
import Kibblez from "../../../images/kibblez.png";
import CountDown from "../../CountDown";
import NumberFormat from "react-number-format";
import moment from "moment";
import { useWeb3React } from "@web3-react/core";
import Button from "../../Button";
import Modal from "../../NewModal";
import TransferKibblez from "../../TransferKibblez";
import PrimaryButton from "../../PrimaryButton";
import BlackModal from "../../BlackModal";

const result = {
  result: {
    isClaimable: true,
    totalStaked: 3,
    totalRewardPerDay: 12000,
    kibblezBalance: 0,
    kibblezClaimable: 901,
    bonezBalance: 0,
  },
  success: true,
};
const Stake = (props) => {
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAssetLoading, setIsAssetLoading] = useState(false);
  const [kibblez, setKibblez] = useState({});
  const [address, setAddress] = useState();
  const [countdown, setCountdown] = useState(
    moment.utc("14:00", "HH:mm").valueOf()
  );
  const [transferModal, setTransferModal] = useState(false);
  const { account, active, activate, error, deactivate } = useWeb3React();

  useEffect(() => {
    if (account) getKibblez(account);
  }, [account]);

  const getKibblez = async (id) => {
    setIsLoading(true);
    let result;
    try {
      result = await GetClaimKibblez(id);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
    try {
      if (result.success) {
        setIsLoading(false);
        setKibblez(result.result);
        setAssets(result.result.assets);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const interval = useRef();

  useEffect(() => {
    interval.current = setInterval(() => {
      const currentDate = moment.utc().valueOf();
      if (countdown - currentDate < 0) {
        setCountdown(countdown + 86400000);
      }
    }, 1000);

    // move clean up function to here:
    return () => {
      clearInterval(interval.current);
      interval.current = null;
    };
  }, [countdown]);
  const modalClose = () => setTransferModal(false);

  return (
    <div className={Styles.stake}>
      <div className={Styles.head}>
        {/* <span className={Styles.heading}>CYBERDOGZ</span>  */}
        CYBERDOGZ STAKE
      </div>
      <div className={Styles.mission}>
        <div className={Styles.subhead}>CYBERDOGZ mission 1</div>
      </div>

      <Row>
        <Col md={7} xs={24} sm={11}>
          <TextInput
            label="Staked"
            value={Intl.NumberFormat("en-US").format(
              kibblez.totalStaked ? kibblez.totalStaked : 0
            )}
            disabled={true}
          />
        </Col>
        <Col md={1} sm={1} />

        <Col md={7} xs={24} sm={11} className={Styles.margin}>
          <TextInput
            label="$KIBBLEZ/day"
            disabled={true}
            icon={Kibblez}
            value={Intl.NumberFormat("en-US").format(
              kibblez.totalRewardPerDay ? kibblez.totalRewardPerDay : 0
            )}
          />
        </Col>
        <Col md={1} sm={1} />
        <Col md={7} xs={24} sm={11} className={Styles.margin}>
          <TextInput
            label="$KIBBLEZ Balance"
            disabled={true}
            icon={Kibblez}
            value={Intl.NumberFormat("en-US").format(
              kibblez.kibblezBalance ? kibblez.kibblezBalance : 0
            )}
          />
        </Col>
        <Col md={1} />
      </Row>
      <div className={Styles.tranferButton}>
        <center>
          <PrimaryButton
            label="Transfer $KIBBLEZ"
            onClick={() => setTransferModal(true)}
          />
        </center>
      </div>
      {/* <img src={Shade} className={Styles.shade} /> */}
      <div className={Styles.lineBorder} />
      {kibblez.isClaimable || !kibblez.userFound ? (
        <div className={Styles.balance}>
          Since you don’t own a “SCOOPER 5000”,
          <br />
          Your KIBBLEZ balance will be reset at
          <span className={Styles.yield}> 2PM UTC</span>
          <br />
          <span className={Styles.coundown}>
            ( <CountDown asset={countdown} /> )
          </span>
        </div>
      ) : (
        <div className={Styles.balance}>
          You own a “SCOOPER 5000”,
          <br />
          Enjoy your balance without worrying about reset!
          <br />
          Your balance is automatically credited.
        </div>
      )}
      {/* <img src={Shade} className={Styles.shade} /> */}
      <div className={Styles.lineBorder} />

      <div className={Styles.subhead}>Staked CYBERDOGZ 2</div>
      {isLoading ? (
        <Loader className={Styles.loader} />
      ) : assets.length ? (
        <Row>
          {assets.map((x, index) => (
            <Col md={6} xs={24} sm={12} key={index}>
              <div className={Styles.card}>
                <Row>
                  {/* <Col span={2}>
                    <img src={Leftborder} className={Styles.border} />
                  </Col> */}
                  <Col span={22}>
                    <div  className={Styles.previewImg} >
                    <img src={x.previewImage}/>
                    </div>
                  </Col>
                </Row>
                <div className={Styles.title}>
                  {x.name.split(":")[0].toUpperCase()}
                </div>
                <div className={Styles.yield}>
                  Yield {x.rewardPerDay ? x.rewardPerDay : 100} $KIBBLEZ/day
                </div>
              </div>
            </Col>
          ))}
        </Row>
      ) : (
        <p className={Styles.empty}>No asset available</p>
      )}
      <BlackModal
        visible={transferModal}
        outerClassName={Styles.tranferModal}
        onClose={() => setTransferModal(false)}
        close={Styles.close}
        containerClassName={Styles.modalContainer}
      >
        <TransferKibblez
          kibblez={kibblez}
          getKibblez={getKibblez}
          modalClose={modalClose}
        />
      </BlackModal>
    </div>
  );
};
export default Stake;
