import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./RecentActivities.module.sass";
import CurrentChallenge from "./CurrentChallenge";
import { Game3DShooterLeaderBoard } from "../../../../services/ApiServices";

const navLinks = ["This Month", "All time", "Today"];

const RecentActivities = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [leaderboard, setLeaderboader] = useState([]);

  useEffect(() => {
    getLeaderboard();
  }, []);
  const getLeaderboard = async () => {
    let body = { limit: 10 };
    setLoading(true);
    let result;
    try {
      result = await Game3DShooterLeaderBoard(body);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
    try {
      if (result) {
        setLoading(false);
        setLeaderboader(result.data.docs);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  const leaderboard1 = [
    { name: "Tom", points: 100, Killed: "Tonny", date: 1686735244 },
    { name: "Tonny", points: 100, killed: "John", date: 1686735244 },
    { name: "Ben", points: 100, killed: "Tonny", date: 1686735244 },
    { name: "Tom", points: 100, killed: "Ben", date: 1686735244 },
    { name: "Tonny", points: 100, killed: "Tom", date: 1686735244 },
    { name: "Ben", points: 100, killed: "Tom", date: 1686735244 },
    { name: "John", points: 100, killed: "Tonny", date: 1686735244 },
    { name: "Tom", points: 100, killed: "Ben", date: 1686735244 },
    { name: "Ben", points: 100, killed: "John", date: 1686735244 },
    { name: "John", points: 100, killed: "Tonny", date: 1686735244 },
  ];
  return (
    <>
      <div className={styles.container}>
        <div className={styles.group}>
          <div className={styles.item}>
            <CurrentChallenge leaderboard={leaderboard} loading={loading} />
          </div>
        </div>
      </div>
    </>
  );
};

export default RecentActivities;
