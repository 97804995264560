import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Account.module.sass";

import { useDispatch, useSelector } from "react-redux";

import { getUser, removeUser } from "../../../redux";
import { useWeb3React } from "@web3-react/core";

import Avatar from "../../../images/avatar.png";
import Stake from "../Stake";
import Modal from "../../NewModal";

const options = ["MetaMask"];

const Account = ({ className, refresh, stick, connect, logout,showDrawer }) => {
  const state = useSelector((state) => state);
  const [isStakeModal, setIsStakeModal] = useState(false);
  const [pathNum, setPathNum] = useState(1000);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  const [user, setUser] = useState({});

  const { account, active, activate, error, deactivate } = useWeb3React();

  const items = [
    {
      title: "BanK",
      url: "/bank",
    },
  ];
  useEffect(() => {
    setUser(state.User.user);
  }, [state.User.user]);

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={styles.user}>
        <div className={styles.avatar}>
          <img
            src={user?.profileUrl ? user.profileUrl : Avatar}
            onClick={() => showDrawer()}
            // onClick={() => setVisible(!visible)}
          />
        </div>

        {visible && (
          <div className={stick ? styles.stickBody : styles.body}>
            <div className={styles.menu}>
              <Link
                className={styles.item}
                to={"/bank"}
                onClick={() => {
                  setVisible(!visible);
                  setPathNum(0);
                }}
              >
                <div className={styles.text}>Bank</div>
              </Link>

              <button
                className={styles.item}
                onClick={() => {
                  setIsStakeModal(true);
                  setVisible(!visible);
                  setPathNum(1);
                }}
              >
                <div className={styles.text}>Stake</div>
              </button>
            </div>
          </div>
        )}
      </div>
      <Modal
        visible={isStakeModal}
        onClose={() => setIsStakeModal(false)}
        close={styles.close}
        containerClassName={styles.modalContainer}
      >
        <Stake />
      </Modal>
    </OutsideClickHandler>
  );
};

export default Account;
