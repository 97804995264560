import React from "react";
import Styles from "./Breeding.module.sass";
import bg from "../../../images/male_female.png";
import Bonez from "../../../images/bonez.png";
import rectangle2 from "../../../images/usdt.png";
import { Link } from "react-router-dom";
import { Divider } from "@material-ui/core";
import { useSnackbar } from "notistack";

function Breeding() {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <div className={Styles.breeding_bg}>
        <div className={Styles.breeding_content}>
          {/* mobile title */}
          <p className={Styles.breeding_title_top}>Breeding</p>

          <div className={Styles.breeding_left}>
            <img src={bg} className={Styles.mfimg} />
          </div>
          <div className={Styles.breeding_right}>
            <div className={Styles.breeding_right_top}>
              <div className={Styles.breeding_title}>Breeding</div>
              <div className={Styles.para}>
                Breed your cyberdog to increase your collection
              </div>
              <div className={Styles.breedbutton}>
                <Link to={"/breeding"} className={Styles.breed_btn}>
                  BREED NOW
                </Link>
              </div>
            </div>
            <div className={Styles.bottom_content}>
              <div className={Styles.breeding_title2}>
                Breeding
                <span className={Styles.G1}>
                  {" "}
                  G1<sup className={Styles.sub}>New</sup>
                </span>
              </div>
              <p className={Styles.p1}>
                New breeding mode for your rare and legendary rare collection.
              </p>
              <p className={Styles.p2}>
                Breeding G1 are required for racing game.
              </p>
              <p className={Styles.p3}>MASS BREEDING AVAILABLE NOW</p>
              <div className={Styles.btn}>
                <div className={Styles.breed_btn} 

                >
                  <Link to={"/breeding_g1"} className={Styles.breedbutton}>
                    BREED NOW
                  </Link>
                </div>
              </div>
              <div className={Styles.required}>
                (
                <sup style={{ fontSize: "10px" }}>
                  *Required <img src={Bonez} style={{ width: "6%" }} />{" "}
                  <img src={rectangle2} style={{ width: "6%" }} />
                </sup>
                )
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Breeding;
