import React, { useEffect } from "react";
import styles from "./BreedfiAd.module.sass";
import AdSense from "react-adsense";
const CyberDogzAd = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9762246811722235";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div>
      {/* <ins
        className="adsbygoogle"
        style={{display:"block"}}
        data-ad-client="ca-pub-9762246811722235"
        data-ad-slot="1132644618"
        data-ad-format="auto"
        data-full-width-responsive="true"
        data-adtest="on"
      ></ins> */}
      <AdSense.Google
      client="ca-pub-9762246811722235"
      slot="1132644618"
      style={{ width: "100%", height: 300, display:"flex",alignItems:"center",justifyContent:"center" }}
      
       />
    </div>
  );
};

export default CyberDogzAd;
