import React from "react";
import Styles from "./Cyberdogz.module.sass";

import cn from "classnames";

const Cyberdogz = () => {
  

  return (
    <>
    
  
      <div className={Styles.bg}>
        <div className={Styles.content}>
          <div className={Styles.welcome}>Welcome to Cyberdogz Page</div>
          <br />
          <div className={Styles.storypara}>
          Content Will be Soon

      </div>
      </div>
      </div>  
        </>
  );

};

export default Cyberdogz;


