import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Marketplace.module.sass";
import Card from "../../components/Card";
import Loader from "../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  getAssetType,
  getBreeds,
  getFactions,
  getPosts,
  getSalesType,
} from "../../redux";
import Filters from "../../components/Filters";

const Marketplace = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [assets, setAssets] = useState([]);
  const [lastKey, setLastkey] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  if (state.Posts.isFirstLoaded === false) {
    dispatch(getPosts(state.Posts.filter));
  }
  useEffect(() => {
    setAssets(state.Posts.posts);
  }, [state.Posts.posts]);
  useEffect(() => {
    dispatch(getBreeds());
    dispatch(getFactions());
    dispatch(getAssetType());
    dispatch(getSalesType());
  }, []);

  useEffect(() => {
    setLastkey(state.Posts.lastKey);
  }, [state.Posts.lastKey]);

  const filter = (params) => {
    let filter = state.Posts.filter;
    for (const key in params) {
      if (params[key] !== "0") {
        filter.map((data, index) => {
          for (const field in data) {
            if (field == key) {
              filter[index] = params;
            }
            if (field == "page") {
              filter[index] = { page: 1 };
            }
          }
        });
      }
    }

    dispatch(getPosts(filter));
  };

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <div className={styles.title}>
            <span className={styles.heading}>MARKETPLACE</span>
          </div>
          {(state.Posts.isFilterLoaded || state.Posts.postLoading) && (
            <Loader className={styles.loading} />
          )}
        </div>
        <div className={styles.row}>
          <Filters
            filter={filter}
          />
          <div className={styles.wrapper}>
            <div className={styles.list}>
              {assets.map((x, index) => (
                <Card className={styles.card} item={x} key={index} />
              ))}
            </div>
            {!state.Posts.postLoading && assets.length <= 0 && (
              <h2 className={styles.nopost}>No Assets Available</h2>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marketplace;
