import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "antd";
import styles from "../BreedingG1.module.sass";
import Shade from "../../../../images/stakeshade.png";
import { useWeb3React } from "@web3-react/core";
import {
  GetBreedProof,
  GetProof,
  GetUserAssets,
} from "../../../../services/ApiServices";
import ReactReadMoreReadLess from "react-read-more-read-less";
import Button from "../../../../components/Button";
import { ethers, providers, utils } from "ethers";
import BreedingMarket from "../../../../abi/BreedingMarket.json";
import {
  POLYGON_BREEDING_ADDRESS,
  POLYGON_NFT_ADDRESS,
  USDT_TOKEN_ADDRESS,
  MUMBAI_MATIC_BREEDING_ADDRESS,
  MUMBAI_USDT_TOKEN_ADDRESS,
} from "../../../../Config/config";
import Usdt from "../../../../images/usdt.png";
import { useDispatch, useSelector } from "react-redux";
import CyberdogzNFT from "../../../../abi/CyberdogzNFT.json";
import ERC20 from "../../../../abi/ERC20.json";
import { isMobile } from "react-device-detect";
import PrimaryButton from "../../../../components/PrimaryButton";

const BreedModal = ({ male, female }) => {
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const { account, active, activate, error, deactivate } = useWeb3React();
  const [dragData, setDragData] = useState({});
  const [errorMsg, setErrormsg] = useState("");
  const [txs, setTxs] = useState();
  const [isSuccess, setIsSuccess] = useState(false);
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [txHash, setTxHash] = useState("");
  const [user, setUser] = useState({});
  const [loadingtext, setLoadingText] = useState("");
  const [maleAssets, setMaleAssets] = useState([]);
  const [femaleAssets, setFemaleAssets] = useState([]);
  const dragItem = useRef();
  const dragOverItem = useRef();
  useEffect(() => {
    setUser(state.User.user);
    if (state.User.user) {
      setUser(state.User.user);
    }
  }, [state.User.user]);
  useEffect(() => {
    setMaleAssets(male);
    setFemaleAssets(female);
  }, [male, female]);
  const ApproveFunction = async () => {
    setIsLoading(true);
    setLoadingText("Checking");
    setIsSuccess(false);
    setTxHash("");
    setErrormsg("");
    let provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    let maleTokens = [],
      femaleTokens = [];
    let isApproval;
    let createApproval;
    let breedingFee;
    let usdtBalance;
    let maleProof,
      femaleProof,
      maleProofs = [],
      femaleProofs = [],
      isEligible,
      message,
      proof;
    maleAssets.map((data) => {
      maleTokens.push(data.tokenId);
    });
    femaleAssets.map((data) => {
      femaleTokens.push(data.tokenId);
    });
    try {
      let network = await provider.getNetwork();
      if (network.chainId !== 137) {
        setErrormsg("Please connect to Polygon Mainnet");
        return setIsLoading(false);
      }
      try {
        setIsLoading(true);
        setLoadingText("Checking");
        let Token = new ethers.Contract(USDT_TOKEN_ADDRESS, ERC20, signer);
        let breedingMarket = new ethers.Contract(
          POLYGON_BREEDING_ADDRESS,
          BreedingMarket.abi,
          signer
        );
        const malepromises = maleTokens.map(async (token) => {
          try {
            maleProof = await GetBreedProof(token, "male", user.token);
          } catch (e) {
            setIsLoading(false);
            return setErrormsg(e.message);
          }
          if (!maleProof.success) {
            setIsLoading(false);
            return setErrormsg(maleProof.error);
          }
          maleProofs.push(maleProof.proof);
        });
        await Promise.all(malepromises);
        const femalepromises = femaleTokens.map(async (token) => {
          try {
            femaleProof = await GetBreedProof(token, "female", user.token);
          } catch (e) {
            setIsLoading(false);
            return setErrormsg(e.message);
          }
          if (!femaleProof.success) {
            setIsLoading(false);
            return setErrormsg(femaleProof.error);
          }

          femaleProofs.push(femaleProof.proof);
        });
        await Promise.all(femalepromises);

        // console.log(maleTokens,"-",femaleTokens,"-",maleProofs,"-", femaleProofs);
        let eligibility;
        try {
          eligibility = await breedingMarket.isBreedable(
            account,
            maleTokens,
            femaleTokens,
            maleProofs,
            femaleProofs
          );

          isEligible = eligibility.toString().split(",")[0];
          message = eligibility.toString().split(",")[1];
          if (isEligible === "false" || isEligible === false) {
            setIsLoading(false);
            return setErrormsg(message);
          }
          try {
            breedingFee = await breedingMarket.breedingFee();
            try {
              usdtBalance = await Token.balanceOf(account);
              // console.log(usdtBalance.toString(),breedingFee.toString());
              if (
                parseInt(usdtBalance) <
                parseInt(breedingFee * maleTokens.length)
              ) {
                setIsLoading(false);
                return setErrormsg("Not enough USDT balance available");
              }
              try {
                setLoadingText("Approving");
                setIsLoading(true);
                isApproval = await Token.allowance(
                  account,
                  POLYGON_BREEDING_ADDRESS
                );
                if (
                  parseFloat(isApproval) >=
                  parseFloat(breedingFee * maleTokens.length)
                ) {
                  breed(maleTokens, femaleTokens, maleProofs, femaleProofs);
                } else {
                  try {
                    createApproval = await Token.approve(
                      POLYGON_BREEDING_ADDRESS,
                      breedingFee * maleTokens.length
                    );

                    let buyReceipt;
                    try {
                      buyReceipt = await createApproval.wait();
                      if (buyReceipt.status == 1) {
                        breed(
                          maleTokens,
                          femaleTokens,
                          maleProofs,
                          femaleProofs
                        );
                      }
                    } catch (error) {
                      setIsLoading(false);
                      if (error.message) {
                        let message = error.message.split(":");
                        console.log(" approve exception", message);
                        return setErrormsg(
                          "Unknown Error occured please contact support!"
                        );
                      }
                    }
                  } catch (e) {
                    let message = e.message.split(":");
                    setIsLoading(false);
                    console.log(" approve exception", message);
                    return setErrormsg(
                      message[0] === "MetaMask Tx Signature"
                        ? message[1]
                        : message
                    );
                  }
                }
              } catch (e) {
                let message = e.message.split(":");
                setIsLoading(false);
                console.log("Breeding Fee", message);
                return setErrormsg("Breeding fee Error!");
              }
            } catch (e) {
              let message = e.message.split(":");
              setIsLoading(false);
              console.log("Buy approve exception", message);
              return setErrormsg(
                "Unknown Error occured please contact support!"
              );
            }
          } catch (e) {
            let message = e.message.split(":");
            setIsLoading(false);
            console.log("Buy approve exception", message);
            return setErrormsg("Unknown Error occured please contact support!");
          }
        } catch (error) {
          setIsLoading(false);
          console.log("Checking error from Eligibility...", error);
          let message = error.message.split(":");
          return setErrormsg(
            message[0] === "MetaMask Tx Signature" ? message[1] : message
          );
        }
      } catch (error) {
        let message = error.message.split(":");
        setIsLoading(false);
        console.log("Checking error from Approve...", message);
        return setErrormsg("Unknown Error occured please contact support!");
      }
    } catch (error) {
      setIsLoading(false);
      let message = error.message.split(":");
      console.log("Checking error from Approve...", message);
      return setErrormsg("Unknown Error occured please contact support!");
    }
  };

  const breed = async (maleTokens, femaleTokens, maleProofs, femaleProofs) => {
    setErrormsg("");
    setLoadingText("Breeding");
    setIsLoading(true);
    setIsSuccess(false);
    setTxHash("");
    try {
      if (!window.ethereum) throw new Error("Metamask is not found.");
      try {
        let provider = new ethers.providers.Web3Provider(window.ethereum);

        let value = 0;

        let network = await provider.getNetwork();
        if (network.chainId !== 137) {
          setErrormsg("Please connect to Polygon Mainnet");
          return setIsLoading(false);
        }
        let signer = provider.getSigner();
        value = await provider.getBalance(account, "latest");
        let displayBalance = utils.formatUnits(
          utils.parseUnits(value.toString(), "wei"),
          "ether"
        );
        console.log("Inside Breeding market...");
        let breedingMarket = new ethers.Contract(
          POLYGON_BREEDING_ADDRESS,
          BreedingMarket.abi,
          signer
        );

        try {
          if (parseFloat(displayBalance) < 0.01) {
            setIsLoading(false);
            return setErrormsg("Insufficient Balance to complete the purchase");
          } else {
            let estimatedGasLimit;
            let estimatedGasPrice;
            try {
              estimatedGasLimit = await breedingMarket.estimateGas.breedToken(
                maleTokens,
                femaleTokens,
                maleProofs,
                femaleProofs
              );
            } catch (e) {
              setIsLoading(false);
              console.log("Error estimatedGasLimit: ", e);
              let message = e.message.split(":");
              return setErrormsg("Error in Estimated Gas Limit");
            }
            try {
              estimatedGasPrice = await provider.getGasPrice();
            } catch (error) {
              let message = error.message.split(":");
              setIsLoading(false);
              return setErrormsg(
                message[0] === "MetaMask Tx Signature" ? message[1] : message
              );
            }
            // utils.formatUnits(
            //   utils.parseUnits('3', 'gwei'),'wei')
            // console.log("gasPrice", estimatedGasPrice.toString());
            // console.log("gasLimit:", estimatedGasLimit.toString());

            let buyTx;
            try {
              buyTx = await breedingMarket.breedToken(
                maleTokens,
                femaleTokens,
                maleProofs,
                femaleProofs
              );
              setTxHash(buyTx.hash);
            } catch (error) {
              if (error.message) {
                let message = error.message.split(":");
                setIsLoading(false);
                return setErrormsg(
                  message[0] === "MetaMask Tx Signature" ? message[1] : message
                );
              }
              console.log("Erron on buy token...", error);
              setIsLoading(false);
              return setErrormsg("Transaction failed");
            }
            let buyReceipt;

            try {
              buyReceipt = await buyTx.wait();
            } catch (error) {
              if (error.message) {
                let message = error.message.split(":");
                setIsLoading(false);
                return setErrormsg(
                  message[0] === "MetaMask Tx Signature" ? message[1] : message
                );
              }
              console.log("Erron on wait ...", error);
              setIsLoading(false);
              return setErrormsg("Transaction failed");
            }

            if (buyReceipt.status) {
              setIsLoading(false);
              setIsSuccess(true);
            }
            return;
          }
        } catch (error) {
          setIsLoading(false);
          console.log("Checking error from token price...", error);
          let message = error.message.split(":");
          return setErrormsg(
            message[0] === "MetaMask Tx Signature" ? message[1] : message
          );
        }
      } catch (error) {
        setIsLoading(false);
        return setErrormsg(error.message);
      }
    } catch (err) {
      setIsLoading(false);
      return setErrormsg(err.message);
    }
  };
  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = femaleAssets;
    const dragItemContent = copyListItems[dragItem.current];

    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setFemaleAssets([...copyListItems]);
  };
  return (
    <div>
      <center className={styles.heading}>Mass Breeding</center>
      <br />
      <Row>
        <Col span={8}>
          <div className={styles.gender}> MALE</div>
          {male.map((data) => (
            <>
              <div className={styles.imageDiv}>
                <img className={styles.assetImage} src={data.previewImage} />
              </div>
              <div className={styles.title}>{data.name}</div>
            </>
          ))}
        </Col>
        <Col span={8}></Col>
        <Col span={8}>
          <div className={styles.gender}>FEMALE</div>
          {femaleAssets.map((data, index) => (
            <>
              <div
                className={styles.imageDiv}
                // onDragStart={(e) => dragStart(e, index)}
                // onDragEnter={(e) => dragEnter(e, index)}
                // onDragEnd={drop}
                // key={index}
                // draggable
              >
                <img
                  className={styles.assetImage}
                  src={data.previewImage}
                  // draggable
                />
                {/* <div className={styles.whiteText}>#{female.tokenId}</div> */}
              </div>
              <div className={styles.title}>{data.name}</div>
            </>
          ))}
        </Col>
        <Col span={24}>
          <div className={styles.breedingFee}>
            Breeding Fee:
            <span className={styles.amount}>
              {(maleAssets.length * 25).toFixed(2)} USDT
            </span>
            <span style={{ color: "#00A2FD" }}>(Polygon)</span>
          </div>
          <div className={styles.pricemob}>
            Breeding Fee
            <br />
            <span className={styles.amount}>
              {(maleAssets.length * 25).toFixed(2)} USDT
            </span>
            <span style={{ color: "#00A2FD" }}>(Polygon)</span>
          </div>
          {errorMsg && <div className={styles.error}>{errorMsg}</div>}
          <div className={styles.breedButton}>
            <PrimaryButton
              onClick={ApproveFunction}
              label="Breed Now"
              loading={isLoading}
              loadingtext={loadingtext}
            />
          </div>
          {isSuccess && (
            <div className={styles.success}>
              Breeding submitted successfully.
            </div>
          )}
          {txHash && (
            <div className={styles.hash}>
              Transaction submitted.{" "}
              <a href={"https://polygonscan.com/tx/" + txHash} target="_blank">
                View in explorer
              </a>
            </div>
          )}
          <div className={styles.importantNote}>
            <span>Important Notes</span>
            {/* <li>Change pair: Drag the female asset drop near the male asset.</li> */}

            <li>Before breeding you must approve to spend USDT.</li>
            {/* <li>Female asset will not available for 24hours after breeding. </li> */}
            <li> It takes 24 hours to complete breeding.</li>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default BreedModal;
