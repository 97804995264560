import React, { useEffect, useState } from "react";
import Styles from "../TransactionHistories.module.sass";
import { Row, Col } from "antd";
import HistoryShade from "../../../images/historyshade.png";
import {
  GetKibblezHistory,
  GetUserAssets,
} from "../../../services/ApiServices";
import cn from "classnames";
import moment from "moment";
import Loader from "../../Loader";
import { MdOpenInNew } from "react-icons/md";
import { minifyAddress } from "../../../utils/helpers";
import TableLoader from "../../TableLoader";

const ERC20Table = ({ ERC_20Histoires, isERC_20Loading }) => {
  return (
    <div className={Styles.history_table}>
      <div className={Styles.rowdata}>
        <Row>
          <Col span={5}>
            <div className={Styles.seller}>Date and Time</div>
          </Col>
          <Col span={5}>
            <div className={Styles.seller}>$BONEZ Withdrawn</div>
          </Col>
          <Col span={4}>
            <div className={Styles.seller}>Fee</div>
          </Col>
          <Col span={5}>
            <div className={Styles.seller}>Transaction Hash</div>
          </Col>
          <Col span={5}>
            <div className={Styles.seller}>Status</div>
          </Col>
        </Row>
      </div>
      {isERC_20Loading ? (
        <TableLoader />
      ) : ERC_20Histoires.length ? (
        <>
          {ERC_20Histoires.map((data, index) => {
            return (
              <Row className={Styles.rowdatabg} key={index}>
                <Col span={5}>
                  <div className={Styles.pricefield}>
                    {moment(data.timestamp * 1000)
                      .utc()
                      .format("DD-MM-YYYY hh:mm A")}{" "}
                    UTC
                  </div>
                </Col>

                <Col span={5}>
                  <div className={Styles.price}>
                    {Intl.NumberFormat("en-US").format(data.claimedAmount)}
                  </div>
                </Col>
                <Col span={4}>
                  <div className={Styles.pricefield}>
                    {Intl.NumberFormat("en-US").format(data.withdrawFee)}
                  </div>
                </Col>
                <Col span={5}>
                  {data.txHash ? (
                    <div className={Styles.hash}>
                      <a
                        href={"https://polygonscan.com/tx/" + data.txHash}
                        target="_blank"
                      >
                        {data.txHash ? minifyAddress(data.txHash, 10) : "-"}
                      </a>
                    </div>
                  ) : (
                    <div className={Styles.pricefield}>-</div>
                  )}
                </Col>
                <Col span={5}>
                  <div className= {data.status == "QUEUED"
                      ? Styles.progress
                      : data.status == "ERRORED"
                      ? Styles.sent
                      : data.status == "COMPLETE"
                      ? Styles.status
                      : Styles.status}>

                    {data.status == "QUEUED"
                      ? "WAITING"
                      : data.status == "ERRORED"
                      ? "FAILED"
                      : data.status == "COMPLETE"
                      ? "WITHDRAWN"
                      : data.status}
                  </div>
                </Col>
              </Row>
            );
          })}
        </>
      ) : (
        <p className={Styles.empty}>No data available</p>
      )}
    </div>
  );
};
export default ERC20Table;
