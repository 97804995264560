import React,{useState,useEffect} from "react"
import { Row, Col } from "antd";
import Styles from "../../screens/Item/Item.module.sass"

function ItemDetails (props){

    const [detailsAccordian,setDetailsAccordian] = useState(true)
    const [asset,setAsset] = useState()

    useEffect(()=>{
        setAsset(props.asset)
    },[props.asset])

    return (
      <><div className={Styles.accordianHead}>Details</div>
    <div className={Styles.detailsAccordian}>
      {/* <div className={Styles.accordian}>
        <div className={Styles.accordianHead}>Details</div>
        {detailsAccordian ? (
          <button
            className={Styles.accordianImgMinus}
            onClick={() => setDetailsAccordian(false)}
          ></button>
        ) : (
          <button
            className={Styles.accordianImgPlus}
            onClick={() => setDetailsAccordian(true)}
          ></button>
        )}
      </div> */}
      {detailsAccordian ? (
        <div className={Styles.detailCard}>
          <Row>
            <Col span={8}>
              <p className={Styles.detailField}>Contract Address</p>
            </Col>
            <Col span={16}>
              <p className={Styles.detailValue}>
                <a
                  target="_blank"
                  href={asset?.chainId==1?
                    "https://etherscan.io/address/" +
                    asset?.contractAddress:
                    "https://polygonscan.com/address/" +
                    asset?.contractAddress
                  }
                >
                  {asset?.contractAddress}
                </a>
              </p>
            </Col>
          </Row>


          {/* <Row>
            <Col span={8}>
              <p className={Styles.detailField}>
                Contract Standard
              </p>
            </Col>
            <Col span={16}>
              <p className={Styles.detailValue}>
                {asset?.assetType}
              </p>
            </Col>
          </Row> */}
          <Row>
            <Col span={8}>
              <p className={Styles.detailField}>Blockchain</p>
            </Col>
            <Col span={16}>
              <p className={Styles.detailValue}>
                {asset?.chainName}
              </p>
            </Col>
          </Row>
        </div>
      ) : null}
    </div>
    </>
    )
}

export default ItemDetails