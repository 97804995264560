import React from "react";
import Hero5 from "./Hero5";
import HotBid from "../../components/HotBid";

const MyAccount = () => {
  return (
    <>
      <Hero5 />
      <HotBid classSection="section-pb" />
    </>
  );
};

export default MyAccount;
