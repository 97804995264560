import React, { useEffect, useRef, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import styles from "./Cyberdogz.module.sass";
import { TfiTwitterAlt } from "react-icons/tfi";
import { SiDiscord } from "react-icons/si";
import { AiFillInstagram } from "react-icons/ai";
import { Col, Row } from "antd";
import CyberdogzImg from "../../../images/cyberdogz.jpg";
import CyberdogzVideo from "../../../images/CYBERDOGZ.mp4";
import ReactPlayer from "react-player";
import { FaPause, FaPlay } from "react-icons/fa";
import { RiPauseCircleLine, RiPlayCircleLine } from "react-icons/ri";
import { HiVolumeOff, HiVolumeUp } from "react-icons/hi";
import { BsPauseCircle, BsPlayCircle } from "react-icons/bs";
const Cyberdogz = (props) => {
  const [play, setPlay] = useState(false);
  const [mute, setmute] = useState(false);
  const [ready, setReady] = useState(false);
  return (
    <div className={styles.cyberdogz}>
      <Row>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className={styles.container}>
            <div>
              <div className={styles.heading}>Join CYBERDOGZ</div>
              <div className={styles.text}>
                CYBERDOGZ is a multi-player economics-themed strategy game
                played across several mini-games. In the game you breed, race
                and earn $BONEZ. Climb the ranks and Win the game once you own
                the “Alpha CYBERDOGZ”!
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          
          {/* <video
            className={styles.cyberdogzImg}
            autoPlay={false}
            src={CyberdogzVideo}
            controls
            controlsList="nodownload"
            type="video/mp4"
          /> */}
          {/* {!ready ?
          <img src={CyberdogzImg} className={styles.cyberdogzImg} /> :
           */}
          <div className={styles.videoContainer}>
          <div className={styles.play}>
            {!play ? (
              <BsPlayCircle
                className={styles.icon}
                onClick={() => {
                  setPlay(!play);
                }}
              />
            ) : (
              <BsPauseCircle
                onClick={() => {
                  setPlay(!play);
                }}
                className={styles.icon}
              />
            )}
          </div>
          <div className={styles.muteContainer}>
            {!mute ? (
              <HiVolumeUp
                className={styles.mute}
                onClick={() => {
                  setmute(!mute);
                }}
              />
            ) : (
              <HiVolumeOff
                onClick={() => {
                  setmute(!mute);
                }}
                className={styles.mute}
              />
            )}
          </div>
          <ReactPlayer
            url={CyberdogzVideo}
            height="600px"
            width="auto"
            // light={CyberdogzImg}
            volume={1}
            muted={mute}
            playing={play}
            onReady={() => setReady(true)}
            style={{
              display: "flex",
              margin: "0 auto",
              width: "100%",
              justifyContent: "center",
            }}
            playIcon={<BsPlayCircle style={{ display: "none" }} />}
          />
        </div>
        {/* } */}
       
        </Col>
      </Row>
    </div>
  );
};
export default Cyberdogz;
