import React, { useState, useEffect } from "react";
import VerifyLoading from "../../json/verifyLoading.json";
import Success from "../../json/Success.json";
import Failed from "../../json/Failed.json";
import LottieLoader from "react-lottie-loader";
import classNames from "classnames";
import styles from "./Verify.module.sass";
import { ResendMail, VerifyEmailAddress } from "../../services/ApiServices";
import { useParams, Link } from "react-router-dom";
import Shade from "../../images/shade.png";
import { Spinner } from "react-bootstrap";
import { useWeb3React } from "@web3-react/core";
function VerifyEmail() {
  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [reSend, setResend] = useState(false);
  const [reSendError, setResendError] = useState("");
  const [reSendLoading, setResendLoading] = useState(false);
  const { id } = useParams();
  const { account, active, activate, error, deactivate } = useWeb3React();

  // const params = new URLSearchParams(window.location.search);
  // const id = params.get("id");
  useEffect(() => {
    if (id && account) verifyEmail();
  }, [id, account]);

  const verifyEmail = async () => {
    setIsLoading(true);
    let result;
    let body = {
      address: account,
      id: id,
    };
    try {
      result = await VerifyEmailAddress(body);
    } catch (e) {
      console.log(e);
      setResendError(e.message);
      setFailed(true);
      return setIsLoading(false);
    }
    if (result.success) {
      setSuccess(true);
      setIsLoading(false);
    }
  };
  // const resend = async () => {
  //   setResendLoading(true);
  //   let result;
  //   try {
  //     result = await ResendMail(address);
  //   } catch (e) {
  //     console.log(e);
  //     setResendError(e.message);
  //     setResendLoading(false);
  //     return setResendLoading(false);
  //   }
  //   if (result.success) {
  //     setResendLoading(false);
  //     setResend(true);
  //   }
  // };
  return (
    <div className={classNames("container", styles.verify)}>
      <div className={styles.title}>
        CYBERDOGZ <span className={styles.subtitle}>MAIL</span>
      </div>
      <img src={Shade} className={styles.shade} />
      {!active ? (
        <div className={styles.connect}>Connect Your wallet to continue...</div>
      ) : isLoading ? (
        <>
          <div className={styles.loader}>
            <LottieLoader animationData={VerifyLoading} />
          </div>
          <div className={styles.loading}>Verifying...</div>
        </>
      ) : success ? (
        <>
          {" "}
          <div className={styles.success}>
            <LottieLoader animationData={Success} />
          </div>
          <div className={styles.successText}>Verified Successfully!</div>
          <Link to={"/lottery"} className={styles.link}>
            Explore Lottery
          </Link>
        </>
      ) : (
        <>
          {" "}
          <div className={styles.failed}>
            <LottieLoader animationData={Failed} />
          </div>
          <div className={styles.failedText}>Verification Failed!</div>
          {reSend && <div className={styles.info}>Please Check your mail</div>}
          {reSendError && <div className={styles.error}>{reSendError}</div>}
          {/* {reSendError == "Already verified." ? null : reSendLoading ? (
            <button className={styles.link}>
              Resend{" "}
              {reSendLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  style={{ marginLeft: "8px" }}
                  size="sm"
                  className={styles.spinner}
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          ) : (
            <button onClick={resend} className={styles.link}>
              Resend
            </button>
          )} */}
        </>
      )}
    </div>
  );
}

export default VerifyEmail;
