import {
  GETUSER,
  GETUSERS,
  CREATEUSER,
  REMOVEUSER,
} from "./UserTypes";

const initialState = {
  user: { id: "" },
  users: [],
  userLoading: true,
  usersLoading: true,
  createUser: true,
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETUSER:
      return { ...state, user: action.payload, userLoading: false };
    case GETUSER:
      return { ...state, user: action.payload, userLoading: false };
    case GETUSERS:
      return { ...state, users: action.payload, usersLoading: false };
    case CREATEUSER:
      return { ...state, user: action.payload, createUser: false };

    case REMOVEUSER:
      return {
        user: {},
        users: [],
        userLoading: true,
        usersLoading: true,
      };

    default:
      return state;
  }
};

export default UserReducer;
