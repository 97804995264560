import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Claim.module.sass";
import TextInput from "../../components/TransparentTextInput";
import {
  verifyBuyerAddress,
  createBuyerUser,
  checkWhiteList,
} from "../../services/ApiServices";
import { ethers, utils } from "ethers";
import { Link, useParams } from "react-router-dom";
import { GetUSD } from "../../services/ApiServices";
import Modal from "../../components/Modal";
import TextArea from "../../components/TextArea";
import { Row, Col } from "antd";
import LandMarket from "../../abi/LandMarket.json";
import CapsuleMarket from "../../abi/CapsuleOpener.json";
import AssetMarket from "../../abi/Assets.json";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CreateUser, getUser } from "../../redux";
import NumberFormat from "react-number-format";
import CyberdogzNFT from "../../abi/CyberdogzNFT.json";
import { isMobile } from "react-device-detect";
import { Alert } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import frozenCapsule from "../../abi/capsule.json";
import { IoIosCloseCircle, IoMdCloseCircleOutline } from "react-icons/io";
import { SlClose } from "react-icons/sl";
import PrimaryButton from "../../components/PrimaryButton";
import TransparentButton from "../../components/TransparentButton";
const Claim = (props, { className }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [address, setAddress] = useState();
  const [isSuccess, setIsSuccess] = useState(false);
  const [errormsg, setErrormsg] = useState();
  const [eth, setEth] = useState(1);
  const [txHash, setTxHash] = useState("");
  const [value, setValue] = useState("");
  const [isSelectWallet, setIsSelectWallet] = useState(false);
  const [btn_txt, setBtn_txt] = useState("MINT NOW");
  const [isLoading, setIsLoading] = useState(false);
  const [isWalletAvailable, setIswalletAvailable] = useState(true);
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [getUserLoading, setGetUserLoading] = useState(false);
  const [quantity, setQuantity] = useState(0);

  const { id } = useParams();

  const andromedaAddress = "0x474ABA21846bF2862946c63bDfF6e479D03455a2";
  const contractAddress = "0x6C9CbD0bE01768F11865DAfaF41a10CE40Fbb7e3";
  const capsuleMarketAddress = "0x870b7d8c9E1157f3aED10331E608CA428C1Ff071";
  const CyberdogzNFTAddress = "0x459124c1320BB66a2D2e58E04E7D28C344631Ded";
  const frozenCapsuleAddress = "0x459124c1320BB66a2D2e58E04E7D28C344631Ded";

  useEffect(() => {
    let address = localStorage.getItem("nftAddress");
    // wallet(true)
    if (address) {
      setAddress(address);
    }
  }, []);
  useEffect(() => {
    setValue(state.Usd.USD);
  }, []);

  const handleQuantity = (value) => {
    if (value <= props.quantity) {
      setErrormsg("");
      setEth(value);
    } else {
      setEth(value);
      setErrormsg("Entered qunatity exceeds your balance");
    }
  };

  const wallet = async () => {
    let meta;
    try {
      if (!window.ethereum) {
        setIswalletAvailable(false);
        throw new Error("Metamask is not found.");
      }

      meta = await window.ethereum.send("eth_requestAccounts");
    } catch (err) {
      setIsSelectWallet(false);
      return setErrormsg(err.message);
    }

    setAddress(meta.result[0]);
    let user;
    console.log(state.User.user._id);
    if (state.User.user._id !== "") {
      dispatch(getUser(meta.result[0]));
      setAddress(state.User.user.walletAddress);
      purchase();
    } else if (state.User.user._id == "" && state.User.userLoading) {
      dispatch(getUser(meta.result[0]));
      setGetUserLoading(true);
    }
    setErrormsg("");

    // if(!initial) purchase();
  };
  const purchase = async () => {
    setErrormsg("");
    setIsLoading(true);
    setBtn_txt("MINTING NOW");
    try {
      if (!window.ethereum) throw new Error("Metamask is not found.");

      let user, meta;
      if (address) {
        setAddress(address);
      } else {
        meta = await window.ethereum.send("eth_requestAccounts");
        setAddress(meta.result[0]);
      }

      try {
        let provider = new ethers.providers.Web3Provider(window.ethereum);
        let signer = provider.getSigner();
        let address = await signer.getAddress();
        setAddress(address);
        ethers.utils.getAddress(address);
        let token, currentPrice;
        console.log("Inside capsule market...");
        let capsuleMarket = new ethers.Contract(
          capsuleMarketAddress,
          CapsuleMarket.abi,
          signer
        );
        try {
          token = await capsuleMarket.openCapsule(eth);
          // console.log(token.toString());
          // console.log(token);
          let buyTx;
          // try {
          //   buyTx = await capsuleMarket.buyToken(
          //     tokenId,
          //     amountToBuy,
          //     overrides
          //   );
          //   setTxHash(buyTx.hash);
          // } catch (error) {
          //   if (error.message) {
          //     // setIsLoading(false)
          //     let message = error.message.split(":");
          //     setIsLoading(false);
          //     setBtn_txt("Claim NOW");
          //     return setErrormsg(
          //       message[0] === "MetaMask Tx Signature"
          //         ? message[1]
          //         : message
          //     );
          //   }
          //   console.log("Erron on buy token...", error);
          //   setIsLoading(false);
          //   setBtn_txt("Claim NOW");
          //   return setErrormsg("Transaction failed");
          // }
          let buyReceipt;

          try {
            buyReceipt = await token.wait();
            console.log("buyReceipt", buyReceipt);
          } catch (error) {
            // setIsLoading(false)
            if (error.message) {
              let message = error.message.split(":");
              setIsLoading(false);
              setBtn_txt("MINT NOW");
              return setErrormsg(
                message[0] === "MetaMask Tx Signature" ? message[1] : message
              );
            }
            console.log("Erron on wait ...", error);
            setIsLoading(false);
            setBtn_txt("CLAIM NOW");
            return setErrormsg("Transaction failed");
          }
          console.log("buyReceipt:", buyReceipt.transactionHash);
          let ethUsed = utils.formatUnits(
            utils.parseUnits(buyReceipt.gasUsed.toString(), "gwei"),
            "ether"
          );

          // setIsLoading(false)
          console.log(`ethUsed: ${ethUsed} ETH`);

          if (buyReceipt.status) {
            // setIsSelectWallet(false)
            setIsLoading(false);
            setBtn_txt("MINT NOW");
            setIsSuccess(true);
            // setSuccesssMsg(`https://etherscan.io/tx/${buyReceipt.transactionHash}`)
            console.log(
              `Transaction receipt : https://etherscan.io/tx/${buyReceipt.transactionHash}`
            );
          }

          // console.log({ eth, address });
          // console.log("tx", tx);
          return setTxHash(buyReceipt.transactionHash);
          // props.onClose();
        } catch (error) {
          setIsLoading(false);
          let message = error.message.split(":");
          setIsLoading(false);
          setBtn_txt("MINT NOW");
          return setErrormsg(
            message[0] === "MetaMask Tx Signature" ? message[1] : message
          );
          // setBtn_txt("Mint NOW");
          // return console.log("Checking error from token ...", error);
        }
      } catch (error) {
        setIsLoading(false);
        setBtn_txt("MINT NOW");
        // setIsDeleverd(true);
        setErrormsg(error.message);
      }
    } catch (err) {
      setIsLoading(false);
      setBtn_txt("MINT NOW");
      return setErrormsg(err.message);
    }
  };
  useEffect(() => {
    getBalance();
  }, [props.uid]);
  const ApproveFunction = async () => {
    let provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    try {
      let network = await provider.getNetwork();
      if (network.name !== "homestead") {
        setErrormsg("Please connect to Ethereum Mainnet");
        setIsLoading(false);
        return setBtn_txt("MINT NOW");
      }
      try {
        let FrozenCapsule = new ethers.Contract(
          frozenCapsuleAddress,
          frozenCapsule.abi,
          signer
        );
        let supplyAfter = await FrozenCapsule.balanceOf(props.uid, "1");

        console.log(parseFloat(supplyAfter.toString()));
        setQuantity(parseFloat(supplyAfter.toString()));
        if (parseFloat(supplyAfter.toString()) >= eth) {
          setIsLoading(true);
          setBtn_txt("APPROVING");
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          let Token = new ethers.Contract(
            CyberdogzNFTAddress,
            CyberdogzNFT.abi,
            signer
          );
          let isApproval;
          let createApproval;
          try {
            isApproval = await Token.isApprovedForAll(
              props.uid,
              capsuleMarketAddress
            );
            if (isApproval) {
              purchase();
            } else {
              try {
                createApproval = await Token.setApprovalForAll(
                  capsuleMarketAddress,
                  true
                );
                let createAReceipt = await createApproval.wait();
                purchase();
              } catch (e) {
                setIsLoading(false);
                let message = e.message.split(":");
                setIsLoading(false);
                setBtn_txt("MINT NOW");
                console.log("Mint approve exception", e);
                return setErrormsg(
                  message[0] === "MetaMask Tx Signature" ? message[1] : message
                );
              }
            }
          } catch (e) {
            setIsLoading(false);
            let message = e.message.split(":");
            setIsLoading(false);
            setBtn_txt("MINT NOW");
            console.log("Mint approve exception", e);
            return setErrormsg(
              message[0] === "MetaMask Tx Signature" ? message[1] : message
            );
          }
        } else {
          setIsLoading(false);
          setErrormsg("Entered qunatity exceeds your balance");
          return setBtn_txt("MINT Now");
        }
      } catch (error) {
        setIsLoading(false);
        let message = error.message.split(":");
        setIsLoading(false);
        setBtn_txt("MINT NOW");
        console.log("Checking error from token price...", error);
        return setErrormsg(
          message[0] === "MetaMask Tx Signature" ? message[1] : message
        );
      }
    } catch (error) {
      setIsLoading(false);
      let message = error.message.split(":");
      setIsLoading(false);
      setBtn_txt("MINT NOW");
      console.log("Checking error from token price...", error);
      return setErrormsg(
        message[0] === "MetaMask Tx Signature" ? message[1] : message
      );
    }
  };
  const getBalance = async () => {
    let provider = new ethers.providers.Web3Provider(window.ethereum);
    let network = await provider.getNetwork();
    if (network.name !== "homestead") {
      setErrormsg("Please connect to Ethereum Mainnet");
      setIsLoading(false);
      return setBtn_txt("MINT NOW");
    }
    const signer = provider.getSigner();
    let FrozenCapsule = new ethers.Contract(
      frozenCapsuleAddress,
      frozenCapsule.abi,
      signer
    );
    try {
      let supplyAfter = await FrozenCapsule.balanceOf(props.uid, "1");
      console.log(parseFloat(supplyAfter.toString()));
      setQuantity(parseFloat(supplyAfter.toString()));
    } catch (error) {
      setIsLoading(false);
      let message = error.message.split(":");
      setIsLoading(false);
      setBtn_txt("MINT NOW");
      console.log("Checking error from token price...", error);
      return setErrormsg("you don't won this capsule");
    }
  };

  return (
    <>
      <div className={cn(className, styles.connect)}>
        <h3 className={styles.title}>Thaw your {props.title}</h3>

        <div className={styles.item}>
          <img
            src="https://cyberdogz.s3.us-west-1.amazonaws.com/nft/capsule/capsule.png"
            alt="Card"
            className={styles.image}
          />
          <div className={styles.text}>
            <div className={styles.info}>
              <p className={styles.stock}>
                {/* ( */}{" "}
                <span className={styles.head}>Available Quantity</span> :{" "}
                <NumberFormat
                  value={quantity}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </p>
              <div>
                <TextInput
                  className={styles.field}
                  label="Select quantity to mint"
                  name="Item"
                  type="number"
                  value={eth}
                  onChange={(e) => handleQuantity(e.target.value)}
                  placeholder="Enter Quantity"
                  min="1"
                />
              </div>
              <div>
                <label className={cn(styles.note, "mt-5")}>
                  You are about to thaw your capsule.&nbsp;This change is
                  irreversible and you will unlock 5 random rare CYBERDOGZ for
                  each capsule in Polygon.
                </label>
              </div>
            </div>
          </div>
        </div>

        {errormsg && (
          <Alert
            description={errormsg}
            type="error"
            showIcon
            style={{
              margin: "20px auto 10px auto",
              width: "80%",
              borderRadius: "10px",
              border: "1px solid #ff4d4f",
              padding: "10px",
            }}
            icon={<IoIosCloseCircle style={{ color: "#ff4d4f" }} />}
          />
        )}

        {!isWalletAvailable && (
          <div className={styles.isWalletAvailable}>
            <div className={styles.info}>
              Opening Metamask App to complete the purchase{" "}
              {/* <a
                  target="_blank"
                  href={
                    "https://metamask.app.link/dapp/cyberdogz.io/detail/" +
                    props.item.id
                  }
                >
                  Click here!
                </a> */}
            </div>
          </div>
        )}

        {/* {isSuccess && (
            <div className={styles.success}>
              {" "}
              <div>You have bought the asset successfully.</div>
              <div><a href="https://opensea.io/assets/0x474ABA21846bF2862946c63bDfF6e479D03455a2/1" target="_blank" > click here to view on opensea market </a></div> 
            </div>
          )} */}

        <div className={styles.btns}>
          {btn_txt == "MINTING NOW" || btn_txt == "APPROVING" ? (
            <PrimaryButton label={btn_txt} isLoading={isLoading} />
          ) : (
            // <button className={cn("button", styles.button)}>
            //   {btn_txt}
            //   {isLoading && (
            //     <Spinner
            //       as="span"
            //       animation="border"
            //       style={{ marginLeft: "8px" }}
            //       size="sm"
            //       className={styles.spinner}
            //       role="status"
            //       aria-hidden="true"
            //     />
            //   )}
            // </button>
            <div className={styles.btns}>
              <TransparentButton
                label="CANCEL"
                onClick={() => {
                  props.onClose();
                }}
              />

              <PrimaryButton
                onClick={() => {
                  ApproveFunction();
                }}
                label={btn_txt}
                isLoading={isLoading}
              />
            </div>
          )}
        </div>
        <div className={styles.notes}>
          <span className={styles.notice}>important notice: </span>
          <li>
            You would approve the transaction before minting the new assets
          </li>
          <li>This process will take up to 30 minutes to complete</li>
        </div>
        {txHash && (
          <div className={styles.success}>
            <br />
            Minted Succesfully.{" "}
            <a href={"https://etherscan.io/tx/" + txHash} target="_blank">
              View in explorer
            </a>
          </div>
        )}
      </div>
    </>
  );
};

export default Claim;
