import React from "react";
import cn from "classnames";
import Styles from "./Loader.module.sass";
import { Col, Row } from "antd";

const TableLoader = ({ length,className, color }) => {
  return (
    <>
    {[...Array(length?length:5)].map((x, index) => (
    <div className={Styles.rowdatabgLoader} key={index}>
    
    <Row className={Styles.rowLoader}>
    {/* <Loader className={Styles.loader} /> */}
    <Col span={24}></Col>
    </Row>
    </div>
    ))}
    </>
  );
};

export default TableLoader;
