import React, { useEffect, useRef, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { GetUserAssets } from "../../../services/ApiServices";
import styles from "./BreedingG1.module.sass";
import cn from "classnames";
import ConfirmModal from "../../../components/ConfirmModal";
import Collection from "../Collection";
import BreedingHistory from "../History/BreedingHistory";
import BgImage from "../../../images/raceImgNew.png";
import Banner from "../../../images/breedingBAnner.png";
import DotsLeft from "../../../images/raceDots.png";
import DotsRight from "../../../images/raceDots1.png";
import PrimaryButton from "../../../components/PrimaryButton";
import { useNavigate } from "react-router-dom";
const BreedingG1 = ({ countdown4hr }) => {
  const [confirm, setConfirm] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <img src={Banner} className={styles.banner} />
          <div className={styles.topContainer}>
            <div className={styles.heading}>Breeding</div>
            <div className={styles.subHead}>
              Breed your cyberdog to increase your collection
            </div>
          </div>
        </div>
        <div className={styles.info}>
          <img src={DotsLeft} className={styles.dotsLeft} />
          <div className={styles.breedText}>
            To breed, you must have at least:
          </div>
          <div className={styles.information}>
            Mass Breeding Select more than two to activate mass breeding.
            <br />
            <span>“Price could change !”</span>
          </div>
          <img src={DotsRight} className={styles.dotsRight} />
        </div>
        <div className={styles.buttonContainer}>
          <PrimaryButton
            // className={styles.breed_btn2}
            onClick={() => {
              navigate("/marketplace");
            }}
            label="View Collection"
          />
        </div>
        <Collection />
      </div>
      <ConfirmModal
        title="Confirm Now"
        visible={confirm}
        onClose={() => {
          setConfirm(false);
        }}
        content="Are you sure you want to proceed with breeding?"
      />
      <BreedingHistory />
    </>
  );
};
export default BreedingG1;
