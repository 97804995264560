import React, { useState, useEffect } from "react";
import styles from "./tab.module.sass";
const Tab = ({ menu, value, onChange }) => {
  const handleClick = (value) => {
    onChange(value);
  };
  return (
    <div className={styles.tab}>
      {menu.map((data, index) => (
        <div key={index} onClick={() => handleClick(index)}>
          {" "}
          <span className={value == index ? styles.selectedMenu : styles.menu}>
            {data.icon && <>{data.icon}</>}
            {data.name}
          </span>
        </div>
      ))}
    </div>
  );
};
export default Tab;
