import React from "react";
import styles from "./Race.module.sass";
import { Carousel } from "react-responsive-carousel";
import Loader from "../../../components/Loader";
import Slider from "react-slick";

export function WinnerList({ leaderboardLoading, leaderboard, dateFunction }) {
  const settings = {
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    infinite: true,
    vertical: true,

    speed: 1500,
    autoplaySpeed: 1500,
    cssEase: "linear",
    pauseOnHover:false,
    pauseOnFocus:false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 5,
        },
      },
    ],
  };
  return (
    <>
      <div className={styles.winners}>
        <p className={styles.liveHead}>
          <span className={styles.yield}> Recent</span> Winners
        </p>
        {leaderboardLoading ? (
          <Loader className={styles.loader} />
        ) : leaderboard.length ? (
          <Slider {...settings}>
            {leaderboard.map((l, index) => {
              return (
                <div key={index} className={styles.whiteText}>
                  <li>
                    <b>
                      {l.userDogName} #{l.userDogId}
                    </b>{" "}
                    won{" "}
                    <span className={styles.blueText}>
                      {l.winAmount} $BONEZ{" "}
                    </span>
                    on {dateFunction(l.date, l.time)}
                  </li>
                </div>
              );
            })}
          </Slider>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
}
