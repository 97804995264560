import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Link, useParams } from "react-router-dom";
import Styles from "./LeaderBoard.module.sass";
import Icon from "../Icon";
import { Row, Col } from "antd";
import moment from "moment";
import HistoryShade from "../../images/historyshade.png";
import { minifyAddress } from "../../utils/helpers";
import { GetBonezLeaderboard, GetKibblezLeaderboard } from "../../services/ApiServices";
import Loader from "../Loader";

const LeaderBoard = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [kibblezLeaderboard, setKibblezLeaderboard] = useState([]);
  const [bonezLeaderboard, setBonezLeaderboard] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isBonezLoading, setBonezIsLoading] = useState(false);
  const [polygonAssets, setPolygonAssets] = useState([]);
  const [ethereumAssets, setEthereumAssets] = useState([]);
  const { uid } = useParams();
  useEffect(() => {
    getLeaderboard();
    getBonezLeaderboard()
  }, []);
  const getLeaderboard = async () => {
    setIsLoading(true);
    let result;
    try {
      result = await GetKibblezLeaderboard();
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
    try {
      if (result.success) {
        setIsLoading(false);
        setKibblezLeaderboard(result.result);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const getBonezLeaderboard = async () => {
    setBonezIsLoading(true);
    let result;
    try {
      result = await GetBonezLeaderboard();
    } catch (e) {
      console.log(e);
      setBonezIsLoading(false);
    }
    try {
      if (result.success) {
        setBonezIsLoading(false);
        setBonezLeaderboard(result.result);
      }
    } catch (e) {
      setBonezIsLoading(false);
      console.log(e);
    }
  };
  return (
    <div className={cn("container", Styles.container)}>
      <p className={Styles.title}>
        <span className={Styles.heading}>LEADERBOARD</span>
      </p>
      <Row>
        <Col md={12} xs={24}>
          <p className={Styles.KibblezTitle}>Kibblez</p>
          <div className={Styles.kibblezTable}>
            <div className={Styles.history_table}>
              <div className={Styles.rowdata}>
                <Row>
                  <Col span={4}>
                    <div className={Styles.seller}>Position</div>
                  </Col>
                  <Col span={10}>
                    <div className={Styles.seller}>Wallet Address</div>
                  </Col>
                  <Col span={10}>
                    <div className={Styles.seller}>Kibblez</div>
                  </Col>
                </Row>
              </div>
              {/* <img src={HistoryShade} className={Styles.history_shade} /> */}
              {isLoading ? (
                <Loader className={Styles.loader} />
              ) : kibblezLeaderboard.length ? (
                <>
                  {kibblezLeaderboard.map((data, index) => {
                    return (
                      <div
                        className={
                          Styles.rowdata
                        }
                        key={index}
                      >
                        <Row>
                          <Col span={4}>
                            <div className={Styles.pricefield}>{index + 1}</div>
                          </Col>
                          <Col span={10}>
                            <div className={Styles.hash}>
                              <Link to={"/account/" + data.address}>
                                {minifyAddress(data.address, 8)}
                              </Link>
                            </div>
                          </Col>
                          <Col span={10}>
                            <div className={Styles.pricefield}>
                              {Intl.NumberFormat("en-US").format(
                                data.kibblez
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p className={Styles.empty}>No data available</p>
              )}
            </div>
          </div>
        </Col>
        <Col md={12} xs={24}>
          <p className={Styles.KibblezTitle}>Bonez</p>
          <div className={Styles.bonezTable}>
            <div className={Styles.history_table}>
              <div className={Styles.rowdata}>
                <Row>
                  <Col span={4}>
                    <div className={Styles.seller}>Position</div>
                  </Col>
                  <Col span={10}>
                    <div className={Styles.seller}>Wallet Address</div>
                  </Col>
                  <Col span={10}>
                    <div className={Styles.seller}>BONEZ</div>
                  </Col>
                </Row>
              </div>
              {/* <img src={HistoryShade} className={Styles.history_shade} /> */}
              {isBonezLoading ? (
                <Loader className={Styles.loader} />
              ) : bonezLeaderboard.length ? (
                <>
                  {bonezLeaderboard.map((data, index) => {
                    return (
                      <div
                        className={
                          Styles.rowdata
                        }
                        key={index}
                      >
                        <Row>
                          <Col span={4}>
                            <div className={Styles.pricefield}>{index + 1}</div>
                          </Col>
                          <Col span={10}>
                            <div className={Styles.hash}>
                              <Link to={"/account/" + data._id}>
                                {minifyAddress(data._id, 8)}
                              </Link>
                            </div>
                          </Col>
                          <Col span={10}>
                            <div className={Styles.pricefield}>
                              {Intl.NumberFormat("en-US").format(data.total)}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p className={Styles.empty}>No data available</p>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LeaderBoard;
