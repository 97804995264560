import React, { useEffect, useState } from "react";
import Styles from "../Table.module.sass";
import { Row, Col } from "antd";
import HistoryShade from "../../../../images/historyshade.png";
import {
  GetKibblezHistory,
  GetUserAssets,
} from "../../../../services/ApiServices";
import cn from "classnames";
import moment from "moment";
import Loader from "../../../../components/Loader";
import Kibblez from "../../../../images/kibblez.png";
import Bonez from "../../../../images/bonez.png";
import CounDown from "../../../../components/CountDown";
import { minifyAddress } from "../../../../utils/helpers";
import { Link } from "react-router-dom";
import Modal from "../../../../components/NewModal";
import RaceModal from "../../SelectModal";
import Pagination from "../../../../components/Pagenation";

const history = [];
const time = [
  { label: "4 hours", value: 1 },
  { label: "12 hours", value: 2 },
  { label: "24 hours", value: 3 },
  { label: "Weekly", value: 4 },
  { label: "All time", value: 5 },
];
const colors = [
  "#32DA00",
  "#00D6DA",
  "#DA00B0",
  "#DAB800",
  "#DA1900",
  "#3A00FC",
  "#E7FF2E",
  "#38FFBA",
  "#FC209F",
  "#FF555C",
];
const UsdtTable = ({
  previousChallenge,
  isPreviousChallengeLoading,
  get24hrLeaderboard,
  countdown24hr,
}) => {
  const [assetSelect, setAssetSelect] = useState(false);
  const [selectedIdex, setSelectedIdex] = useState(0);
  const data = [
    {
      raceNo: "Race1",
      raceTime: "8:00AM",
      raceName: "Marathon",
      start: "12M : 42s ",
      fee: 1000,
      postition: 5,
      win: 100,
      join: true,
    },
    {
      raceNo: "Race1",
      raceTime: "8:00AM",
      raceName: "Marathon",
      start: "12M : 42s ",
      fee: 1000,
      postition: 5,
      win: 100,
    },
    {
      raceNo: "Race1",
      raceTime: "8:00AM",
      raceName: "Marathon",
      start: "12M : 42s ",
      fee: 1000,
      postition: 5,
      win: 100,
    },
    {
      raceNo: "Race1",
      raceTime: "8:00AM",
      raceName: "Marathon",
      start: "12M : 42s ",
      fee: 1000,
      postition: 5,
      win: 100,
    },
    {
      raceNo: "Race1",
      raceTime: "8:00AM",
      raceName: "Marathon",
      start: "12M : 42s ",
      fee: 1000,
      postition: 5,
      win: 100,
    },
    {
      raceNo: "Race1",
      raceTime: "8:00AM",
      raceName: "Marathon",
      start: "12M : 42s ",
      fee: 1000,
      postition: 5,
      win: 100,
    },
    {
      raceNo: "Race1",
      raceTime: "8:00AM",
      raceName: "Marathon",
      start: "12M : 42s ",
      fee: 1000,
      postition: 5,
      win: 100,
    },
    {
      raceNo: "Race1",
      raceTime: "8:00AM",
      raceName: "Marathon",
      start: "12M : 42s ",
      fee: 1000,
      postition: 5,
      win: 100,
    },
    {
      raceNo: "Race1",
      raceTime: "8:00AM",
      raceName: "Marathon",
      start: "12M : 42s ",
      fee: 1000,
      postition: 5,
      win: 100,
    },
    {
      raceNo: "Race1",
      raceTime: "8:00AM",
      raceName: "Marathon",
      start: "12M : 42s ",
      fee: 1000,
      postition: 5,
      win: 100,
    },
  ];
  return (
    <>
      <div className={Styles.history_table}>
        <div className={Styles.rowdata}>
          <Row>
            <Col span={1}></Col>
            <Col span={1}></Col>
            <Col span={4}>
              <div className={Styles.seller}>Race Details</div>
            </Col>
            <Col span={3}>
              <div className={Styles.seller}>Starts in</div>
            </Col>
            <Col span={3}>
              <div className={Styles.seller}>Entry fee</div>
            </Col>
            <Col span={3}>
              <div className={Styles.seller}>Positions</div>
            </Col>
            <Col span={3}>
              <div className={Styles.seller}>Win</div>
            </Col>
            <Col span={6}>
              <div className={Styles.seller}>Action</div>
            </Col>
          </Row>
        </div>
        {/* <img src={HistoryShade} className={Styles.history_shade} /> */}

        {isPreviousChallengeLoading ? (
          <Loader className={Styles.loader} />
        ) : data.length ? (
          <>
            {data.map((data, index) => {
              return (
                <div className={Styles.rowdatabg} key={index}>
                  <Row>
                    <Col span={1}>
                      <div
                        style={{
                          backgroundColor: colors[index],
                          boxShadow: colors[index] + " 0px 5px 15px",
                        }}
                        className={Styles.style}
                      ></div>
                    </Col>
                    <Col span={1}>
                      <div className={Styles.raceText}>
                        Race {index + 1}
                        <br />
                        {data.raceTime}
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className={Styles.status}>{data.raceName}</div>
                    </Col>
                    <Col span={3}>
                      <div className={Styles.pricefield}>{data.start}</div>
                    </Col>
                    <Col span={3}>
                      <div className={Styles.pricefield}>
                        <img className={Styles.icon} src={Kibblez} />
                        {data.fee}
                      </div>
                    </Col>
                    <Col span={3}>
                      <div className={Styles.pricefield}>
                        {data.postition}/15
                      </div>
                    </Col>
                    <Col span={3}>
                      <div className={Styles.pricefield}>
                        <img className={Styles.icon} src={Bonez} />
                        {data.win}
                      </div>
                    </Col>
                    <Col span={6}>
                      <Row>
                        <Col span={12}>
                          {data.join ? (
                            <button
                              className={Styles.viewButton}
                              onClick={() => setAssetSelect(true)}
                            >
                              Join Now
                            </button>
                          ) : (
                            <button className={Styles.viewButton}>
                              View Now
                            </button>
                          )}
                        </Col>
                        <Col span={12}>
                          <button className={Styles.betButton}>Side Bet</button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </>
        ) : (
          <p className={Styles.empty}>No data available</p>
        )}
        <div>
          <Pagination />
        </div>
      </div>
      <Modal onClose={() => setAssetSelect(false)} visible={assetSelect}
      outerClassName={Styles.modal}
      >
        <RaceModal />
      </Modal>
    </>
  );
};
export default UsdtTable;
