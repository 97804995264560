import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Disclaimer.module.sass";

const Disclaimer = () => {
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <h3 className={cn("h3", styles.title)}>DISCLAIMER</h3>
        <div className={styles.privacyBorder}>
          <div className={styles.grop}>
            <p>
              The site and the marketplace offerings are provided on an as-is and
              as-available basis. You agree that your use of the site and our
              services will be at your sole risk. To the fullest extent
              permitted by law, we disclaim all warranties, express or implied,
              in connection with the site and the marketplace offerings and your
              use thereof, including, without limitation, the implied warranties
              of merchantability, fitness for a particular purpose, and
              non-infringement. We make no warranties or representations about
              the accuracy or completeness of the site’s content or the content
              of any websites linked to the site and we will assume no liability
              or responsibility for any <br />
              <br />
              <span style={{color:"#ffffff",Text:"bold"}}> (1) </span>errors, mistakes, or inaccuracies of content and materials,
              <br /> <span style={{color:"#ffffff",Text:"bold"}}>(2)</span> personal injury or property damage, of any nature
              whatsoever, resulting from your access to and use of the site,{" "}
              <br /> <span style={{color:"#ffffff",Text:"bold"}}>(3)</span> any unauthorized access to or use of our secure servers
              and/or any and all personal information and/or financial
              information stored therein, <br /> <span style={{color:"#ffffff",Text:"bold"}}>(4)</span> any interruption or
              cessation of transmission to or from the site or the marketplace
              offerings,
              <br /> <span style={{color:"#ffffff",Text:"bold"}}>(5)</span> any bugs, viruses, trojan horses, or the like which may
              be transmitted to or through the site by any third party, and/or{" "}
              <br /> <span style={{color:"#ffffff",Text:"bold"}}>(6) </span>any errors or omissions in any content and materials or
              for any loss or damage of any kind incurred as a result of the use
              of any content posted, transmitted, or otherwise made available
              via the site.
              <br /> <br /> We do not warrant, endorse, guarantee, or assume
              responsibility for any product or service advertised or offered by
              a third party through the site, any hyperlinked website, or any
              website or mobile application featured in any banner or other
              advertising, and we will not be a party to or in any way be
              responsible for monitoring any transaction between you and any
              third-party providers of products or services. As with the
              purchase of a product or service through any medium or in any
              environment, you should use your best judgment and exercise
              caution where appropriate.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
