import React, { useEffect, useState, useRef } from "react";
import Dropdown from "../Dropdown";
import FiledsDropdown from "../FieldsDropdown";
import styles from "./Filter.module.sass";
import cn from "classnames";
import Icon from "../Icon";
import { Radio, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getPosts, resetFilter } from "../../redux";
import "./index.css";
import NewDropdown from "../NewDropdown";
import PrimaryButton from "../PrimaryButton";
const dateOptions1 = ["Defalt", "Newest", "Oldest"];
const dateOptions = [
  { displayName: "Default", _id: "" },
  { displayName: "Newest", _id: "desc" },
  { displayName: "Oldest", _id: "asc" },
];
const likesOptions = ["Most liked", "Least liked"];
const colorOptions = ["All colors", "Black", "Green", "Pink", "Purple"];
const statusOptions = [
  { displayName: "All", _id: "" },
  { displayName: "Live", _id: "LIVE" },
  { displayName: "Ended", _id: "ENDED" },
  { displayName: "Coming Soon", _id: "COMING_SOON" },
];
const genderOptions = [
  { displayName: "All", value: "" },
  { displayName: "Male", value: "MALE" },
  { displayName: "Female", value: "FEMALE" },
];
function Filters({ filter }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const didMount = useRef(false);
  const didMount1 = useRef(false);
  const didMount2 = useRef(false);
  const didMount3 = useRef(false);
  const didMount4 = useRef(false);
  const didMount5 = useRef(false);
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [date, setDate] = useState(
    state.Posts.filter[1].time == ""
      ? dateOptions[0]
      : state.Posts.filter[1].time == "asc"
      ? dateOptions[1]
      : dateOptions[2]
  );
  const [likes, setLikes] = useState(likesOptions[0]);
  const [color, setColor] = useState(colorOptions[0]);
  const [status, setStatus] = useState(statusOptions[0]);
  const [salesTypes, setSalesTypes] = useState({
    displayName: "All",
    _id: "0",
  });
  const [type, setType] = useState({ displayName: "All", _id: "0" });
  const [isLoading, setIsLoading] = useState(false);
  const [breed, setBreed] = useState({ displayName: "All", _id: "0" });
  const [faction, setFaction] = useState({ displayName: "All", _id: "0" });
  const [gender, setGender] = useState("");
  const [filters, setFilters] = useState({});
  const [visible, setVisible] = useState(deviceWidth > 767 ? true : false);
  const [breeds, setBreeds] = useState([{ displayName: "All", _id: "" }]);

  const [factions, setFactions] = useState([{ displayName: "All", _id: "" }]);
  const [assetType, setAssetType] = useState([{ displayName: "All", _id: "" }]);
  const [salesType, setsalesType] = useState([{ displayName: "All", _id: "" }]);

  const onChangeGenderHandler = (value) => {
    filter({ gender: value });
    setGender(value);
  };
  useEffect(() => {
    state.Posts.filter.map((data) => {
      for (const [key, value] of Object.entries(data)) {
        if (value) {
          switch (key) {
            case "assetType":
              let typeArray = state.Posts.assetType.find((element) => {
                return element._id === value;
              });
              setType(typeArray);
              break;
            case "saleType":
              let salesArray = state.Posts.salesType.find((element) => {
                return element._id === value;
              });
              setSalesTypes(salesArray);
              break;
            case "breed":
              let breedArray = state.Posts.breeds.find((element) => {
                return element._id === value;
              });
              setBreed(breedArray);
              break;
            case "faction":
              let factionArray = state.Posts.factions.find((element) => {
                return element._id === value;
              });
              setFaction(factionArray);
              break;
            case "gender":
              setGender(value);
              break;
            case "status":
              let statusArray = statusOptions.find((element) => {
                return element._id === value;
              });
              setStatus(statusArray);
              filter({ status: value });
              break;
            case "time":
              let timeArray = dateOptions.find((element) => {
                return element._id === value;
              });
              setDate(timeArray);
              filter({ time: value });
              break;
            default:
              break;
          }
        }
      }
    });
  }, [state.Posts.filter]);

  const dateFucntion = (date) => {
    setDate(date);
    filter({ time: date._id });
  };
  // useEffect(() => {
  //   filter({ time: date._id });
  // }, [date]);
  useEffect(() => {
    if (didMount.current) filter({ breed: breed._id });
    else didMount.current = true;
  }, [breed]);

  useEffect(() => {
    if (didMount2.current) filter({ faction: faction._id });
    else didMount2.current = true;
  }, [faction]);
  useEffect(() => {
    if (didMount3.current) filter({ saleType: salesTypes._id });
    else didMount3.current = true;
  }, [salesTypes]);
  useEffect(() => {
    if (didMount4.current) filter({ status: status._id });
    else didMount4.current = true;
  }, [status]);
  useEffect(() => {
    if (didMount5.current) filter({ assetType: type._id });
    else didMount5.current = true;
  }, [type]);

  const clearFilter = () => {
    setSalesTypes(salesType[0]);
    setType(assetType[0]);
    setFaction(factions[0]);
    setBreed(breeds[0]);
    setDate(dateOptions[0]);
    setGender("");
    dispatch(resetFilter());
    setStatus(statusOptions[0]);
  };
  useEffect(() => {
    let array = [{ displayName: "All", _id: "" }];
    let breedArray = array.concat(state.Posts.breeds);
    setBreeds(breedArray);
  }, [state.Posts.breeds]);

  useEffect(() => {
    setFactions([]);
    let array = [{ displayName: "All", _id: "" }];
    let factionArray = array.concat(state.Posts.factions);
    setFactions(factionArray);
  }, [state.Posts.factions]);
  useEffect(() => {
    let array = [{ displayName: "All", _id: "" }];
    let typeArray = array.concat(state.Posts.assetType);
    setAssetType(typeArray);
  }, [state.Posts.assetType]);
  useEffect(() => {
    let array = [{ displayName: "All", _id: "" }];
    let salesArray = array.concat(state.Posts.salesType);
    setsalesType(salesArray);
  }, [state.Posts.salesType]);
  return (
    <div className={visible ? styles.filters : styles.filters1}>
      <div className={styles.filterbutton} onClick={() => setVisible(!visible)}>
        <PrimaryButton
          className={styles.selection}
          label={visible ? "Hide Filters" : "Show Filters"}
        />
      </div>
      <>
        {/* <div className={styles.range}>
          <div className={styles.label}>Price range</div>
          <Range
            values={values}
            step={STEP}
            min={MIN}
            max={MAX}
            onChange={(values) => setValues(values)}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: "36px",
                  display: "flex",
                  width: "100%",
                }}
              >
                <div
                  ref={props.ref}
                  style={{
                    height: "8px",
                    width: "100%",
                    borderRadius: "4px",
                    background: getTrackBackground({
                      values,
                      colors: ["#3772FF", "#E6E8EC"],
                      min: MIN,
                      max: MAX,
                    }),
                    alignSelf: "center",
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ props, isDragged }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "24px",
                  width: "24px",
                  borderRadius: "50%",
                  backgroundColor: "#3772FF",
                  border: "4px solid #FCFCFD",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "-33px",
                    color: "#fff",
                    fontWeight: "600",
                    fontSize: "14px",
                    lineHeight: "18px",
                    fontFamily: "Poppins",
                    padding: "4px 8px",
                    borderRadius: "8px",
                    backgroundColor: "#141416",
                  }}
                >
                  {values[0].toFixed(1)}
                </div>
              </div>
            )}
          />
          <div className={styles.scale}>
            <div className={styles.number}>0.01 ETH</div>
            <div className={styles.number}>10 ETH</div>
          </div>
        </div> */}
      </>
      {visible && (
        <div className={styles.visible}>
          <div className={styles.dropdown}>
            <div className={styles.item}>
              <div className={styles.label}>Sort by</div>
              <div className={styles.genderFlex}>
                {dateOptions.map((x, index) => (
                  <div
                    key={index}
                    className={
                      x.displayName === date.displayName
                        ? "notched-border-active notched-border"
                        : "notched-border"
                    }
                    style={{ padding: "0 10px", margin: "0 8px 15px 0" }}
                    onClick={() => dateFucntion(x)}
                  >
                    <div className="notched-border-text">{x.displayName}</div>
                  </div>
                ))}
              </div>
              {/* <NewDropdown
            className={styles.dropdown}
            value={date}
            setValue={setDate}
            options={dateOptions}
          /> */}
            </div>
          </div>
          <div className={styles.group}>
            <div className={styles.item}>
              <div className={styles.label}>Asset Type</div>
              <NewDropdown
                className={styles.dropdown}
                value={type}
                setValue={setType}
                options={assetType}
              />
            </div>
            <div className={styles.item}>
              <div className={styles.label}>Gender</div>
              <div className={styles.genderFlex}>
                {genderOptions.map((x, index) => (
                  <div
                    key={index}
                    className={
                      x.value === gender
                        ? "notched-border-active notched-border"
                        : "notched-border"
                    }
                    style={{ padding: "0 10px" }}
                    onClick={() => onChangeGenderHandler(x.value)}
                  >
                    <div className="notched-border-text">{x.displayName}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.label}>Breed</div>
              <NewDropdown
                className={styles.dropdown}
                value={breed}
                setValue={setBreed}
                options={breeds}
              />
            </div>
            <div className={styles.item}>
              <div className={styles.label}>Faction</div>
              <NewDropdown
                className={styles.dropdown}
                value={faction}
                setValue={setFaction}
                options={factions}
              />
            </div>
            <div className={styles.item}>
              <div className={styles.label}>Sale Type</div>

              <NewDropdown
                className={styles.dropdown}
                value={salesTypes}
                setValue={setSalesTypes}
                options={salesType}
              />
            </div>
            <div className={styles.item}>
              <div className={styles.label}>Status</div>

              <NewDropdown
                className={styles.dropdown}
                value={status}
                setValue={setStatus}
                options={statusOptions}
              />
            </div>
          </div>
          <div className={styles.reset} onClick={clearFilter}>
            <Icon name="close-circle" size="24" />
            <span>Reset filter</span>
          </div>
        </div>
      )}
    </div>
  );
}
export default Filters;
