import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Modal from "../../components/Modal";
import Purchase from "../../components/Purchase";
import PlaceBid from "../../components/PlaceBid";
import { useSnackbar } from "notistack";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick";
import { getUSD } from "../../redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  Link,
} from "react-router-dom";
import Icon from "../../components/Icon";
import {
  AddCommnets,
  GetAssetById,
  GetBids,
  GetPostId,
} from "../../services/ApiServices";
import Loader from "../../components/Loader";
import TextInput from "../../components/TextInput";
import { useDispatch, useSelector } from "react-redux";
import HotBid from "../../components/HotBid";
import { Collapse, Table, Row, Col, Tooltip } from "antd";
import "./Users/user.css";
import { Steps, Popover } from "antd";
import moment from "moment";
import Shade from "../../images/shade.png";
import { BsFillArrowUpRightSquareFill } from "react-icons/bs";
import Auction from "../../abi/AuctionMarket.json";
import { ethers, utils, providers } from "ethers";
import ItemDetails from "../../components/Details";
import ItemAttributes from "../../components/Attributes";
import TieredPrice from "../../components/TieredPrice";
import BidsHistory from "../../components/BidsHistory";
import CountDown from "../../components/CountDown";
import "./index.css";
import Characteristics from "../../components/Characterstics";
import { GetHistory } from "../../services/ApiServices";
import NumberFormat from "react-number-format";
import HistoryAccordian from "../../components/HistoryAccordian";
import { MdOpenInNew } from "react-icons/md";
import SpaceshipMint from "../../components/SpaceshipMint";
import NewModal from "../../components/NewModal";
import { INFURA_PROVIDER_KEY } from "../../Config/config";
import { IoMaleSharp, IoFemaleSharp } from "react-icons/io5";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import Arrow from "../../images/shareArrow.png";
import ReactReadMoreReadLess from "react-read-more-read-less";
import BlackModal from "../../components/BlackModal";
const { Step } = Steps;
const { Panel } = Collapse;
const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);
const navLinks = ["Info", "Owners", "History", "Chain Info"];

const categories = [
  {
    category: "black",
    content: "art",
  },
  {
    category: "purple",
    content: "unlockable",
  },
];

const Item = (props) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const state = useSelector((state) => state);
  const { enqueueSnackbar } = useSnackbar();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [asset, setAsset] = useState({});
  const [visibleModalBid, setVisibleModalBid] = useState(false);
  const [nav, setNav] = useState("");
  const [item, setItem] = useState({});
  const [value, setValue] = useState(0);
  const [comment, setComment] = useState("");
  const [uid, setUid] = useState("");
  const [limit, setLimit] = useState(2);
  const [user, setUser] = useState({});
  const [spaceshipModal, setSpaceshipModal] = useState(false);
  const [isGallery, setIsGallery] = useState(false);
  const [bidModal, setBidModal] = useState(false);
  const [indexGallery, setIndexGallery] = useState(0);
  const [bids, setBids] = useState([]);
  const [isBidsLoading, setIsBidsLoading] = useState(false);
  const [currentPrice, setCurrentPrice] = useState("");
  const [currentTier, setCurrentTier] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [currentBalance, setCurrentBalance] = useState("");
  const [currentBid, setCurrentBid] = useState("");
  const [selectedItem, setSelectedItem] = useState(0);
  const [contractValues, setContractValues] = useState({});
  const [auctionValue, setAuctionValue] = useState({});
  const [history, setHistory] = useState([]);
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const AuctionAddress = "0x14D7c2d970cDcD402c86349e3956B55EFa255CA4";
  const nftAddress = "0x459124c1320bb66a2d2e58e04e7d28c344631ded";
  useEffect(() => {
    setUser(state.User.user);
  }, [state.User.user]);

  const getAsset = async () => {
    setCurrentBalance("");
    setCurrentPrice("");
    setTotalSupply("");
    setCurrentTier("");
    setIsLoading(true);
    let result;
    try {
      result = await GetAssetById(id);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }

    try {
      if (result.success) {
        setIsLoading(false);
        setAsset(result.asset);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const getBids = async (assetID) => {
    setCurrentPrice("");
    setCurrentTier("");
    setTotalSupply("");
    setCurrentBalance("");
    setIsBidsLoading(true);
    let result;
    try {
      result = await GetBids(assetID);
    } catch (e) {
      console.log(e);
      setIsBidsLoading(false);
    }
    try {
      if (result.success) {
        setIsBidsLoading(false);
        setBids(result.result);
      }
    } catch (e) {
      setIsBidsLoading(false);
      console.log(e);
    }
  };
  const getId = async () => {
    let result;
    try {
      result = await GetPostId(id);
    } catch (e) {
      console.log(e);
    }
    try {
      if (result.success) {
        getBids(result.result[0].id);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getHistories = async () => {
    let result;
    try {
      result = await GetHistory(id);
    } catch (e) {
      console.log(e);
    }
    try {
      if (result.success) {
        setHistory(result.inventory);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getCurrentBid = async () => {
    try {
      let provider;
      let auction;
      if (typeof window.ethereum == "undefined") {
        throw new Error("Please install metamask");
      } else {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        provider = new providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        auction = new ethers.Contract(AuctionAddress, Auction.abi, signer);
      }
      let data = await auction.getCurrentBid(asset.itemId);

      console.log("checking auction item", data.toString());
      let current_bid = data.toString().split(",")[1];

      current_bid = utils.formatUnits(
        utils.parseUnits(current_bid.toString(), "wei"),
        "ether"
      );

      setCurrentBid(current_bid);
      console.log("Checking current bid...", current_bid);
    } catch (e) {
      return console.log(e);
    }
  };

  useEffect(() => {
    function handleResize() {
      setDeviceWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect(() => {
  //   getCurrentBid();
  //   dispatch(getLandContract());
  // }, [asset.contractAddress]);
  // useEffect(() => {
  //   dispatch(getCapsuleContract());
  // }, []);
  // useEffect(() => {
  //   dispatch(getAssetContract(asset.contractAddress));
  // }, [asset.contractAddress]);
  // useEffect(() => {
  //   contract();
  // }, [asset.contractAddress, state.Contract.assetContract]);

  const contract = () => {
    if (
      Object.is(
        state.Contract.landContract.contractAddress,
        asset.contractAddress
      )
    ) {
      setCurrentPrice(state.Contract.landContract.currentPrice);
      setCurrentTier(state.Contract.landContract.currentTier);
      setTotalSupply(state.Contract.landContract.totalSupply);
      setCurrentBalance(state.Contract.landContract.currentBalance);
    } else if (
      Object.is(
        state.Contract.capsuleContract.contractAddress,
        asset.contractAddress
      )
    ) {
      setCurrentPrice(state.Contract.capsuleContract.currentPrice);
      setCurrentTier(state.Contract.capsuleContract.currentTier);
      setTotalSupply(state.Contract.capsuleContract.totalSupply);
      setCurrentBalance(state.Contract.capsuleContract.currentBalance);
    } else if (
      Object.is(
        state.Contract.assetContract.contractAddress,
        asset.contractAddress
      )
    ) {
      setCurrentPrice(state.Contract.assetContract.currentPrice);
      setCurrentTier(state.Contract.assetContract.currentTier);
      setTotalSupply(state.Contract.assetContract.totalSupply);
      setCurrentBalance(state.Contract.assetContract.currentBalance);
    }
  };
  const customDot = (dot, { status, index }) => (
    <Popover
      content={
        <span>
          step {index} status: {status}
        </span>
      }
    >
      {dot}
    </Popover>
  );

  useEffect(() => {
    let id = localStorage.getItem("uid");
    setUid(id);
  }, [id]);
  useEffect(() => {
    setIsLoading(true);
    getAsset();
    // getHistories();
    // getId();
  }, [id]);
  useEffect(() => {
    getUSDCurrency();
  }, [state.Usd.USD.length, asset]);
  const getUSDCurrency = () => {
    if (state.Usd.USD.length) {
      let usd = state.Usd.USD.find((element) => {
        return element.chainName == asset.currency;
      });
      if (usd) setValue(usd.price);
    }
  };
  useEffect(() => {
    dispatch(getUSD());
  }, []);

  function callback(key) {
    console.log(key);
  }
  useEffect(() => {
    if (Object.is(nftAddress, asset.contractAddress)) AuctionContractContract();
  }, [asset.contractAddress]);

  const AuctionContractContract = async () => {
    try {
      let provider;
      let auction;
      if (typeof window.ethereum == "undefined") {
        provider = new providers.InfuraProvider("homestead", {
          projectId: INFURA_PROVIDER_KEY,
        });

        auction = new ethers.Contract(AuctionAddress, Auction.abi, provider);
      } else {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        provider = new providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        auction = new ethers.Contract(AuctionAddress, Auction.abi, signer);
      }
      let data = await auction.fetchAuctionItem(
        asset.contractAddress,
        asset.tokenId,
        asset.itemId
      );
      let tokenAddress = data.toString().split(",")[0];
      let tokenId = data.toString().split(",")[1];
      let startPrice = data.toString().split(",")[2];
      let amount = data.toString().split(",")[3];
      let tradable = data.toString().split(",")[4];
      let sold = data.toString().split(",")[5];
      let duration = data.toString().split(",")[6];
      let endTime = data.toString().split(",")[8];
      // let currentBid = data.toString().split(",")[13];
      let price = utils.formatUnits(
        utils.parseUnits(startPrice.toString(), "wei"),
        "ether"
      );
      // currentBid = utils.formatUnits(
      //   utils.parseUnits(currentBid.toString(), "wei"),
      //   "ether"
      // );
      var value = {
        tokenAddress: tokenAddress,
        tokenId: tokenId,
        startPrice: price,
        amount: amount,
        tradable: tradable,
        sold: sold,
        duration: duration,
        endTime: endTime,
        // currentBid: currentBid,
      };
      setAuctionValue(value);
    } catch (e) {
      console.log(e);
    }
  };
  const customRenderThumb = (children) =>
    children.map((item, index) => {
      if (item.props.children.props.type)
        return (
          <img
            key={index}
            src={
              asset.attachments[0].fileType == "IMAGE"
                ? asset.attachments[0].url
                : asset.attachments[1].url
            }
          />
        );
      else return <img key={index} src={item.props.children.props.src} />;
    });

  return (
    <>
      {isLoading && <Loader className={styles.loader} />}
      {asset._id && (
        <div className={cn("section", styles.section)}>
          <div className={cn("container", styles.container)}>
            <div className={styles.details}>
              <h3 className={styles.title}>{asset.name}</h3>
              <div className={styles.head}>
                <div className={styles.size}>{asset.assetName}</div>
                {asset.assetType.type == "dog" && (
                  <div className={styles.gender}>
                    <p>{asset.gender}</p>
                    {/* <img src={asset.gender == "MALE" ? Male : Female} /> */}
                    {asset.gender == "MALE" ? (
                      <IoMaleSharp />
                    ) : (
                      <IoFemaleSharp />
                    )}
                  </div>
                )}
                {/* <div className={styles.likes}>
                  <img src={Likes} />
                  <p>243</p>
                </div> */}
              </div>
              <div className={styles.priceRow}>
                <Row>
                  <Col md={6} sm={7} xs={24}>
                    <div className={styles.priceHead}>Edition</div>
                  </Col>
                  <Col md={8} sm={14} xs={24}>
                    <p className={styles.of}>
                      {currentBalance ? (
                        <NumberFormat
                          value={currentBalance}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      ) : (
                        <NumberFormat
                          value={asset.available}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      )}{" "}
                      of{" "}
                      {
                        <NumberFormat
                          value={asset.supply}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      }
                    </p>
                  </Col>
                </Row>
              </div>
              <div className={styles.priceRow}>
                <Row>
                  <Col md={6} sm={7} xs={24}>
                    <div className={styles.priceHead}>
                      {asset.saleType.type == "buyable"
                        ? "Price"
                        : "Current Bid"}
                    </div>
                  </Col>
                  <Col md={18} sm={14} xs={24}>
                    {/* <img src={asset.chainId==1?Ethereum:Polygon} className={styles.priceimg} /> */}
                    <h4 className={styles.of}>
                      <NumberFormat
                        value={asset.tokenPrice.toFixed(2).toLocaleString()}
                        displayType={"text"}
                        thousandSeparator={true}
                      />{" "}
                      <span className={styles.currency}>{asset.currency}</span>
                      {value ? (
                        <span className={styles.usd}>
                          ({" "}
                          <NumberFormat
                            value={(value * asset.tokenPrice)
                              .toFixed(2)
                              .toLocaleString()}
                            displayType={"text"}
                            prefix="$ "
                            thousandSeparator={true}
                          />{" "}
                          )
                        </span>
                      ) : null}
                      {/* {asset.saleType.type == "buyable" && asset.tieredPricing && (
                        <span className={styles.leftPrice}>
                          ({" "}
                          <NumberFormat
                            value={asset.currentTierStock}
                            displayType={"text"}
                            thousandSeparator={true}
                          />{" "}
                          left in this price tier )
                        </span>
                      )} */}
                      {asset.saleType.type == "auction" && (
                        <Tooltip
                          placement="top"
                          title="The highest bidder will win the item at the end of the auction."
                        >
                          <BsFillArrowUpRightSquareFill
                            className={styles.arrow}
                          />
                        </Tooltip>
                      )}
                    </h4>
                  </Col>
                </Row>
              </div>

              {/* Auction Start */}
              {asset.saleType.type == "auction" && asset.status == "LIVE" && (
                <div className={styles.sales}>
                  <Row>
                    <Col md={6} sm={7} xs={24}>
                      <div className={styles.salesEnd}>Auction Ends</div>
                    </Col>
                    <Col md={18} sm={8} xs={24}>
                      <div className={styles.salesDate}>
                        {moment
                          .utc(asset.endDate)
                          .format("DD MMM YYYY hh:MM A")}
                        &nbsp;UTC
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} sm={8} xs={24}></Col>
                    <Col md={18} sm={8} xs={24}>
                      <div className={styles.salesTime}>
                        <CountDown asset={asset.endDate} />
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
              {/* Auction Starts on */}
              {asset.saleType.type == "auction" &&
                asset.status == "COMING_SOON" && (
                  <div className={styles.sales}>
                    <Row>
                      <Col md={6} sm={7} xs={24}>
                        <div className={styles.salesEnd}>Auction Starts on</div>
                      </Col>
                      <Col md={18} sm={8} xs={24}>
                        <div className={styles.salesDate}>
                          {moment
                            .utc(asset.startDate)
                            .format("DD MMM YYYY hh:MM A")}
                          &nbsp;UTC
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} xs={8}></Col>
                      <Col md={18} xs={8}>
                        <div className={styles.salesTime}>
                          <CountDown asset={asset.startDate} />
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              {/* Sales Start */}
              {asset.saleType.type == "buyable" &&
                asset.status == "COMING_SOON" && (
                  <div className={styles.sales}>
                    <Row>
                      <Col md={6} sm={7} xs={24}>
                        <div className={styles.salesEnd}>Sale Starts</div>
                      </Col>
                      {!moment().isAfter(asset.startDate) && (
                        <Col md={18} sm={8} xs={24}>
                          <div className={styles.salesDate}>
                            {moment
                              .utc(asset.startDate)
                              .format("DD MMM YYYY hh:MM A")}
                            &nbsp;UTC
                          </div>
                        </Col>
                      )}
                    </Row>
                    {!moment().isAfter(asset.startDate) && (
                      <Row>
                        <Col md={6} xs={8}></Col>
                        <Col md={18} xs={8}>
                          <div className={styles.salesTime}>
                            <CountDown asset={asset.startDate} />
                          </div>
                        </Col>
                      </Row>
                    )}
                  </div>
                )}

              <div className={styles.detail}>
                <Row>
                  <Col md={6} sm={7} xs={24}>
                    <div className={styles.detailHead}>Description</div>
                  </Col>
                  <Col md={18} sm={10} xs={24}>
                    <div className={styles.description}>
                      {/* {asset.description.replace("https://cyberdogz.io", "")} */}
                      <ReactReadMoreReadLess
                        charLimit={100}
                        readMoreText={"Read more ▼"}
                        readLessText={"Read less ▲"}
                        readMoreStyle={{
                          color: "#9698A1",
                          fontSize: "14px",
                          cursor: "pointer",
                          fontWeight: 500,
                          marginLeft: "8px",
                        }}
                        readLessStyle={{
                          color: "#9698A1",
                          fontSize: "14px",
                          cursor: "pointer",
                          fontWeight: 500,
                          marginLeft: "8px",
                        }}
                      >
                        {asset.description.replace("https://cyberdogz.io", "")}
                      </ReactReadMoreReadLess>
                    </div>
                  </Col>
                </Row>
              </div>
              {/* AuctionEnd */}
              <center></center>
              {asset.saleType.type == "auction" && asset.status == "ENDED" && (
                <>
                  {/* <p md={6} sm={7} xs={24}> */}
                  <div className={styles.auctionEnd}>Auction Ended</div>
                  {/* </p> */}
                </>
              )}
              {/* Auction Butons */}
              {asset.saleType.type == "auction" &&
                (asset.status == "LIVE" ? (
                  <button
                    className={styles.makeOffer}
                    onClick={() => {
                      setItem(asset);
                      setBidModal(true);
                    }}
                  >
                    PLACE BID
                  </button>
                ) : asset.status == "ENDED" ? (
                  <a
                    className={styles.opensea}
                    href={
                      "https://opensea.io/assets/" +
                      asset.contractAddress +
                      "/" +
                      asset.tokenId
                    }
                    target="_blank"
                  >
                    <span>Open in Opensea</span>
                  </a>
                ) : (
                  <div className={styles.salesbuttons}>
                    <button className={styles.buyNow}>COMING SOON</button>
                  </div>
                ))}

              {/* Sales Buttons */}
              {asset.saleType.type == "buyable" &&
                (asset.status == "LIVE" ? (
                  <div className={styles.salesbuttons}>
                    <button
                      className={styles.buyNow}
                      onClick={() => {
                        setItem(asset);
                        if (asset._id == "626f87b7d87315dcb8aec78e")
                          setSpaceshipModal(true);
                        else setVisibleModalBid(true);
                      }}
                    >
                      {asset._id == "626f87b7d87315dcb8aec78e"
                        ? "MINT NOW"
                        : "BUY NOW"}
                    </button>
                    {asset.chainId == 137 && (
                      <Tooltip placement="top" title="Open in Opensea">
                        <a
                          href={
                            "https://opensea.io/assets/matic/" +
                            asset.nftContractAddress +
                            "/" +
                            asset.itemId
                          }
                          target="_blank"
                        >
                          <MdOpenInNew className={styles.openicon} />
                        </a>
                      </Tooltip>
                    )}
                  </div>
                ) : (
                  <div className={styles.salesbuttons}>
                    <button className={styles.buyNow}>
                      {" "}
                      {asset.status == "ENDED" ? "SOLD OUT" : "COMING SOON"}
                    </button>
                  </div>
                ))}
            </div>
            <div className={styles.bg}>
              <div className={styles.preview}>
                <Carousel
                  showIndicators={false}
                  showStatus={false}
                  showThumbs={true}
                  selectedItem={selectedItem}
                  renderThumbs={customRenderThumb}
                >
                  {asset.attachments.map((x, index) => (
                    <div key={index}>
                      {x.fileType == "IMAGE" ? (
                        <img className={styles.img} src={x.url} alt="Card" />
                      ) : (
                        <video
                          key={index}
                          autoPlay={deviceWidth > 1005}
                          muted
                          loop={deviceWidth > 1005}
                          src={x.url}
                          width="100%"
                          type="video/mp4"
                          controls
                          controlsList="nodownload"
                          poster={asset.attachments[1].url}
                        />
                      )}
                    </div>
                  ))}
                </Carousel>

                {/* <Options
                  className={styles.options}
                  items={asset.likes}
                  uid={user._id}
                /> */}
              </div>
            </div>
          </div>

          <div className={cn("container")}>
            {/* Other Asset Types */}
            {asset.assetType.type != "dog" && (
              <Row>
                <Col md={12} xs={24}>
                  <ItemDetails asset={asset} />
                </Col>
                {asset.tieredPricing && (
                  <Col md={12} xs={24}>
                    <TieredPrice asset={asset} />
                  </Col>
                )}
              </Row>
            )}

            {/* Dog asset */}
            {asset.assetType.type === "dog" && (
              <Row>
                <Col md={12} xs={24}>
                  <ItemAttributes asset={asset} />
                </Col>
                {asset.tieredPricing ? (
                  <Col md={12} xs={24}>
                    {/* <TieredPrice asset={asset} /> */}
                    <ItemDetails asset={asset} />
                  </Col>
                ) : asset.saleType.type != "auction" &&
                  asset.saleType.type != "auction_ended" ? (
                  <Col md={12} xs={24}>
                    <ItemDetails asset={asset} />
                  </Col>
                ) : (
                  <Col md={12} xs={24}>
                    <BidsHistory bids={bids} />
                  </Col>
                )}
              </Row>
            )}

            {asset.assetType.type == "dog" && (
              <Row>
                <Col md={12} xs={24}>
                <Characteristics faction={asset.faction} />
                </Col>
                {/* <Col md={12} xs={24}>
                  <Characteristics faction={asset.faction} />
                </Col> */}
              </Row>
            )}
            {asset.assetType.type == "dog" && asset.tieredPricing && (
              <Row>
                <Col md={12} xs={24}>
                  {/* <ItemDetails asset={asset} /> */}
                </Col>
              </Row>
            )}
          </div>

          <HotBid />
          <Modal
            containerClassName="purchase"
            visible={bidModal}
            onClose={() => setBidModal(false)}
          >
            <PlaceBid
              item={item}
              className="purchase"
              refresh={getBids}
              getAsset={getAsset}
              currentBid={item.tokenPrice}
              auction={auctionValue}
              onClose={() => setBidModal(false)}
            />
          </Modal>
          <BlackModal
            containerClassName="purchase"
            visible={visibleModalBid}
            onClose={() => setVisibleModalBid(false)}
          >
            <Purchase
              item={item}
              className="purchase"
              onClose={() => setVisibleModalBid(false)}
              // currentBalance={currentBalance}
              // currentPrice={currentPrice}
              // currentTier={currentTier}
              // totalSupply={totalSupply}
            />
          </BlackModal>
          <Modal
            containerClassName="styles.gallery"
            visible={isGallery}
            onClose={() => setIsGallery(false)}
          >
            <Row>
              <Col span={4}>
                {asset.attachments.map((data, index) => {
                  return (
                    <div key={index}>
                      {data.fileType == "image" ? (
                        <img
                          src={data.url}
                          onClick={() => setIndexGallery(index)}
                          width="100%"
                        />
                      ) : null}
                    </div>
                  );
                })}
              </Col>
              <Col span={20}>
                <img
                  width="100%"
                  // srcSet={`${asset.attachments[indexGallery].url} 2x`}
                  src={asset.attachments[indexGallery].url}
                  alt="Card"
                />
              </Col>
            </Row>
          </Modal>
          <BlackModal
            visible={spaceshipModal}
            outerClassName={styles.mintModal}
            onClose={() => setSpaceshipModal(false)}
          >
            <SpaceshipMint value={value} />
          </BlackModal>
        </div>
      )}
    </>
  );
};

export default Item;
