import React, { useState, useEffect } from "react";
import { Row, Col, Slider } from "antd";
import styles from "../../screens/Item/Item.module.sass";
import Yellow0 from "../../images/Yellow/0.png";
import Yellow1 from "../../images/Yellow/1.png";
import Yellow2 from "../../images/Yellow/2.png";
import Yellow3 from "../../images/Yellow/3.png";
import Yellow4 from "../../images/Yellow/4.png";
import Yellow5 from "../../images/Yellow/5.png";
import Yellow6 from "../../images/Yellow/6.png";
import Yellow7 from "../../images/Yellow/7.png";
import Yellow8 from "../../images/Yellow/8.png";
import Yellow9 from "../../images/Yellow/9.png";
import Yellow10 from "../../images/Yellow/10.png";
import Blue0 from "../../images/Blue/0.png";
import Blue1 from "../../images/Blue/1.png";
import Blue2 from "../../images/Blue/2.png";
import Blue3 from "../../images/Blue/3.png";
import Blue4 from "../../images/Blue/4.png";
import Blue5 from "../../images/Blue/5.png";
import Blue6 from "../../images/Blue/6.png";
import Blue7 from "../../images/Blue/7.png";
import Blue8 from "../../images/Blue/8.png";
import Blue9 from "../../images/Blue/9.png";
import Blue10 from "../../images/Blue/10.png";
function ItemAttributes(props) {
  const [attributesAccordian, setAttributesAccordian] = useState(true);
  const [asset, setAsset] = useState();

  useEffect(() => {
    setAsset(props.asset);
  }, [props.asset]);
  return (
    <>
    <div className={styles.accordianHead}>Attributes</div>
    <div className={styles.detailsAccordian}>
      {/* <div className={styles.accordian}>
        <div className={styles.accordianHead}>Attributes</div>
        {attributesAccordian ? (
          <button
            className={styles.accordianImgMinus}
            onClick={() => setAttributesAccordian(false)}
          ></button>
        ) : (
          <button
            className={styles.accordianImgPlus}
            onClick={() => setAttributesAccordian(true)}
          ></button>
        )}
      </div> */}
      {attributesAccordian ? (
        <div className={styles.attributesCard}>
          <div className={styles.attributesRow}>
            {asset?.defaultAttributes.map((data, index) => {
              return (
                <Row key={index}>
                  <Col span={4}>
                    <p className={styles.attributeField}>{data.trait_type}</p>
                  </Col>
                  <Col span={2}></Col>
                  {/* <Col span={12}>
            {index%2==0?
              <img
                src={data.value==0?Yellow0:(data.value)==1?Yellow1:data.value==2?Yellow2:data.value==3?Yellow3:data.value==4?Yellow4:data.value==5?Yellow5:data.value==6?Yellow6:data.value==7?Yellow7:data.value==8?Yellow8:data.value==9?Yellow9:data.value==10?Yellow10:Yellow0}
                className={styles.attributeImg}
              />:
               <img
                src={data.value==0?Blue0:(data.value)==1?Blue1:data.value==2?Blue2:data.value==3?Blue3:data.value==4?Blue4:data.value==5?Blue5:data.value==6?Blue6:data.value==7?Blue7:data.value==8?Blue8:data.value==9?Blue9:data.value==10?Blue10:Blue0}
                className={styles.attributeImg}
              />
            }
            </Col> */}
                  <Col span={12}>
                    <Slider
                      value={data.value}
                      max={10}
                      min={0}
                      formatter={null}
                      trackStyle={{
                        background:
                          "linear-gradient(90deg, #fdc22e 0%, #eddc65 100%)",
                      }}
                      handleStyle={{
                        backgroundColor: '#101010',
                        borderColor: '#fdc22e',                       
                      }}
                    />
                  </Col>
                  <Col span={2}></Col>
                  <Col span={4}>
                    <p className={styles.attributeValue}>
                      <span>{data.value}</span>/10
                    </p>
                  </Col>
                </Row>
              );
            })}
            {/* <Row>
            <Col span={4}>
              <p className={styles.attributeField}>SPEED</p>
            </Col>
            <Col span={2}></Col>
            <Col span={12}>
              <img
                src={data.value==0?Yellow0:data.value.toFixed()==1?Yellow1:data.value==2?Yellow2:data.value==3?Yellow3:data.value==4?Yellow4:data.value==5?Yellow5:data.value==6?Yellow6:data.value==7?Yellow7:data.value==8?Yellow8:data.value==9?Yellow9:data.value==10?Yellow10:Yellow0}
                className={styles.attributeImg}
              />
            </Col>
            <Col span={2}></Col>
            <Col span={4}>
              <p className={styles.attributeValue}>
                <span>{data.value}</span>/10
              </p>
            </Col>
          </Row> */}
          </div>
          {/* <div className={styles.attributesRow}>
          <Row>
            <Col span={4}>
              <p className={styles.attributeField}>
                FRIENDLINESS
              </p>
            </Col>
            <Col span={2}></Col>
            <Col span={12}>
              <img
                src={asset?.stats.Friendliness.toFixed()==0?Blue0:asset?.stats.Friendliness.toFixed()==1?Blue1:asset?.stats.Friendliness.toFixed()==2?Blue2:asset?.stats.Friendliness.toFixed()==3?Blue3:asset?.stats.Friendliness.toFixed()==4?Blue4:asset?.stats.Friendliness.toFixed()==5?Blue5:asset?.stats.Friendliness.toFixed()==6?Blue6:asset?.stats.Friendliness.toFixed()==7?Blue7:asset?.stats.Friendliness.toFixed()==8?Blue8:asset?.stats.Friendliness.toFixed()==9?Blue9:asset?.stats.Friendliness.toFixed()==10?Blue10:Blue0}
                className={styles.attributeImg}
              />
            </Col>
            <Col span={2}></Col>
            <Col span={4}>
              <p className={styles.attributeValue}>
                <span>{asset?.stats.Friendliness}</span>/10
              </p>
            </Col>
          </Row>
        </div>
        <div className={styles.attributesRow}>
          <Row>
            <Col span={4}>
              <p className={styles.attributeField}>
                INTELLIGENCE
              </p>
            </Col>
            <Col span={2}></Col>
            <Col span={12}>
              <img
                src={asset?.stats.Intelligence==0?Yellow0:asset?.stats.Intelligence.toFixed()==1?Yellow1:asset?.stats.Intelligence==2?Yellow2:asset?.stats.Intelligence==3?Yellow3:asset?.stats.Intelligence==4?Yellow4:asset?.stats.Intelligence==5?Yellow5:asset?.stats.Intelligence==6?Yellow6:asset?.stats.Intelligence==7?Yellow7:asset?.stats.Intelligence==8?Yellow8:asset?.stats.Intelligence==9?Yellow9:asset?.stats.Intelligence==10?Yellow10:Yellow0}
                className={styles.attributeImg}
              />
            </Col>
            <Col span={2}></Col>
            <Col span={4}>
              <p className={styles.attributeValue}>
                <span>{asset?.stats.Intelligence}</span>/10
              </p>
            </Col>
          </Row>
        </div>
        <div className={styles.attributesRow}>
          <Row>
            <Col span={4}>
              <p className={styles.attributeField}>STAMINA</p>
            </Col>
            <Col span={2}></Col>
            <Col span={12}>
              <img
                src={asset?.stats.Stamina==0?Blue0:asset?.stats.Stamina.toFixed()==1?Blue1:asset?.stats.Stamina==2?Blue2:asset?.stats.Stamina==3?Blue3:asset?.stats.Stamina==4?Blue4:asset?.stats.Stamina==5?Blue5:asset?.stats.Stamina==6?Blue6:asset?.stats.Stamina==7?Blue7:asset?.stats.Stamina==8?Blue8:asset?.stats.Stamina==9?Blue9:asset?.stats.Stamina==10?Blue10:Blue0}
                className={styles.attributeImg}
              />
            </Col>
            <Col span={2}></Col>
            <Col span={4}>
              <p className={styles.attributeValue}>
                <span>{asset?.stats.Stamina}</span>/10
              </p>
            </Col>
          </Row>
        </div>
        <div className={styles.attributesRow}>
          <Row>
            <Col span={4}>
              <p className={styles.attributeField}>AGILITY</p>
            </Col>
            <Col span={2}></Col>
            <Col span={12}>
              <img
                src={asset?.stats.Agility==0?Yellow0:asset?.stats.Agility.toFixed()==1?Yellow1:asset?.stats.Agility==2?Yellow2:asset?.stats.Agility==3?Yellow3:asset?.stats.Agility==4?Yellow4:asset?.stats.Agility==5?Yellow5:asset?.stats.Agility==6?Yellow6:asset?.stats.Agility==7?Yellow7:asset?.stats.Agility==8?Yellow8:asset?.stats.Agility==9?Yellow9:asset?.stats.Agility==10?Yellow10:Yellow0}
                className={styles.attributeImg}
              />
            </Col>
            <Col span={2}></Col>
            <Col span={4}>
              <p className={styles.attributeValue}>
                <span>{asset?.stats.Agility}</span>/10
              </p>
            </Col>
          </Row>
        </div> */}
        </div>
      ) : null}
    </div>
    </>
  );
}

export default ItemAttributes;
