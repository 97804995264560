import React, { useEffect, useState } from "react";
import Styles from "../TransactionHistories.module.sass";
import { Row, Col, Empty } from "antd";
import HistoryShade from "../../../images/historyshade.png";
import {
  GetKibblezHistory,
  GetUserAssets,
} from "../../../services/ApiServices";
import cn from "classnames";
import moment from "moment";
import Loader from "../../Loader";
import TableLoader from "../../TableLoader";
const KibblezTable = ({ kebblezHistoires, isKibblezLoading }) => {
  return (
    <div className={Styles.history_table}>
      <div className={Styles.rowdata}>
        <Row>
          <Col span={6}>
            <div className={Styles.seller}>Date and Time</div>
          </Col>
          <Col span={6}>
            <div className={Styles.seller}>KIBBLEZ</div>
          </Col>
          <Col span={6}>
            <div className={Styles.seller}>Total Staked</div>
          </Col>
          <Col span={6}>
            <div className={Styles.seller}>Status</div>
          </Col>
        </Row>
      </div>
      {isKibblezLoading ? (
        <TableLoader />
      ) : kebblezHistoires.length ? (
        <>
          {kebblezHistoires.map((data, index) => {
            return (
                <Row className={Styles.rowdatabg} key={index}>
                  <Col span={6}>
                    <div className={Styles.pricefield}>
                      {moment(data.toDate * 1000)
                        .utc()
                        .format("DD-MM-YYYY hh:mm A")}{" "}
                      UTC
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className={Styles.pricefield}>
                      {Intl.NumberFormat("en-US").format(data.amount)}
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className={Styles.pricefield}>
                      {Intl.NumberFormat("en-US").format(data.totalStaked)}
                    </div>
                  </Col>

                  <Col span={6}>
                    <div
                      className={
                        data.status == "SPENT" ? Styles.sent : Styles.status
                      }
                    >
                      {data.status}
                    </div>
                  </Col>
                </Row>
            );
          })}
        </>
      ) : (
        <p className={Styles.empty}>No data available</p>
      )}
    </div>
  );
};
export default KibblezTable;
