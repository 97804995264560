
import {
    GETUSD,
} from './UsdTypes'

const initialState = {
    USD:[]
}



const usdReducer = (state = initialState, action) => {

    switch (action.type) {

       
        case GETUSD:
            return { ...state, USD: action.payload}
        default: return state;
    }



}

export default usdReducer