import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./User.module.sass";
import { Link } from "react-router-dom";
import Icon from "../../../components/Icon";
import Report from "../../../components/Report";
import Modal from "../../../components/NewModal";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import EtheriumImg from "../../../images/Ethereum.png";
import { ethers } from "ethers";
import { minifyAddress } from "../../../utils/helpers";
import { Tooltip } from "antd";
import Avatar from "../../../images/avatar.png";
import { useWeb3React } from "@web3-react/core";
import ProfileEdit from "../../ProfileEdit";
import { FaCopy, FaRegEdit } from "react-icons/fa";
import { SIGNATURE_MESSAGE_KEY } from "../../../Config/config";
import Web3 from "web3";
import { SignatureLogin } from "../../../services/ApiServices";
import Button from "../../../components/Button";
import { useSnackbar } from "notistack";
import TextInput from "../../../components/NewTextInput";
const shareUrlFacebook = "https://facebook.com";
const shareUrlTwitter = "https://twitter.com";

const User = ({ className, item, getUser, isFollow }) => {
  const [balance, setBalance] = useState(0);
  const state = useSelector((state) => state);
  const [visibleModalEdit, setVisibleModalEdit] = useState(false);
  const [user, setUser] = useState({});
  const { account, active, activate, error, deactivate } = useWeb3React();
  const [signatureLoading, setSignatureLoading] = useState(false);
  const [isCodeModal, setIsCodeModal] = useState(false);
  const [code, setCode] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const WalletBalance = ({ id }) => {
    if (typeof window.ethereum !== "undefined") {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      provider.getBalance(id).then((balance) => {
        let value = parseFloat(ethers.utils.formatEther(balance));
        setBalance(value);
      });

      return <div className={styles.price}>{balance.toFixed(4)}</div>;
    }
    return;
  };
  // const [isFollow,setIsFollow]= useState(false)

  // const IsFollow=(id)=>{
  //   if(item.id){
  //  var follow=item.followers.some((element) => {
  //         return element === id;
  //       })
  //       setIsFollow(follow);
  //     }
  // }
  useEffect(() => {
    setUser(state.User.user);
  }, [state.User.user]);
  const onEditModalClose = () => {
    setVisibleModalEdit(false);
  };


  const getSignature = async () => {
    if (!window.ethereum) 
    return enqueueSnackbar("Metamask is not found.",{variant:"error"});

    setSignatureLoading(true);
    var message = SIGNATURE_MESSAGE_KEY;
    var hash = Web3.utils.sha3(message);
    let signature;

    await window.ethereum.send("eth_requestAccounts");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    try {
      signature = await signer.signMessage(hash);
    } catch (e) {
      let message = e.message.split(":");
      setSignatureLoading(false);
      console.log(e);
      return enqueueSnackbar(
        message[0] === "MetaMask Message Signature" ? message[1] : message,
        { variant: "error" }
      );
    }
    // console.log("signature", signature);
    signatureLogin(signature);
  };
  const signatureLogin = async (signature) => {
    setSignatureLoading(true);
    let result;
    try {
      result = await SignatureLogin(signature);
    } catch (e) {
      console.log(e);
      setSignatureLoading(false);
      enqueueSnackbar(e.message, { variant: "error" });
    }
    try {
      if (result.success) {
        setIsCodeModal(true);
        setSignatureLoading(false);
        setCode(result.code);
        enqueueSnackbar(result.message, { variant: "info" });
      }
    } catch (e) {
      setSignatureLoading(false);
      enqueueSnackbar(e.message, { variant: "error" });
      console.log(e);
    }
  };
  return (
    <>
      {/* <div className={cn(styles.user, className)}>
        {item.address == account && (
          <FaRegEdit
            className={styles.editIcon}
            onClick={() => setVisibleModalEdit(true)}
          />
        )}

        <div className={styles.avatar}>
          <img src={item.profileUrl ? item.profileUrl : Avatar} alt="Avatar" />
         
        </div>
        {item.userName != "None" && (
          <div className={styles.name}>{item.userName}</div>
        )}
        {item.address && (
          <div className={styles.code}>
            <div className={styles.code}>
              <div className={styles.number}>
                {minifyAddress(item.address, 6)}
              </div>

              <Tooltip title="Copied!" color="#04DFE5" trigger="click">
                <button
                  className={styles.copy}
                  onClick={() => {
                    navigator.clipboard.writeText(item.address);
                  }}
                >
                  <Icon name="copy" size="16" />
                </button>
              </Tooltip>
            </div>
            
          </div>
          
        )}
        <div className={styles.loginButton}>
        {item.address == account && <Button
                label="Login Code"
                loading={signatureLoading}
                loadingtext="Verifying"
                onClick={() => {
                  getSignature();
                }}
              />}
            </div>
        <div className={styles.note}>
          Member since {moment(item.createdAt).format("MMM DD, YYYY")}
        </div>
      </div> */}
  <ProfileEdit
          user={item}
          onEditModalClose={onEditModalClose}
          getUserFunction={getUser}
        />

      <Modal
        visible={visibleModalEdit}
        onClose={() => setVisibleModalEdit(false)}
      >
        <ProfileEdit
          user={item}
          onEditModalClose={onEditModalClose}
          getUser={getUser}
        />
      </Modal>
      <Modal
        visible={isCodeModal}
        outerClassName={styles.codeModal}
        onClose={() => setIsCodeModal(false)}
      >
        <div className={styles.codeModalBody}>
          <div className={styles.modalTitle}>Login Code</div>
          <div className={styles.content}>
            <TextInput value={code} />
            <button
              className={styles.copycode}
              onClick={() => {
                navigator.clipboard.writeText(code);
              }}
            >
              <Tooltip title="Copied!" color="#04DFE5" trigger="click">
                <FaCopy className={styles.copyIcon} />
              </Tooltip>
            </button>
          </div>
          <div className={styles.note}>
            <span className={styles.noteHead}>Note: </span>
            Don't share the code to others.
          </div>
        </div>
      </Modal>
    </>
  );
};

export default User;
