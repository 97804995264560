import {
  GETUSD,
} from "./UsdTypes";
import { GetUSD } from "../../services/ApiServices";

export const getUSD = () => {


  return async (dispatch) => {
      let result;
      // dispatch({ type: SETFIRSTLOADED })
      try {
          result = await GetUSD();
      }
      catch (e) {

          if (e.message.includes('token is expired or token is unauthorized, please login again')) {
          }

      }

      if (result.success) {
          try{

          dispatch(GetUsdResponse(result.result))
          }
          catch(e){}
      }

  }

}

export const GetUsdResponse = (payload) => {

  return (dispatch) => {

      return dispatch({
          type: GETUSD,
          payload: payload
      })
  }
}