import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Link, useParams } from "react-router-dom";
import styles from "./TransactionHistories.module.sass";
import Icon from "../../components/Icon";
import KibblezTable from "./KibblezTable";
import BonzeTable from "./BonzeTable";
import ERC20Table from "./ERC-20Table";
import { Row, Col, Tooltip } from "antd";

const navLinks = ["Kibblez", "Bonez", "Bonez ERC-20 "];

const TransactionHistories = ({
  kebblezHistoires,
  isKibblezLoading,
  isBonezLoading,
  bonezHistoires,
  ERC_20Histoires,
  isERC_20Loading,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [polygonAssets, setPolygonAssets] = useState([]);
  const [ethereumAssets, setEthereumAssets] = useState([]);
  const { uid } = useParams();

  return (
    <div className={cn("container", styles.container)}>
      <div className={styles.top}>
        <div>
          <div className={styles.title}>Transactions</div>
          <div className={styles.description}>
            See your recent and financial game transactions
          </div>
        </div>
        <div className={styles.nav}>
        {navLinks.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            key={index}
            onClick={() => setActiveIndex(index)}
          >
            {x}
            {index == 2 && (
              <Tooltip
                color="#2F2F2F"
                title="$BONEZ ERC-20 token in Polygon network"
              >
                <i
                  className="fa fa-fr fa-info-circle"
                  style={{ fontSize: "16px" }}
                />
              </Tooltip>
            )}
          </button>
        ))}
      </div>
      </div>

  
      <div className={styles.group}>
        <div className={styles.item}>
          {activeIndex === 0 && (
            <>
              <KibblezTable
                class={styles.items}
                index={activeIndex}
                isKibblezLoading={isKibblezLoading}
                kebblezHistoires={kebblezHistoires}
              />
            </>
          )}
          {activeIndex === 1 && (
            <BonzeTable
              class={styles.items}
              index={activeIndex}
              assets={ethereumAssets}
              isBonezLoading={isBonezLoading}
              bonezHistoires={bonezHistoires}
            />
          )}
          {activeIndex === 2 && (
            <ERC20Table
              class={styles.items}
              index={activeIndex}
              ERC_20Histoires={ERC_20Histoires}
              isERC_20Loading={isERC_20Loading}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionHistories;
