
import React from "react";
import Styles from "./NFTDrop.module.sass";

import cn from "classnames";

const NFTDrop = () => {
  

  return (
    <>
    
  
      <div className={Styles.bg}>
        <div className={Styles.content}>
          <div className={Styles.welcome}>Welcome to NFTDrop Page</div>
          <br />
          <div className={Styles.storypara}>
          Content Will be Soon

      </div>
      </div>
      </div>  
        </>
  );

};

export default NFTDrop;


