import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { minifyAddress } from "../../utils/helpers";
import styles from "./LotteryLeaderBoard.module.sass";
import Avatar from "../../images/avatar.png";
import { useNavigate } from "react-router-dom";
import { LeaderBoardLottery } from "../../services/ApiServices";
import { useWeb3React } from "@web3-react/core";
import Medal from "../../images/medal.png";
import PrimaryButton from "../PrimaryButton";
import BlackModal from "../BlackModal";
import LotteryRegister from "../LotteryRegister";
import moment from "moment";
const LotteryLeaderBoard = (props) => {
  const [winnersList, setWinnersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { account, active, activate, error, deactivate } = useWeb3React();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    leaderboard();
  }, []);
  const leaderboard = async () => {
    setLoading(true);
    let result;
    try {
      result = await LeaderBoardLottery();
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
    if (result.success) {
      setWinnersList(result.winners);
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      {/* <Row>
        {winnersList.map((winner, index) => (
          <Col xs={24} sm={12} lg={6} md={12}>
            <div className={styles.list}>
              <div className={styles.heading}>
                {winner._id.day}-{winner._id.month}-{winner._id.year}
              </div>
              {winner.winners.map((address, index) => (
                <div className={styles.addressList}>
                  <img
                    src={address.profileUrl ? address.profileUrl : Avatar}
                    alt="Avatar"
                    className={styles.avatar}
                  />

                  <div className={styles.address}>
                    {minifyAddress(
                      address.address,
                      account == address.address ? 6 : 7
                    )}
                    {account == address.address && <span className={styles.you}>(you)</span>}
                  </div>
                </div>
              ))}
            </div>
          </Col>
        ))}
      </Row> */}
      <Row>
        <Col xs={24} sm={12} lg={8} md={12} className={styles.border}>
          {winnersList.slice(0, 1).map((winner, index) => (
            <div className={styles.list} key={index}>
              <div className={styles.heading}>
                {winner._id.day}-{winner._id.month}-{winner._id.year} (
                {moment().calendar().split(" at")[0]})
              </div>
              {winner.winners.map((address, index) => (
                <div className={styles.addressList} key={index}>
                  <img
                    src={address.profileUrl ? address.profileUrl : Avatar}
                    alt="Avatar"
                    className={styles.avatar}
                  />
                  <div className={styles.address}>
                    {minifyAddress(
                      address.address,
                      account == address.address ? 6 : 7
                    )}
                    {account == address.address && (
                      <span className={styles.you}>(you)</span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </Col>
        <Col xs={24} sm={12} lg={8} md={12} className={styles.colFlex}>
          <div className={styles.medalContent}>
            <img src={Medal} className={styles.medal} />
            <p>Win More Gen One</p>
            <h1>CYBERDOGZ LOTTERY</h1>
            <PrimaryButton
              label={props.btnTxt}
              className={styles.button}
              onClick={() => {
                if (props.btnTxt === "Register Now") {
                  setVisible(true);
                }
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} lg={8} md={12} className={styles.border}>
          {winnersList.slice(1, 2).map((winner, index) => (
            <div className={styles.list} key={index}>
              <div className={styles.heading}>
                {winner._id.day}-{winner._id.month}-{winner._id.year} (
                {moment().subtract(1, "days").calendar().split(" at")[0]})
              </div>
              {winner.winners.map((address, index) => (
                <div className={styles.addressList} key={index}>
                  <img
                    src={address.profileUrl ? address.profileUrl : Avatar}
                    alt="Avatar"
                    className={styles.avatar}
                  />

                  <div className={styles.address}>
                    {minifyAddress(
                      address.address,
                      account == address.address ? 6 : 7
                    )}
                    {account == address.address && (
                      <span className={styles.you}>(you)</span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </Col>
      </Row>
      {!winnersList.length && (
        <div className={styles.text}>Recent winners will be show here.</div>
      )}
      <BlackModal
        visible={visible}
        onClose={() => setVisible(false)}
        close={styles.close}
        outerClassName={styles.modal}
      >
        <LotteryRegister
          onClose={() => setVisible(false)}
          registerResult={props.registerResult}
        />
      </BlackModal>
    </div>
  );
};
export default LotteryLeaderBoard;
