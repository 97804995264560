import React, { useEffect, useState } from "react";
import cn from "classnames";
// import styles from "./Profile.module.sass";
import styles from "./Assets.module.sass";
// import TextInput from "../TextInput";
import Loader from "../../../components/Loader";
import { Row, Col, Tooltip } from "antd";
import { ethers, utils } from "ethers";
// import Modal from "../Modal";
import AndromedaLand from "../../../abi/AndromedaLand.json";
import Male from "../../../images/male.png";
import Female from "../../../images/female.png";
import frozonCapsule from "../../../abi/capsule.json";
// import Claim from "../../screens/Claim";
import Avatar from "antd/lib/avatar/avatar";
import EthereumImg from "../../../images/Ethereum.png";
import Cup from "../../../images/cup.png";
import { Link, useParams } from "react-router-dom";
import Kibblez from "../../../images/kibblez.png";
const Profile = (props, { className }) => {
  const [address, setAddress] = useState();
  const [errormsg, setErrormsg] = useState();
  const [isSelectWallet, setIsSelectWallet] = useState(false);
  const [ownedLand, setOwnedLand] = useState(0);
  const [ownfrozoncapsule, setFrozonCapsule] = useState(2);
  const [ownAsset, setOwnAsset] = useState();
  const [delevered, setDeleverd] = useState(false);
  const [isClaim, setIsClaim] = useState(false);
  const [capsule, setCapsule] = useState({});
  const { uid } = useParams();
  const andromedaAddress = "0xc72E061C4BCf042f8DDd2b82Ce0A9a7f0CF9329F";
  const forzoncapsuleAddress = "0x459124c1320BB66a2D2e58E04E7D28C344631Ded";
  const AssetAddress = "0x8daBBB3b26c2Eb1De543028b525Ba277bFC28227";
  const contractAddress = "0x862Dcd88D204d081896803152De5311A19999d2d";

  useEffect(() => {
    wallet();
    setAddress(uid);
  }, [uid]);
  const wallet = async () => {
    setAddress(uid);
    // getOwnLand();
  };

  const getOwnLand = async () => {
    setIsSelectWallet(false);
    setErrormsg("");
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      let andromedaLand = new ethers.Contract(
        andromedaAddress,
        AndromedaLand.abi,
        signer
      );
      try {
        let supplyAfter = await andromedaLand.balanceOf(uid, "1");
        setOwnedLand(parseFloat(supplyAfter.toString()));
      } catch (error) {
        return console.log("Checking error from token price...", error);
      }
    } catch (error) {
      setErrormsg(error.message);
    }
  };

  const getCapsuleSupply = async () => {
    setIsSelectWallet(false);
    setErrormsg("");
    // const web3 = new Web3(window.ethereum)
    try {
      if (!window.ethereum)
        throw new Error("No crypto wallet found. Please install it.");

      let user, meta;
      if (address) {
        setAddress(address);
        setIsSelectWallet(false);
      } else {
        meta = await window.ethereum.send("eth_requestAccounts");
        setAddress(meta.result[0]);
      }

      try {
        // user = await verifyBuyerAddress(address);
        // if (user.success && !user.message.includes("Address not exist.")) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        let value = 0;

        const signer = provider.getSigner();

        const address = await signer.getAddress();
        setAddress(address);
        // console.log("checking signer...", meta.result[0]);

        ethers.utils.getAddress(address);

        // await provider.getBalance(address).then((balance) => {
        //   value = utils.formatUnits(utils.parseUnits(balance.toString(), 'gwei'), 'ether')
        //   // value = utils.formatUnits(
        //   //   utils.parseUnits(buyReceipt.gasUsed.toString(), 'gwei'),
        //   //   'ether')
        // });

        value = await provider.getBalance(address, "latest");

        let displayBalance = utils.formatUnits(
          utils.parseUnits(value.toString(), "wei"),
          "ether"
        );

        // const tx = await signer.sendTransaction({
        //   to: address,
        //   value: ethers.utils.parseEther(
        //     (props.item.tokenPrice * eth).toString()
        //   ),
        // });

        let frozoncapsule = new ethers.Contract(
          forzoncapsuleAddress,
          frozonCapsule.abi,
          signer
        );
        try {
          let supplyAfter = await frozoncapsule.balanceOf(address, "1");

          setFrozonCapsule(parseFloat(supplyAfter.toString()));
        } catch (error) {
          return console.log("Checking error from token price...", error);
        }
        // }
        // else {
        //   setIsCreate(true);
        // }
      } catch (error) {
        // setIsDeleverd(true);
        setErrormsg(error.message);
      }
    } catch (err) {
      return setErrormsg(err.message);
    }
  };

  return (
    <>
      {props.isLoading ? (
        <Loader className={styles.loader} />
      ) : props.assets.length < 1 && ownedLand < 1 ? (
        <div className={styles.noassets}>{"No Assets Found"}</div>
      ) : (
        <Row>
          {ownedLand > 0 && (
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <div className={styles.Card}>
                <Link to={"/detail/61e5377f7ccabd0e6cf6fde2"}>
                  <div className={styles.preview}>
                    <div className={styles.of}>
                      <Tooltip title={"Rare"}>
                        <img src={Cup} />
                      </Tooltip>
                      <br />
                    </div>
                    <img
                      src="https://cyberdogz.s3.us-west-1.amazonaws.com/nft/andromeda/images/parcel.png"
                      alt="Card"
                    />
                  </div>
                  <div className={styles.body}>
                    <div className={styles.line}>
                      <div className={styles.title}>{"CYBERDOGZ LAND"}</div>
                    </div>
                  </div>
                  <div className={styles.counter}>
                  <div className={styles.kibblez}>
                    <img
                      // style={{background:"#EE9200"}}
                      src={Kibblez}
                    />
                    <span className={styles.kibblezText}>10/day</span>
                  </div>
                  <div className={styles.buyNowDiv}>
                    <button
                      className={styles.buyNow}
                      // onClick={() => {setCapsule(x);setIsClaim(!isClaim)}}
                    >
                      BUY NOW
                    </button>
                  </div>
                </div>
                </Link>
               
              </div>
            </Col>
          )}
          {props.assets.map((x, index) => (
            <Col xs={24} sm={12} md={12} lg={8} xl={6} key={index}>
              <div className={styles.Card} key={x._id + index}>
                <Link to={x.showInMarketplace && "/detail/"+x._id}>
                  <div className={styles.preview}>
                    <div className={styles.of}>
                      <Tooltip title={x.rarity.name}>
                        <img src={x.rarity.icon} />
                        </Tooltip>
                      <br />

                      {x.gender !== "NONE" && (
                        <Tooltip
                          title={
                            (x.gender = "MALE" ? "MALE" : "FEMALE")
                          }
                        >
                          <img
                            src={
                              (x.gender = "MALE" ? Male : Female)
                            }
                            className={styles.gender}
                          />
                        </Tooltip>
                      )}
                    </div>
                    <img src={x.previewImage} alt="Card" />
                  </div>

                  <div className={styles.lineBorder}></div>
                    <div className={!x.soldOut ? styles.cards : styles.cards1}>
                      <div className={styles.cardsTxt}>
                      <div className={styles.title}>
                        {x.name.split(":")[0].toUpperCase()}
                      </div>
                      <div className={styles.counter}>
                  <div className={styles.kibblez}>
                    <img
                      // style={{background:"#EE9200"}}
                      src={Kibblez}
                    />
                    <span className={styles.kibblezText}>
                      {x.rewardPerDay
                        ? x.rewardPerDay
                        : 100}{" "}
                      /day
                    </span>
                  </div>
                  {!x.soldOut  &&
                  <div className={styles.buyNowDiv}>
                    <button
                      className={styles.buyNow}
                      // onClick={() => {setCapsule(x);setIsClaim(!isClaim)}}
                    >
                     {x.soldOut? "SOLD OUT": "BUY NOW"}
                    </button>
                  </div>
                  }
                </div>
                      </div>
                      </div>
                  
                  
                </Link>
               
              </div>
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

export default Profile;
