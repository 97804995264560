import React from "react";
import Hero4 from "./Hero4";
import HotBid from "../../components/HotBid";

const CYBERDOGZ = () => {
  return (
    <>
      <Hero4/>
      <HotBid classSection="section-pb" />
    </>
  );
};

export default CYBERDOGZ;

