import React, { useEffect, useState } from "react";
import Styles from "./TransferKibblez.module.sass";
import { Row, Col, Slider, Checkbox, Steps } from "antd";
import TextInput from "../../components/TransparentTextInput";
import Shade from "../../images/stakeshade.png";
import { GetUserAssets, Transferkibblez } from "../../services/ApiServices";
import Loader from "../../components/Loader";
import Kibblez from "../../images/kibblez.png";
import Button from "../../components/Button";
import Form from "react-bootstrap/Form";
import Web3 from "web3";  
import { ethers, utils, providers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../PrimaryButton";

const TransferKibblez = ({ kibblez, getKibblez, modalClose }) => {
  const state = useSelector((state) => state);
  const [address, setAddress] = useState("");
  const [transferKibblez, setTransferKibblez] = useState(0);
  const [error, setError] = useState("");
  const [check, setCheck] = useState(false);
  const { account, active, activate, deactivate } = useWeb3React();
  const [tranferLoading, setTranferLoading] = useState(false);
  const [user, setUser] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setUser(state.User.user);
    if (state.User.user) {
      setUser(state.User.user);
    }
  }, [state.User.user]);
  const addressHandler = (e) => {
    let result = Web3.utils.isAddress(e.target.value);
    if (result) {
      setError("");
      setAddress(e.target.value);
    } else {
      setAddress("");
      setError("Enter valid Ethereum Address");
    }
  };
  const kibblezHandler = (e) => {
    if (e.target.value>0) {
      setError("");
      setTransferKibblez(e.target.value);
    } else {
      setTransferKibblez(0);
      setError("Transfer Kibblez is required!");
    }
  };
  const transfer = async() => {
    if (!address) setError("Receiver Address is required!");
    else if (!transferKibblez) setError("Transfer Kibblez is required!");
    else if(transferKibblez>kibblez.kibblezBalance) setError("You are not have enough kibblez balance!");

    else {
      let body={
        address:address,
        amount:transferKibblez
      }
        setTranferLoading(true);
        let result;
        try {
          result = await Transferkibblez(body,state.User.user.token);
        } catch (e) {
          console.log(e);
          setTranferLoading(false);
        }
        try {
          if (result.success) {
            setTranferLoading(false);
            enqueueSnackbar("Kibblez Transferd Successfully",{variant:"info"});
            getKibblez(account)
             modalClose()
          }
        } catch (e) {
          setTranferLoading(false);
          console.log(e);
        }
    }
  };

  return (
    <div className={Styles.stake}>
      <div className={Styles.head}>
        {/* <span className={Styles.yield}>TRANSFER</span>  */}
        TRANSFER $KIBBLEZ
      </div>
      <Row>
        <Col md={10} xs={24}>
          <div className={Styles.field}>Available $KIBBLEZ Balance</div>
        </Col>
        <Col md={4}>
          <div className={Styles.centerValue}>:</div>
        </Col>
        <Col md={10} xs={24}>
          <div className={Styles.value}>
            {Intl.NumberFormat("en-US").format(
              kibblez.kibblezBalance ? kibblez.kibblezBalance : 0
            )}
          </div>
        </Col>
      </Row>
      <br />
      <div>
        <TextInput label="Address" onChange={addressHandler} placeholder="Ex: 0x0000000000000000000000000000000000000000" />
        <br />
      </div>
      <div>
        <TextInput label="Transfer $KIBBLEZ" onChange={kibblezHandler } min="1" type="number" />
        <br />
      </div>
      {error && <div className={Styles.error}>{error}</div>}
      {/* <img src={Shade} className={Styles.shade} /> */}
      <div className={Styles.lineBorder} />
      <div className={Styles.button}>
        <div className={Styles.approve}>
          {" "}
          <PrimaryButton
            onClick={transfer}
            label="Transfer"
            loading={tranferLoading}
            loadingtext="Tranfering"
            className={Styles.button1}
          />
        </div>
      </div>
    </div>
  );
};
export default TransferKibblez;
