import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Challanges.module.sass";
import CurrentChallenge from "./CurrentChallenge";
import { Game3DShooterLeaderBoard } from "../../../../services/ApiServices";

const navLinks = [
  { name: "This Month", mode: "monthly" },
  { name: "This Week", mode: "weekly" },
  { name: "Today", mode: "daily" },
];

const Leaderboard = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [leaderboard, setLeaderboader] = useState([]);
  const [mode, setMode] = useState("monthly");
  useEffect(() => {
    getLeaderboard(activeIndex);
  }, [mode]);

  const getLeaderboard = async () => {
    let body = { mode: mode, limit: 10 };
    setLoading(true);
    let result;
    try {
      result = await Game3DShooterLeaderBoard(body);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
    try {
      if (result) {
        setLoading(false);
        setLeaderboader(result.data.docs);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.nav}>
          {navLinks.map((x, index) => (
            <button
              // className={cn(styles.link, {
              //   [styles.active]: index === activeIndex,
              // })}
              className={index == activeIndex ? "leader-active" : "leader"}
              key={index}
              onClick={() => {
                setActiveIndex(index);
                setMode(x.mode);
              }}
            >
              <div className={index == activeIndex ? "" : "leader-text"}>
                {x.name}
              </div>
            </button>
          ))}
        </div>

        <div className={styles.group}>
          <div className={styles.item}>
            <CurrentChallenge leaderboard={leaderboard} loading={loading} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Leaderboard;
