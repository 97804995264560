import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "antd";
import styles from "../Breeding.module.sass";
import Shade from "../../../../images/stakeshade.png";
import { useWeb3React } from "@web3-react/core";
import {
  GetBreedProof,
  GetProof,
  GetUserAssets,
} from "../../../../services/ApiServices";
import ReactReadMoreReadLess from "react-read-more-read-less";
import Button from "../../../../components/Button";
import { ethers, providers, utils } from "ethers";
import BreedingMarket from "../../../../abi/BreedingMarket.json";
import {
  POLYGON_BREEDING_ADDRESS,
  POLYGON_NFT_ADDRESS,
  USDT_TOKEN_ADDRESS,
} from "../../../../Config/config";
import Usdt from "../../../../images/usdt.png";
import { useDispatch, useSelector } from "react-redux";
import CyberdogzNFT from "../../../../abi/CyberdogzNFT.json";
import ERC20 from "../../../../abi/ERC20.json";
import { isMobile } from "react-device-detect";
import PrimaryButton from "../../../../components/PrimaryButton";

const BreedModal = ({ male, female }) => {
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const { account, active, activate, error, deactivate } = useWeb3React();
  const [dragData, setDragData] = useState({});
  const [errorMsg, setErrormsg] = useState("");
  const [txs, setTxs] = useState();
  const [isSuccess, setIsSuccess] = useState(false);
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [txHash, setTxHash] = useState("");
  const [user, setUser] = useState({});
  const [loadingtext, setLoadingText] = useState("");
  useEffect(() => {
    setUser(state.User.user);
    if (state.User.user) {
      setUser(state.User.user);
    }
  }, [state.User.user]);
  const ApproveFunction = async () => {
    setIsLoading(true);
    setLoadingText("Checking");
    setIsSuccess(false);
    setTxHash("");
    setErrormsg("");
    let provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    try {
      let network = await provider.getNetwork();
      if (network.chainId !== 137) {
        setErrormsg("Please connect to Polygon Mainnet");
        return setIsLoading(false);
      }
      try {
        setIsLoading(true);
        setLoadingText("Checking");
        let Token = new ethers.Contract(USDT_TOKEN_ADDRESS, ERC20, signer);
        let breedingMarket = new ethers.Contract(
          POLYGON_BREEDING_ADDRESS,
          BreedingMarket.abi,
          signer
        );
        let isApproval;
        let createApproval;
        let breedingFee;
        let usdtBalance;
        let maleProof, femaleProof, isEligible, message, proof;

        try {
          maleProof = await GetBreedProof(male.tokenId, "male", user.token);
        } catch (e) {
          setIsLoading(false);
          return setErrormsg(e.message);
        }
        if (!maleProof.success) {
          setIsLoading(false);
          return setErrormsg(proof.error);
        }

        try {
          femaleProof = await GetBreedProof(
            female.tokenId,
            "female",
            user.token
          );
        } catch (e) {
          setIsLoading(false);
          return setErrormsg(e.message);
        }
        if (!femaleProof.success) {
          setIsLoading(false);
          return setErrormsg(proof.error);
        }

        let eligibility;
        try {
          eligibility = await breedingMarket.isBreedable(
            account,
            [male.tokenId],
            [female.tokenId],
            [maleProof.proof],
            [femaleProof.proof]
          );

          isEligible = eligibility.toString().split(",")[0];
          message = eligibility.toString().split(",")[1];
          if (isEligible === "false" || isEligible === false) {
            setIsLoading(false);
            return setErrormsg(message);
          }
          try {
            breedingFee = await breedingMarket.breedingFee();
            try {
              usdtBalance = await Token.balanceOf(account);
              // console.log(usdtBalance.toString(),breedingFee.toString());
              if (parseInt(usdtBalance) < parseInt(breedingFee)) {
                setIsLoading(false);
                return setErrormsg("Not enough USDT balance available");
              }
              try {
                setLoadingText("Approving");
                setIsLoading(true);
                isApproval = await Token.allowance(
                  account,
                  POLYGON_BREEDING_ADDRESS
                );
                if (parseFloat(isApproval) >= parseFloat(breedingFee)) {
                  breed(maleProof, femaleProof);
                } else {
                  try {
                    createApproval = await Token.approve(
                      POLYGON_BREEDING_ADDRESS,
                      breedingFee
                    );

                    let buyReceipt;
                    try {
                      buyReceipt = await createApproval.wait();
                      if (buyReceipt.status == 1) {
                        breed(maleProof, femaleProof);
                      }
                    } catch (error) {
                      setIsLoading(false);
                      if (error.message) {
                        let message = error.message.split(":");
                        console.log(" approve exception", message);
                        return setErrormsg(
                          "Unknown Error occured please contact support!"
                        );
                      }
                    }
                  } catch (e) {
                    let message = e.message.split(":");
                    setIsLoading(false);
                    console.log(" approve exception", message);
                    return setErrormsg(
                      message[0] === "MetaMask Tx Signature"
                        ? message[1]
                        : message
                    );
                  }
                }
              } catch (e) {
                let message = e.message.split(":");
                setIsLoading(false);
                console.log("Breeding Fee", message);
                return setErrormsg("Breeding fee Error!");
              }
            } catch (e) {
              let message = e.message.split(":");
              setIsLoading(false);
              console.log("Buy approve exception", message);
              return setErrormsg(
                "Unknown Error occured please contact support!"
              );
            }
          } catch (e) {
            let message = e.message.split(":");
            setIsLoading(false);
            console.log("Buy approve exception", message);
            return setErrormsg("Unknown Error occured please contact support!");
          }
        } catch (error) {
          setIsLoading(false);
          console.log("Checking error from Eligibility...", error);
          let message = error.message.split(":");
          return setErrormsg(
            message[0] === "MetaMask Tx Signature" ? message[1] : message
          );
        }
      } catch (error) {
        let message = error.message.split(":");
        setIsLoading(false);
        console.log("Checking error from Approve...", message);
        return setErrormsg("Unknown Error occured please contact support!");
      }
    } catch (error) {
      setIsLoading(false);
      let message = error.message.split(":");
      console.log("Checking error from Approve...", message);
      return setErrormsg("Unknown Error occured please contact support!");
    }
  };

  const breed = async (maleProof, femaleProof) => {
    setErrormsg("");
    setLoadingText("Breeding");
    setIsLoading(true);
    setIsSuccess(false);
    setTxHash("");
    try {
      if (!window.ethereum) throw new Error("Metamask is not found.");
      try {
        let provider = new ethers.providers.Web3Provider(window.ethereum);

        let value = 0;

        let network = await provider.getNetwork();
        if (network.chainId !== 137) {
          setErrormsg("Please connect to Polygon Mainnet");
          return setIsLoading(false);
        }
        let signer = provider.getSigner();
        value = await provider.getBalance(account, "latest");
        let displayBalance = utils.formatUnits(
          utils.parseUnits(value.toString(), "wei"),
          "ether"
        );
        console.log("Inside Breeding market...");
        let breedingMarket = new ethers.Contract(
          POLYGON_BREEDING_ADDRESS,
          BreedingMarket.abi,
          signer
        );

        try {
          if (parseFloat(displayBalance) < 0.01) {
            setIsLoading(false);
            return setErrormsg("Insufficient Balance to complete the purchase");
          } else {
            let estimatedGasLimit;
            let estimatedGasPrice;
            try {
              estimatedGasLimit = await breedingMarket.estimateGas.breedToken(
                [male.tokenId],
                [female.tokenId],
                [maleProof.proof],
                [femaleProof.proof]
              );
            } catch (e) {
              setIsLoading(false);
              console.log("Error estimatedGasLimit: ", e);
              let message = e.message.split(":");
              return setErrormsg("Error in Estimated Gas Limit");
            }
            try {
              estimatedGasPrice = await provider.getGasPrice();
            } catch (error) {
              let message = error.message.split(":");
              setIsLoading(false);
              return setErrormsg(
                message[0] === "MetaMask Tx Signature" ? message[1] : message
              );
            }
            // utils.formatUnits(
            //   utils.parseUnits('3', 'gwei'),'wei')
            // console.log("gasPrice", estimatedGasPrice.toString());
            // console.log("gasLimit:", estimatedGasLimit.toString());

            let buyTx;
            try {
              buyTx = await breedingMarket.breedToken(
                [male.tokenId],
                [female.tokenId],
                [maleProof.proof],
                [femaleProof.proof]
              );
              setTxHash(buyTx.hash);
            } catch (error) {
              if (error.message) {
                let message = error.message.split(":");
                setIsLoading(false);
                return setErrormsg(
                  message[0] === "MetaMask Tx Signature" ? message[1] : message
                );
              }
              console.log("Erron on buy token...", error);
              setIsLoading(false);
              return setErrormsg("Transaction failed");
            }
            let buyReceipt;

            try {
              buyReceipt = await buyTx.wait();
            } catch (error) {
              if (error.message) {
                let message = error.message.split(":");
                setIsLoading(false);
                return setErrormsg(
                  message[0] === "MetaMask Tx Signature" ? message[1] : message
                );
              }
              console.log("Erron on wait ...", error);
              setIsLoading(false);
              return setErrormsg("Transaction failed");
            }

            if (buyReceipt.status) {
              setIsLoading(false);
              setIsSuccess(true);
            }
            return setTxs([
              `https://etherscan.io/tx/${buyReceipt.transactionHash}`,
            ]);
          }
        } catch (error) {
          setIsLoading(false);
          console.log("Checking error from token price...", error);
          let message = error.message.split(":");
          return setErrormsg(
            message[0] === "MetaMask Tx Signature" ? message[1] : message
          );
        }
      } catch (error) {
        setIsLoading(false);
        return setErrormsg(error.message);
      }
    } catch (err) {
      setIsLoading(false);
      return setErrormsg(err.message);
    }
  };
  return (
    <div>
      <center className={styles.heading}>Breeding</center>
      <br />
      <Row>
        <Col span={11}>
          <div className={styles.gender}> MALE</div>
          <br />
          <div className={styles.genderCard}>
            <img className={styles.assetImage} src={male.previewImage} />
          </div>
          <div className={styles.title}>{male.name}</div>
          {/* <div className={styles.whiteText}>#{male.tokenId}</div> */}
        </Col>
        <Col span={2}></Col>
        <Col span={11}>
          <div className={styles.gender}>FEMALE</div>
          <br />

          <div className={styles.genderCard}>
            <img className={styles.assetImage} src={female.previewImage} />
            {/* <div className={styles.whiteText}>#{female.tokenId}</div> */}
          </div>
          <div className={styles.title}>{female.name}</div>
        </Col>
        <Col span={24}>
          <div className={styles.breedingFee}>
            Breeding Fee:
            <span className={styles.amount}>25.00 USDT</span>
            <span style={{ color: "#00A2FD" }}>(Polygon)</span>
          </div>
          <div className={styles.pricemob}>
            Breeding Fee
            <br />
            <span className={styles.amount}>25.00 USDT</span>
            <span style={{ color: "#00A2FD" }}>(Polygon)</span>
          </div>
          {errorMsg && <div className={styles.error}>{errorMsg}</div>}
          <div className={styles.breedButton}>
            <PrimaryButton
              onClick={ApproveFunction}
              label="Breed Now"
              loading={isLoading}
              loadingtext={loadingtext}
            />
          </div>
          {isSuccess && (
            <div className={styles.success}>
              Breeding submitted successfully.
            </div>
          )}
          {txHash && (
            <div className={styles.hash}>
              Transaction submitted.{" "}
              <a href={"https://polygonscan.com/tx/" + txHash} target="_blank">
                View in explorer
              </a>
            </div>
          )}
          <div className={styles.importantNote}>
            <span>Important Notes</span>
            <li>Before breeding you must approve to spend USDT.</li>
            {/* <li>Female asset will not available for 24hours after breeding. </li> */}
            <li> It takes 24 hours to complete breeding.</li>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default BreedModal;
