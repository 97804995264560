import React, { useState, useEffect } from "react";
import styles from "../../screens/Item/Item.module.sass";
import { Row, Col } from "antd";
import moment from "moment";
import Ethereum from "../../images/Ethereum.png";

function HistoryAccordian({history}) {
  const [historyAccordian, setHistoryAccordian] = useState(true);
 

  return (
    <>
    <div className={styles.accordianHead}>Purchase History</div>
    <div className={styles.historyAccordian}>
      {/* <div className={styles.accordian}>
        <div className={styles.accordianHead}>Purchase History</div>
        {historyAccordian ? (
          <button
            className={styles.accordianImgMinus}
            onClick={() => setHistoryAccordian(false)}
          ></button>
        ) : (
          <button
            className={styles.accordianImgPlus}
            onClick={() => setHistoryAccordian(true)}
          ></button>
        )}
      </div> */}
      {historyAccordian ? (
        <div className={styles.history}>
          <Row>
            <Col span={8}>
              <div className={styles.buyer}>Buyer</div>
            </Col>
            <Col span={8}>
              <div className={styles.seller}>Token Id </div>
            </Col>
            <Col span={8}>
              <div className={styles.seller}>Price</div>
            </Col>
          </Row>
          {history.length ? (
            <div className={styles.historyCard}>
              {history.map((data,index) => {
                return (
                  <div className={styles.rowdata} key={index}>
                    <Row>
                      <Col span={8}>
                        <div className={styles.buyerid}>
                          <a
                            href={"https://etherscan.io/address/" + data.userId}
                            target="_blank"
                          >
                            {data.userId}
                          </a>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className={styles.tokenId}>
                          <a
                            href={
                              "https://opensea.io/assets/0x459124c1320BB66a2D2e58E04E7D28C344631Ded/" +
                              data.tokenId
                            }
                            target="_blank"
                          >
                            {data.tokenId}
                          </a>
                        </div>
                      </Col>
                      <Col span={8}>
                        <img src={Ethereum} className={styles.ethimg} />
                        <div className={styles.price}>{data.tokenPrice}</div>
                        <div className={styles.date}>
                          {moment(data.timestamp * 1000).fromNow()}
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={styles.listingText}>No Records</div>
          )}
        </div>
      ) : null}
    </div>
    </>
  );
}

export default HistoryAccordian;
