import React from "react";
import Styles from "./button.module.sass";
import { Spinner } from "react-bootstrap";

const TransparentButton = (props) => {
  return (
    <div className={props.outerContainer}>
      {props.loading ? (
        <button className={Styles.claimbutton} {...props}>
          <span className={Styles.label}>
            {props.loadingtext ? props.loadingtext : props.label}
            <Spinner
              as="span"
              animation="border"
              style={{ marginLeft: "8px" }}
              size="sm"
              className={Styles.spinner}
              role="status"
              aria-hidden="true"
            />
          </span>
        </button>
      ) : props.disabled === true ? (
        <button className={Styles.disableButton} disabled={false}>
          <span className={Styles.disableLabel}>{props.label}</span>
        </button>
      ) : (
        <button className={Styles.claimbutton} {...props}>
          <span className={Styles.label}>
            <div>
              <div className={Styles.top}></div>
            </div>
            {props.label}
            <div className={Styles.bottom}></div>
          </span>
        </button>
      )}
    </div>
  );
};
export default TransparentButton;
