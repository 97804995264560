import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Link, useParams } from "react-router-dom";
import styles from "./Table.module.sass";
// import Icon from "../../components/Icon";
import KibblezTable from "./KibblezTable";
import UsdtTable from "./UsdtTable";
import { Switch } from "antd";

const RaceTable = ({
  events,
  assets,
  isLoading,
  eventsLoading,
  setPage,
  totalDocs,
  page,
  refreshRaceEvents,
  activeOnly,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className={styles.container}>
      <p className={styles.title}>
        <span className={styles.cyberdogzText}>CYBERDOGZ SPACE-RACE</span>{" "}
        {/* <span className={styles.heading}>SPACE-RACE</span> */}
      </p>

      <div className={styles.header}>
        {activeOnly && (
          <div className={styles.switch}>
            Active{"   "}
            <Switch
              onChange={(isCheck) => {
                activeOnly(isCheck);
              }}
              defaultChecked
              className="switchdesign"
            />
          </div>
        )}
      </div>

      {/* <div className={styles.nav}>
        {navLinks.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            key={index}
            onClick={() => setActiveIndex(index)}
          >
            {x}
          </button>
        ))}
      </div> */}
      <div className={styles.group}>
        <div className={styles.item}>
          {activeIndex === 0 && (
            <>
              <KibblezTable
                events={events}
                setPage={setPage}
                totalDocs={totalDocs}
                eventsLoading={eventsLoading}
                isLoading={isLoading}
                assets={assets}
                page={page}
                refreshRaceEvents={refreshRaceEvents}
              />
            </>
          )}
          {activeIndex === 1 && (
            <UsdtTable
              class={styles.items}
              index={activeIndex}
              events={events}
              eventsLoading={eventsLoading}
              isLoading={isLoading}
              assets={assets}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RaceTable;
