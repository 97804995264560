import React, { useEffect, useRef, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import styles from "./Community.module.sass";
import { TfiTwitterAlt } from "react-icons/tfi";
import { SiDiscord } from "react-icons/si";
import { AiFillInstagram } from "react-icons/ai";
import { GiSailboat } from "react-icons/gi";
import { Col, Row } from "antd";

const Community = (props) => {
  const community = [
    {
      name: "Twitter",
      type: "Follow on",
      icon: <TfiTwitterAlt className={styles.icon} />,
      href: "https://twitter.com/gocyberdogz"
    },
    { name: "Discord", type: "Community on", icon: <SiDiscord className={styles.icon} />,href: "https://discord.gg/CYBERDOGZ" },
    { name: "Instagram", type: "Updates on", icon: <AiFillInstagram className={styles.icon} />, href :"https://www.instagram.com/cyberdogz.io/?igshid=YmMyMTA2M2Y%3D"},
    { name: "OpenSea", type: "Shop on", icon: <GiSailboat className={styles.icon} />,href: "https://opensea.io/collection/officialcyberdogz" },
  ];
  return (
    <div className={styles.community}>
      <div className={styles.heading}>Join Our Community</div>
      <Row justify="space-between" align="center">
        {community.map((data, index) => (
          <Col xs={22} sm={10} md={10} lg={5} className={styles.socialMedia} key={index}>
            <a href={data.href} target="_blank" rel="noreferrer" className={styles.a}>
            <Row>
              <Col span={10}>
                {data.icon}
              </Col>
              <Col span={14}>
                <div className={styles.type}>{data.type}</div>
                <div className={styles.name}>{data.name}</div>
              </Col>
            </Row>
            </a>
          </Col>
        ))}
      </Row>
    </div>
  );
};
export default Community;
