import {
  GETPOSTS,
  GETUSERPOSTS,
  CLEARGETPOSTS,
  GETSEARCHPOSTS,
  GETFILTERPOSTS,
  RESETFILTER,
  GETASSETTYPE,
  GETSALESTYPE,
  GETLODEMOREPOSTS,
  GETBREEDS,
  GETFACTIONS,
  GETLASTKEY,
  EDIT_FILTER,
  GETPOSTSLOADNIG,
  CLEARGETPOSTSLOADNIG,
  SETFIRSTLOADED,
  POST_CANCELLED,
  POST_ADDED,
  CREATE_POST,
  CREATE_POST_RESPONSE,
  CREATE_POST_ERROR,
  CREATE_POST_ERROR_CLEAR,
  EDIT_POST,
  EDIT_POST_ERROR,
  EDIT_POST_ERROR_CLEAR,
  EDIT_POST_RESPONSE,
  CLEARPOSTS,
} from "./PostsTypes";

import {
  GetAsset,
  GetBreeds,
  GetFaction,
  GetSearchAsset,
  GetUserAssets,
  GetAssetType,
  GetSalesType,
} from "../../services/ApiServices";

// export const createPost = (payload) => {
//     return async (dispatch) => {
//         let result;
//         dispatch({ type: CREATE_POST })
//         try {
//             result = await addPost(payload);
//         }
//         catch (e) {
//             if (e.message.includes('token is expired or token is unauthorized, please login again')) {
//                 return
//             }
//             else {
//                 return dispatch(CreatePostsError(e.message))
//             }

//         }

//         if (result.success) {
//             dispatch(createPostsResponse(result.subscription))
//         }
//         else {
//             return dispatch(CreatePostsError(result.error))

//         }

//     }

// }

export const createPostsResponse = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: CREATE_POST_RESPONSE,
      payload: payload,
    });
  };
};

export const editFilter = (params) => {
  return (dispatch) => {
    return dispatch({
      type: EDIT_FILTER,
      payload: params,
    });
  };
};
export const resetFilter = () => {
  return async (dispatch) => {
    let result;
    dispatch({ type: RESETFILTER });
    try {
      result = await GetAsset("&status=LIVE");
    } catch (e) {
      if (
        e.message.includes(
          "token is expired or token is unauthorized, please login again"
        )
      ) {
      }
    }

    if (result && result.success) {
      try {
        dispatch(GetPostsResponse(result.assets));
      } catch (e) {}
    }
  };
};

export const getSearch = () => {
  return async (dispatch) => {
    let result;
    try {
      result = await GetSearchAsset();
    } catch (e) {
      if (
        e.message.includes(
          "token is expired or token is unauthorized, please login again"
        )
      ) {
      }
    }
    if (result && result.success) {
      try {
        dispatch(GetSearchPostsResponse(result.assets.docs));
      } catch (e) {}
    }
  };
};
export const getFactions = () => {
  return async (dispatch) => {
    let result;
    // dispatch({ type: SETFIRSTLOADED });
    try {
      result = await GetFaction();
    } catch (e) {
      if (
        e.message.includes(
          "token is expired or token is unauthorized, please login again"
        )
      ) {
      }
    }

    if (result && result.success) {
      try {
        dispatch(GetFactionsResponse(result.factions));
      } catch (e) {}
    }
  };
};
export const getAssetType = () => {
  return async (dispatch) => {
    let result;
    // dispatch({ type: SETFIRSTLOADED });
    try {
      result = await GetAssetType();
    } catch (e) {
      if (
        e.message.includes(
          "token is expired or token is unauthorized, please login again"
        )
      ) {
      }
    }

    if (result && result.success) {
      try {
        dispatch(GetAssetTypeResponse(result.result));
      } catch (e) {}
    }
  };
};
export const getSalesType = () => {
  return async (dispatch) => {
    let result;
    // dispatch({ type: SETFIRSTLOADED });
    try {
      result = await GetSalesType();
    } catch (e) {
      if (
        e.message.includes(
          "token is expired or token is unauthorized, please login again"
        )
      ) {
      }
    }

    if (result && result.success) {
      try {
        dispatch(GetSalesTypeResponse(result.result));
      } catch (e) {}
    }
  };
};
export const getBreeds = () => {
  return async (dispatch) => {
    let result;
    try {
      result = await GetBreeds();
    } catch (e) {
      if (
        e.message.includes(
          "token is expired or token is unauthorized, please login again"
        )
      ) {
      }
    }

    if (result && result.success) {
      try {
        dispatch(GetBreedsResponse(result.breeds));
      } catch (e) {}
    }
  };
};
export const getPosts = (query) => {
  let params = "";
  if (query) {
    query.map((data) => {
      for (const key in data) {
        if (data[key]) {
          params = params + "&" + key + "=" + data[key];
        }
      }
    });
  }

  return async (dispatch) => {
    let result;
    dispatch({ type: SETFIRSTLOADED });
    try {
      result = await GetAsset(params);
    } catch (e) {
      if (
        e.message.includes(
          "token is expired or token is unauthorized, please login again"
        )
      ) {
      }
    }

    if (result && result.success) {
      try {
        dispatch(GetPostsResponse(result.assets));
      } catch (e) {}
    }
  };
};
export const getLoadMorePosts = (query) => {
  let params = "";
  if (query) {
    query.map((data) => {
      for (const key in data) {
        if (data[key]) {
          params = params + "&" + key + "=" + data[key];
        }
      }
    });
  }

  return async (dispatch) => {
    let result;
    // dispatch({ type: SETFIRSTLOADED });
    try {
      result = await GetAsset(params);
    } catch (e) {
      if (
        e.message.includes(
          "token is expired or token is unauthorized, please login again"
        )
      ) {
      }
    }

    if (result && result.success) {
      try {
        dispatch(GetLoadMorePostsResponse(result.assets));
      } catch (e) {}
    }
  };
};
export const getUserPosts = (id) => {
  return async (dispatch) => {
    let result;
    try {
      result = await GetUserAssets(id, "onlydogz=false&rents=true");
    } catch (e) {
      if (
        e.message.includes(
          "token is expired or token is unauthorized, please login again"
        )
      ) {
      }
    }

    if (result && result.success) {
      try {
        dispatch(GetUserPostsResponse(result.posts));
      } catch (e) {}
    }
  };
};

export const GetPostsResponse = (payload, params) => {
  return (dispatch) => {
    return dispatch({
      type: GETPOSTS,
      payload: payload,
      params: params,
    });
  };
};
export const GetLoadMorePostsResponse = (payload, params) => {
  return (dispatch) => {
    return dispatch({
      type: GETLODEMOREPOSTS,
      payload: payload,
      params: params,
    });
  };
};
export const GetBreedsResponse = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: GETBREEDS,
      payload: payload,
    });
  };
};
export const GetFactionsResponse = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: GETFACTIONS,
      payload: payload,
    });
  };
};
export const GetAssetTypeResponse = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: GETASSETTYPE,
      payload: payload,
    });
  };
};
export const GetSalesTypeResponse = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: GETSALESTYPE,
      payload: payload,
    });
  };
};
export const GetUserPostsResponse = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: GETUSERPOSTS,
      payload: payload,
    });
  };
};
export const GetLastKeyResponse = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: GETLASTKEY,
      payload: payload,
    });
  };
};
export const GetSearchPostsResponse = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: GETSEARCHPOSTS,
      payload: payload,
    });
  };
};
export const CreatePostsError = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: CREATE_POST_ERROR,
      payload: payload,
    });
  };
};

export const clearPosts = () => {
  return (dispatch) => {
    return dispatch({
      type: CLEARPOSTS,
    });
  };
};
