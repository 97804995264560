import React, { useState, useEffect, useRef } from "react";
import { useWeb3React } from "@web3-react/core";
import styles from "./TackBack.module.sass";
import Button from "../Button";
import { useSnackbar } from "notistack";
import { ethers, utils, providers } from "ethers";
import CyberdogzNFT from "../../abi/CyberdogzNFT.json";
import RentManager from "../../abi/RentManager.json";
import { minifyAddress } from "../../utils/helpers";
import PrimaryButton from "../PrimaryButton";
import TransparentButton from "../TransparentButton";

const TakeBack = ({ rentData, uid,getRentAssetsHistory,onClose }) => {
  const { account, active, activate, error, deactivate } = useWeb3React();
  const [isRevokeLoading, setIsRevokeLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  // const CyberdogzNFTAddress = "0x459124c1320BB66a2D2e58E04E7D28C344631Ded";//ethereum mainnet
  const CyberdogzNFTAddress = "0x6c9cbd0be01768f11865dafaf41a10ce40fbb7e3"; //polygon mainnet
  // const CyberdogzNFTAddress = "0xbD14f20eE32553e28F75488868A39Dce006fbC6f"; //Rinkeby
  // const RentManagerAddress = "0xaB1273EC34a156A985dEfAD873607B8b646093Ed"; //Rinkeby
  const RentManagerAddress = "0x9Fa958F64b7cC0F9C305B33620b7f66Ca86d6c2A";
  const takeBack = async () => {
    setIsRevokeLoading(true);
    try {
      if (!window.ethereum)
        throw new Error("No crypto wallet found. Please install it.");
      try {
        let provider = new ethers.providers.Web3Provider(window.ethereum);
        let signer = provider.getSigner();
        let network = await provider.getNetwork();
        // if (asset.chainId == 1) {
        //   if (network.name !== "homestead") {
        //     setIsRevokeLoading(false);
        //     return enqueueSnackbar("Please connect to Ethereum Mainnet",{variant:"error"});
        //   }
        // } else
        // if (asset.chainId == 137) {
          if (network.chainId !== 137) {
            setIsRevokeLoading(false);
            return enqueueSnackbar("Please connect to Polygon Mainnet", {
              variant: "error",
            });
          }
        // }

        let rentManager = new ethers.Contract(
          RentManagerAddress,
          RentManager.abi,
          signer
        );
        try {
          let revoke = await rentManager.revokeRental(rentData.rentId);

          let buyReceipt;
          try {
            buyReceipt = await revoke.wait();
            // if (buyReceipt.status == 1) {
              getRentAssetsHistory()
              enqueueSnackbar("Revoke submitted succesfully!", {
                variant: "info",
              });
              setIsRevokeLoading(false);
              onClose()
            // }
          } catch (error) {
            setIsRevokeLoading(false);
            if (error.message) {
              let message = error.message.split(":");
              console.log(message);
              getRentAssetsHistory()
              return enqueueSnackbar(
                "Unknown Error occured please contact support!",
                { variant: "error" }
              );
            }
          }
        } catch (error) {
          let message = error.message.split(":");
          console.log("Revoke exception", error);
          setIsRevokeLoading(false);
          console.log(message);
          return enqueueSnackbar(
            "Unknown Error occured please contact support!",
            { variant: "error" }
          );
        }
      } catch (error) {
        let message = error.message.split(":");
        setIsRevokeLoading(false);
        console.log("Revoke exception", error);
        return enqueueSnackbar(
          "Unknown Error occured please contact support!",
          { variant: "error" }
        );
      }
    } catch (err) {
      let message = err.message.split(":");
      setIsRevokeLoading(false);
      console.log("Revoke exception", err);
      return enqueueSnackbar("Unknown Error occured please contact support!", {
        variant: "error",
      });
    }
  };

  return (
    <div className={styles.takeBack}>
      {account == uid ? (
        <div className={styles.revoke}>
          <div className={styles.title}>Are you sure?</div>
          <div className={styles.text}>Do you really want to take back your asset from {minifyAddress(rentData.tenant, 6)}?</div>
          <div className={styles.buttons}>
              <TransparentButton label="Cancel" onClick={() => onClose()}/>
              <PrimaryButton label="Take Back" onClick={takeBack} loading={isRevokeLoading} loadingText="Revoking" />
          </div>
        </div>
      ) : (
        <div className={styles.notOwner}>
          You are not a Owner 
        </div>
      )}
    </div>
  );
};
export default TakeBack;
