import { GETUSER, GETUSERS, REMOVEUSER, CREATEUSER } from "./UserTypes";

import {
  createBuyerUser,
  VerifyUser,
  GetUsers,
} from "../../services/ApiServices";

export const getUser = (uid) => {
  return async (dispatch) => {
    let result;
    try {
      result = await VerifyUser(uid);
    } catch (e) {
      console.log(e.message);
    }

    if (result && result.success) {
      try {
        dispatch(GetUserResponse(result.result));
      } catch (e) {
        console.log(e.message);
      }
    }
  };
};
export const removeUser = () => {
  return async (dispatch) => {
    dispatch(RemoveUsersResponse());
  };
};
export const CreateUser = (body) => {
  return async (dispatch) => {
    let result;
    try {
      result = await createBuyerUser(body);
    } catch (e) {
      if (
        e.message.includes(
          "token is expired or token is unauthorized, please login again"
        )
      ) {
      }
    }
    if (result && result.success) {
      try {
        dispatch(CreateUserResponse(result.result));
      } catch (e) {}
    }
  };
};
export const getUsers = () => {
  return async (dispatch) => {
    let result;
    // dispatch({ type: SETFIRSTLOADED })
    try {
      result = await GetUsers();
    } catch (e) {
      if (
        e.message.includes(
          "token is expired or token is unauthorized, please login again"
        )
      ) {
      }
    }

    if (result && result.success) {
      try {
        dispatch(GetUsersResponse(result.users));
      } catch (e) {}
    }
  };
};

export const RemoveUsersResponse = () => {
  return (dispatch) => {
    return dispatch({
      type: REMOVEUSER,
    });
  };
};
export const GetUsersResponse = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: GETUSERS,
      payload: payload,
    });
  };
};
export const GetUserResponse = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: GETUSER,
      payload: payload,
    });
  };
};

export const CreateUserResponse = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: CREATEUSER,
      payload: payload,
    });
  };
};
