import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import { useSnackbar } from "notistack";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick";
import {
  getLandContract,
  getCapsuleContract,
  getUSD,
  getAssetContract,
} from "../../redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  Link,
} from "react-router-dom";
import {
  AddCommnets,
  GetAssetById,
  GetUSD,
  GetBids,
  GetPostId,
  RentAssetHistories,
  GetAssetByTokenId,
  NamePet,
} from "../../services/ApiServices";
import Loader from "../../components/Loader";
import TextInput from "../../components/TransparentTextInput";
import { useDispatch, useSelector } from "react-redux";
import HotBid from "../../components/HotBid";
import { Collapse, Table, Row, Col, Tooltip } from "antd";
import { Steps, Popover } from "antd";
import Female from "../../images/female.png";
import Male from "../../images/male.png";
import Ethereum from "../../images/Ethereum.png";
import ItemAttributes from "../../components/Attributes";
import "./index.css";
import Characteristics from "../../components/Characterstics";
import ReactReadMoreReadLess from "react-read-more-read-less";
import Modal from "../../components/NewModal";
import RentModal from "./RentModal";
import RentHistory from "../../components/RentHistory";
import GameHistory from "../../components/GameHistory";
import { minifyAddress } from "../../utils/helpers";
import { useWeb3React } from "@web3-react/core";
import { ethers, utils, providers } from "ethers";
import CyberdogzNFT from "../../abi/CyberdogzNFT.json";
import RentManager from "../../abi/RentManager.json";
import BreedingNft from "../../abi/BreedingAssetNFT.json";
import { Spinner } from "react-bootstrap";
import { MdOpenInNew } from "react-icons/md";
// import ConfirmModal from "../../components/ConfirmModal";
import Button from "../../components/Button";
import {
  ALCHEMY_PROVIDER_KEY,
  BREEDING_NFT_ADDRESS,
  ETHEREUM_NFT_ADDRESS,
  ETHEREUM_NFT_TRANSFER_ADDRESS,
  ETHEREUM_RENT_MANEGER_ADDRESS,
  INFURA_PROVIDER_KEY,
  POLYGON_NFT_ADDRESS,
  PUPPY_ASSET_ID,
} from "../../Config/config";
import { AiFillEdit } from "react-icons/ai";
import { FaDog } from "react-icons/fa";
import { IoFemaleSharp, IoMaleSharp } from "react-icons/io5";
import BlackModal from "../../components/BlackModal";
import PrimaryButton from "../../components/PrimaryButton";

const ItemDetail = (props) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const { account, active, activate, error, deactivate } = useWeb3React();
  const state = useSelector((state) => state);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { id, tokenId, chainId } = useParams();
  const [asset, setAsset] = useState({});
  const [value, setValue] = useState(0);
  const [user, setUser] = useState({});
  const [selectedItem, setSelectedItem] = useState(0);
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [isRentModal, setIsRentModal] = useState(false);
  const [balance, setBalance] = useState(0);
  const [renterAddress, setRenterAddress] = useState("");
  const [isRent, setISRent] = useState(false);
  const [isRevoke, setIsRevoke] = useState(false);
  const [isRevokeLoading, setIsRevokeLoading] = useState(false);
  const [rentalId, setRentalId] = useState("");
  const [rentHistory, setRentHistory] = useState([]);
  const [rentHistoryLoading, setRentHistoryLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [tranferLoading, setTranferLoading] = useState(false);
  const [nameLoading, setNameLoading] = useState(false);
  const [nicknameModal, setNicknameModal] = useState(false);
  const [nickname, setNickname] = useState("");
  const [nicknameError, setNicknameError] = useState("");
  const [isPuppyOwn, setIsPuppyOwn] = useState(false);

  useEffect(() => {
    setUser(state.User.user);
  }, [state.User.user]);
  useEffect(() => {
    if (account && asset.contractAddress) {
      if (PUPPY_ASSET_ID == id) getPuppyBalanace();
      else getBalanace();
    }
  }, [account, asset.contractAddress]);

  const getBalanace = async () => {
    setIsRevoke(false);
    setBalance(0);
    setRentalId("");
    setRenterAddress("");
    let provider;
    try {
      if (!window.ethereum)
        throw new Error("No crypto wallet found. Please install it.");
      try {
        if (chainId == 1) {
          provider = new providers.InfuraProvider("homestead", {
            projectId: INFURA_PROVIDER_KEY,
          });
        } else {
          provider = new providers.AlchemyProvider(
            "matic",
            ALCHEMY_PROVIDER_KEY
          );
        }
        let cyberdogzNFT = new ethers.Contract(
          chainId == 1 ? ETHEREUM_NFT_ADDRESS : POLYGON_NFT_ADDRESS,
          CyberdogzNFT.abi,
          provider
        );
        let rentManager = new ethers.Contract(
          ETHEREUM_RENT_MANEGER_ADDRESS,
          RentManager.abi,
          provider
        );
        try {
          let supplyAfter = await cyberdogzNFT.balanceOf(account, tokenId);
          setBalance(parseFloat(supplyAfter.toString()));
          // console.log(parseFloat(supplyAfter.toString()));
          // if(parseFloat(supplyAfter.toString())>=1){
          // let checkRent = await rentManager.checkRental(tokenId, account);
          let RentalItems = await rentManager.getOwnerRentalItems(account);
          RentalItems.map((data) => {
            if (data[0] == account) {
              if (
                data.active === true &&
                parseInt(data.tokenId._hex) === parseInt(tokenId)
              ) {
                setIsRevoke(true);
                setRenterAddress(data.tenant);
                setRentalId(parseInt(data.rentalId._hex));
              }
            }
          });

          // }
        } catch (error) {
          return console.log("Checking error from token price...", error);
        }
      } catch (error) {
        console.log(error.message);
      }
    } catch (err) {
      return console.log(err.message);
    }
  };
  const getPuppyBalanace = async () => {
    setIsPuppyOwn(false);
    let provider;
    try {
      if (!window.ethereum)
        throw new Error("No crypto wallet found. Please install it.");
      try {
        if (chainId == 1) {
          provider = new providers.InfuraProvider("homestead", {
            projectId: INFURA_PROVIDER_KEY,
          });
        } else {
          provider = new providers.AlchemyProvider(
            "matic",
            ALCHEMY_PROVIDER_KEY
          );
        }
        let breedingNft = new ethers.Contract(
          BREEDING_NFT_ADDRESS,
          BreedingNft.abi,
          provider
        );

        try {
          let ownerAddress = await breedingNft.ownerOf(tokenId);
          if (ownerAddress === account) {
            setIsPuppyOwn(true);
          }
        } catch (error) {
          return console.log("Checking error from token price...", error);
        }
      } catch (error) {
        console.log(error.message);
      }
    } catch (err) {
      return console.log(err.message);
    }
  };

  const getAsset = async () => {
    setIsLoading(true);
    let result;
    try {
      result = await GetAssetByTokenId(chainId, id, tokenId);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }

    try {
      if (result.success) {
        setIsLoading(false);
        setAsset(result.asset);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    function handleResize() {
      setDeviceWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getAsset();
  }, [id]);

  useEffect(() => {
    setValue(state.Usd.USD);
  }, [state.Usd.USD]);

  useEffect(() => {
    dispatch(getUSD());
  }, []);

  useEffect(() => {
    if (tokenId) getRentAssetsHistory();
  }, [tokenId]);
  const rentClose = () => setIsRentModal(false);

  const customRenderThumb = (children) =>
    children.map((item, index) => {
      if (item.props.children.props.type)
        return (
          <img
            key={index}
            src={
              asset.attachments[0].fileType == "IMAGE"
                ? asset.attachments[0].url
                : asset.attachments[1].url
            }
          />
        );
      else return <img key={index} src={item.props.children.props.src} />;
    });
  const getRentAssetsHistory = async () => {
    setRentHistoryLoading(true);
    let result;

    try {
      result = await RentAssetHistories(tokenId);
    } catch (e) {
      console.log(e);
      setRentHistoryLoading(false);
    }

    try {
      if (result.success) {
        setRentHistoryLoading(false);
        setRentHistory(result.result);
      }
    } catch (e) {
      setRentHistoryLoading(false);
      console.log(e);
    }
  };
  const takeBack = async () => {
    setIsRevokeLoading(true);
    try {
      if (!window.ethereum)
        throw new Error("No crypto wallet found. Please install it.");
      try {
        let provider = new ethers.providers.Web3Provider(window.ethereum);
        let signer = provider.getSigner();
        let network = await provider.getNetwork();
        // if (chainId == 1) {
        //   if (network.name !== "homestead") {
        //     setIsRevokeLoading(false);
        //     return enqueueSnackbar("Please connect to Ethereum Mainnet",{variant:"error"});
        //   }
        // } else
        if (chainId == 137) {
          if (network.chainId !== 137) {
            setIsRevokeLoading(false);
            return enqueueSnackbar("Please connect to Polygon Mainnet", {
              variant: "error",
            });
          }
        }

        let rentManager = new ethers.Contract(
          ETHEREUM_RENT_MANEGER_ADDRESS,
          RentManager.abi,
          signer
        );
        try {
          let revoke = await rentManager.revokeRental(rentalId);

          let buyReceipt;
          try {
            buyReceipt = await revoke.wait();
            if (buyReceipt.status == 1) {
              enqueueSnackbar("Revoke submitted succesfully!", {
                variant: "info",
              });
              setIsRevokeLoading(false);
              getBalanace();
            }
          } catch (error) {
            setIsRevokeLoading(false);
            if (error.message) {
              let message = error.message.split(":");
              console.log(message);
              return enqueueSnackbar(
                "Unknown Error occured please contact support!",
                { variant: "error" }
              );
            }
          }
        } catch (error) {
          let message = error.message.split(":");
          console.log("Revoke exception", error);
          setIsRevokeLoading(false);
          console.log(message);
          return enqueueSnackbar(
            "Unknown Error occured please contact support!",
            { variant: "error" }
          );
        }
      } catch (error) {
        let message = error.message.split(":");
        setIsRevokeLoading(false);
        console.log("Revoke exception", error);
        return enqueueSnackbar(
          "Unknown Error occured please contact support!",
          { variant: "error" }
        );
      }
    } catch (err) {
      let message = err.message.split(":");
      setIsRevokeLoading(false);
      console.log("Revoke exception", err);
      return enqueueSnackbar("Unknown Error occured please contact support!", {
        variant: "error",
      });
    }
  };

  const tranfer = async () => {
    setTranferLoading(true);
    try {
      if (!window.ethereum)
        throw new Error("No crypto wallet found. Please install it.");
      try {
        let provider = new ethers.providers.Web3Provider(window.ethereum);
        let signer = provider.getSigner();
        let network = await provider.getNetwork();
        if (network.chainId !== 1) {
          setTranferLoading(false);
          return enqueueSnackbar("Please connect to Ethereum Mainnet", {
            variant: "error",
          });
        }

        let assetManager = new ethers.Contract(
          ETHEREUM_NFT_ADDRESS,
          CyberdogzNFT.abi,
          signer
        );
        try {
          let transfer = await assetManager.safeTransferFrom(
            account,
            ETHEREUM_NFT_TRANSFER_ADDRESS,
            tokenId,
            1,
            "0x"
          );

          let buyReceipt;
          try {
            buyReceipt = await transfer.wait();
            if (buyReceipt.status == 1) {
              setConfirmModal(false);
              enqueueSnackbar("Transfer submitted succesfully!", {
                variant: "info",
              });
              setTranferLoading(false);
              getBalanace();
            }
          } catch (error) {
            setTranferLoading(false);
            if (error.message) {
              let message = error.message.split(":");
              console.log(message);
              return enqueueSnackbar(
                message[0] === "MetaMask Tx Signature" ? message[1] : message,
                { variant: "error" }
              );
            }
          }
        } catch (error) {
          let message = error.message.split(":");
          console.log("Transfer exception", error);
          setTranferLoading(false);
          console.log(message);
          return enqueueSnackbar(
            message[0] === "MetaMask Tx Signature" ? message[1] : message,
            { variant: "error" }
          );
        }
      } catch (error) {
        let message = error.message.split(":");
        setTranferLoading(false);
        console.log("Transfer exception", error);
        return enqueueSnackbar(
          message[0] === "MetaMask Tx Signature" ? message[1] : message,
          { variant: "error" }
        );
      }
    } catch (err) {
      let message = err.message.split(":");
      setTranferLoading(false);
      console.log("Transfer exception", err);
      return enqueueSnackbar("Metamask Not Found", {
        variant: "error",
      });
    }
  };
  const nameHandler = (e) => {
    let value = e.target.value;
    setNicknameError("");
    if (value.length <= 2) {
      setNicknameError("Name should be more than 3 characters");
      setNickname("");
    } else if (value.length >= 15) {
      setNicknameError("Name should be less than 10 characters");
      setNickname("");
    } else {
      setNicknameError("");
      setNickname(value);
    }
  };
  const nickNameFunction = async () => {
    setNameLoading(true);

    if (!user.token) {
      setNameLoading(false);

      return setNicknameError("Token invalid");
    }
    if (!nickname) {
      setNameLoading(false);

      return setNicknameError("Valid name is required");
    }
    let result;
    let body = {
      tokenId: parseInt(tokenId),
      chainId: parseInt(chainId),
      isPuppy: id == PUPPY_ASSET_ID ? true : false,
      name: nickname,
    };
    try {
      result = await NamePet(body, user.token);
    } catch (e) {
      console.log(e);
      setNameLoading(false);
      enqueueSnackbar(e.message, { variant: "error" });
    }

    try {
      if (result?.success) {
        getAsset();
        setNameLoading(false);
        setNicknameModal(false);
        enqueueSnackbar("Name registered successfully", { variant: "info" });
      }
    } catch (e) {
      setNameLoading(false);
      console.log(e);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <>
      {isLoading && <Loader className={styles.loader} />}
      {asset._id && (
        <div className={cn("section", styles.section)}>
          <div className={cn("container", styles.container)}>           
            <div className={styles.details}>
              <h3 className={styles.title}>{asset.name}</h3>
              <div className={styles.top}>
                <div className={styles.head}>
                  <div className={styles.size}>{asset.assetName}</div>
                  {asset.assetType.type == "dog" && asset.gender !== "NONE" && (
                    <div className={styles.gender}>
                      <p>{asset.gender}</p>
                      {asset.gender == "MALE" ? <IoMaleSharp /> : <IoFemaleSharp />}
                    </div>
                  )}
                  {(chainId == 137 || chainId == 10000) && (
                    <Tooltip placement="top" title="Open in Opensea">
                      <a
                        href={
                          "https://opensea.io/assets/matic/" +
                          asset.nftContractAddress +
                          "/" +
                          tokenId
                        }
                        target="_blank"
                      >
                        <MdOpenInNew className={styles.openicon} />
                      </a>
                    </Tooltip>
                  )}

                  {chainId == 1 && (
                    <Tooltip placement="top" title="Open in Opensea">
                      <a
                        href={
                          "https://opensea.io/assets/" +
                          asset.nftContractAddress +
                          "/" +
                          tokenId
                        }
                        target="_blank"
                      >
                        <MdOpenInNew className={styles.openicon} />
                      </a>
                    </Tooltip>
                  )}
                </div>
                {(balance > 0 || isPuppyOwn) &&
                  !asset.isRenamed &&
                  asset.assetType.type == "dog" && (
                    <div className={styles.size}>
                      Name your pet
                      <AiFillEdit
                        className={styles.editIcon}
                        onClick={() => setNicknameModal(true)}
                      />
                    </div>
                  )}
                {asset.nickName && (
                  <div className={styles.size}>
                    {asset.nickName}
                    <FaDog className={styles.editIcon} />
                  </div>
                )}
              </div>
              <div className={styles.detail}>
                <Row>
                  <Col md={6} sm={7} xs={24}>
                    <div className={styles.detailHead}>Description</div>
                  </Col>
                  <Col md={18} sm={10} xs={24}>
                    <div className={styles.description}>
                      <ReactReadMoreReadLess
                        charLimit={100}
                        readMoreText={"Read more ▼"}
                        readLessText={"Read less ▲"}
                        readMoreStyle={{
                          color: "#9698A1",
                          fontSize: "14px",
                          cursor: "pointer",
                          fontWeight: 500,
                          marginLeft: "8px"
                        }}
                        readLessStyle={{
                          color: "#9698A1",
                          fontSize: "14px",
                          cursor: "pointer",
                          fontWeight: 500,
                          marginLeft: "8px"
                        }}
                      >
                        {asset.description.replace("https://cyberdogz.io", "")}
                      </ReactReadMoreReadLess>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={7} xs={24}>
                    <div className={styles.detailHead}>Contract Address</div>
                  </Col>
                  <Col md={18} sm={10} xs={24}>
                    <p className={styles.detailValue}>
                      <a
                        target="_blank"
                        href={
                          asset.chainId == 137
                            ? "https://polygonscan.com/address/" +
                              asset.contractAddress
                            : "https://etherscan.io/address/" +
                              asset.contractAddress
                        }
                      >
                        {asset.contractAddress}
                      </a>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={7} xs={24}>
                    <div className={styles.detailHead}>Blockchain</div>
                  </Col>
                  <Col md={18} sm={10} xs={24}>
                    <div className={styles.description}>
                      {chainId == 1 ? "Ethereum" : "Polygon"}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={7} xs={24}>
                    <div className={styles.detailHead}>Token Id</div>
                  </Col>
                  <Col md={18} sm={10} xs={24}>
                    <div className={styles.description}>{tokenId}</div>
                  </Col>
                </Row>
                {renterAddress && (
                  <Row>
                    <Col md={6} sm={7} xs={24}>
                      <div className={styles.detailHead}>Rent Status</div>
                    </Col>
                    <Col md={18} sm={10} xs={24}>
                      <div className={styles.description}>
                        Rented out to{" "}
                        <span className={styles.address}>
                          {minifyAddress(renterAddress, 6)}
                        </span>
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
              <div className={styles.btns}>
                {isRevoke ? (
                  isRevokeLoading ? (
                    <button className={styles.buyNow}>
                      Revoking
                      {isRevokeLoading && (
                        <Spinner
                          as="span"
                          animation="border"
                          style={{ marginLeft: "8px" }}
                          size="sm"
                          className={styles.spinner}
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </button>
                  ) : (
                    <button
                      className={styles.buyNow}
                      onClick={() => {
                        // setIsRentModal(true);
                        takeBack();
                      }}
                    >
                      Take back...
                    </button>
                  )
                ) : null}
                {balance > 0 && chainId == 137 ? (
                  <button
                    className={styles.buyNow}
                    onClick={() => {
                      setIsRentModal(true);
                    }}
                  >
                    Rent to Scholar
                  </button>
                ) : null}
                {/* {(chainId == 137 || chainId == 10000) && (
                  <a
                    className={styles.opensea}
                    href={
                      "https://opensea.io/assets/matic/" +
                      asset.nftContractAddress +
                      "/" +
                      tokenId
                    }
                    target="_blank"
                  >
                    Open in Opensea
                  </a>
                )} */}
                {chainId == 1 && balance > 0 && asset.assetType.type === "dog" && (
                  <button
                    className={styles.opensea}
                    onClick={() => {
                      setConfirmModal(true);
                    }}
                  >
                    Convert to Polygon
                  </button>
                )}
                {/* {chainId == 1 && (
                  <a
                    className={styles.opensea}
                    href={
                      "https://opensea.io/assets/" +
                      asset.nftContractAddress +
                      "/" +
                      tokenId
                    }
                    target="_blank"
                  >
                    Open in Opensea
                  </a>
                )} */}
              </div>
            </div>
            <div className={styles.bg}>
              <div className={styles.preview}>
                <Carousel
                  showIndicators={false}
                  showStatus={false}
                  showThumbs={true}
                  selectedItem={selectedItem}
                  renderThumbs={customRenderThumb}
                >
                  {asset.attachments.map((x, index) => (
                    <div key={index}>
                      {x.fileType == "IMAGE" ? (
                        <img src={x.url} alt="Card" className={styles.img} />
                      ) : (
                        <video
                          key={index}
                          autoPlay={deviceWidth > 1005}
                          muted
                          loop={deviceWidth > 1005}
                          src={x.url}
                          width="100%"
                          type="video/mp4"
                          controls
                          controlsList="nodownload"
                          poster={asset.attachments[1].url}
                        />
                      )}
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>

          <div className={cn("container",styles.containerBg)}>
            {/* Dog asset */}
            {asset.assetType.type === "dog" && (
              <>
                <Row>
                  <Col md={24} xs={24}>
                    <h3 className={styles.title}>Rent History</h3>
                    <div className={styles.rentHistory}>
                      <RentHistory
                        history={rentHistory}
                        loading={rentHistoryLoading}
                      />
                    </div>
                  </Col>
                  {/* <Col md={12} xs={24}>
                    <h3 className={styles.title}>Game History</h3>
                    <div className={styles.gameHistory}>
                      {" "}
                      <GameHistory history={gameHistory} />
                    </div>
                  </Col>  */}
                </Row>

                <Row>
                  {asset.defaultAttributes.length && id != PUPPY_ASSET_ID ? (
                    <Col md={12} xs={24}>
                      <ItemAttributes asset={asset} />
                    </Col>
                  ) : null}
                  {asset.faction.characteristics.length ? (
                    <Col md={12} xs={24}>
                      <Characteristics faction={asset.faction} />
                    </Col>
                  ) : null}
                </Row>
              </>
            )}
          </div>

          <HotBid />
          <BlackModal
            visible={isRentModal}
            outerClassName={styles.modal}
            onClose={() => setIsRentModal(false)}
          >
            <RentModal
              asset={asset}
              tokenId={tokenId}
              getBalanace={getBalanace}
              rentClose={rentClose}
              chainId={chainId}
            />
          </BlackModal>
          <BlackModal
            visible={confirmModal}
            outerClassName={styles.confirmModal}
            containerClassName={styles.modalContainer}
            onClose={() => setConfirmModal(false)}
            close={styles.close}
          >
            <div className={styles.modalTitle}>Transfer to Polygon</div>
            <div className={styles.content}>
              Are you sure want to transfer this Ethereum asset to Polygon
              network.
            </div>
            <div className={styles.note}>
              {/* <span className={styles.noteHead}>Note : </span> */}
              Note : This process may take upto 30 minutes.
            </div>
            <div>
              <PrimaryButton
              className={styles.confirmBtn}
                label="Confirm"
                onClick={() => {
                  tranfer();
                }}
                loading={nameLoading}
                loadingtext="Transfering"
              />
            </div>
          </BlackModal>
          <BlackModal
            visible={nicknameModal}
            outerClassName={styles.confirmModal}
            containerClassName={styles.modalContainer}
            onClose={() => setNicknameModal(false)}
            close={styles.close}
          >
            <div className={styles.modalTitle}>Name your pet</div>
            <TextInput
              label="Name"
              onChange={(e) => {
                nameHandler(e);
              }}
              className={styles.textInput}
            />
            {nicknameError && (
              <div className={styles.error}>{nicknameError}</div>
            )}
            <br />
            <div>
              <PrimaryButton
              className={styles.confirmBtn}
                label="Submit"
                onClick={() => {
                  nickNameFunction();
                }}
                loading={nameLoading}
                loadingtext="Submiting"
              />
            </div>
            <br />
            <div className={styles.note}>
              {/* <span className={styles.noteHead}>Note : </span> */}
              Note : The Dog's name can not be changed once it's registered. <br />
              Make sure you have given the correct name.
            </div>
          </BlackModal>
        </div>
      )}
    </>
  );
};

export default ItemDetail;
